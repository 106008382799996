import { useMemo } from 'react';
import type { CellClickedEvent, ColDef } from 'ag-grid-community';
import { RowInfo } from '../../types';
import { Button } from '@mantine/core';

type UseColDefsProps = {
    selected: string;
    fundIdHeader: string;
    t: (key: string) => string;
    handleFundIdCellClick: (params: any) => void;
    hasWritePermission: (fundGroup: string, permission: string) => boolean;
    renderCellWithViolations: (...args: any[]) => JSX.Element;
    inBadge: JSX.Element;
    outBadge: JSX.Element;
    cellWithoutBold: any;
    cellicon: any;
    commonCellStyle: any;
    commonCellStyleWithoutTolerance: any;
    wipButtonStates: Record<string, string>;
    handleWipButtonClick: (...args: any[]) => void;
    handleWipButtonHover: (...args: any[]) => void;
    handleWipButtonLeave: (...args: any[]) => void;
    isButtonDisabled: boolean;
    loadingButtons: Record<string, boolean>;
    theme: any;
    setCheckState: (state: string) => void;
    ME: string;
    I_M_STARTING: string;
};

export const useColDefs = ({
    selected,
    fundIdHeader,
    t,
    handleFundIdCellClick,
    hasWritePermission,
    renderCellWithViolations,
    inBadge,
    outBadge,
    cellWithoutBold,
    cellicon,
    commonCellStyle,
    commonCellStyleWithoutTolerance,
    wipButtonStates,
    handleWipButtonClick,
    handleWipButtonHover,
    handleWipButtonLeave,
    isButtonDisabled,
    loadingButtons,
    theme,
    setCheckState,
    ME,
    I_M_STARTING,
}: UseColDefsProps): ColDef[] => {
    return useMemo<ColDef[]>(() => {
        const defaultCols: ColDef<RowInfo>[] = [
            {
                valueGetter: "node.rowIndex + 1",
                headerName: "",
                width: 65,
                headerClass: `custom-header`,
                pinned: "left",
                cellStyle: {
                    textAlign: "right",
                }
            },

            {
                field: "fund_id",
                headerName: fundIdHeader,
                width: 105,
                pinned: "left",
                onCellClicked: (params) => handleFundIdCellClick(params),
                cellStyle: cellWithoutBold,
            },

            {
                field: "fund_name",
                headerName: t("FUND_NAME"),
                width: 248,
                pinned: "left",
                onCellClicked: (params) => handleFundIdCellClick(params),
                cellStyle: cellWithoutBold,
            },
            {
                field: "tol_start",
                headerName: t("START_OF_THE_DAY_TOL_STATUS"),
                width: 135,
                cellStyle: cellicon,
                onCellClicked: (params: CellClickedEvent<RowInfo, any>) => handleFundIdCellClick(params),
                cellRenderer: (params: any) => {
                    const tolStart = params.data.tol_start;
                    return (
                        <div>
                            {tolStart === 'IN' ? inBadge : outBadge}
                        </div>
                    );
                },
            },

            {
                field: "tol_current",
                headerName: t("CURRENT_TOL_STATUS"),
                width: 115,
                cellStyle: cellicon,
                onCellClicked: (params: CellClickedEvent<RowInfo, any>) => handleFundIdCellClick(params),
                cellRenderer: (params: any) => {
                    const tolCurrent = params.data.tol_current;
                    if (tolCurrent !== null && tolCurrent !== undefined) {
                        return (
                            <div>
                                {tolCurrent === 'IN' ? inBadge : outBadge}
                            </div>
                        );
                    } else {
                        return null;
                    }
                },
            } as ColDef<RowInfo>,

            {
                field: "data_missing",
                headerName: `${t("DATA_MISSING")}`,
                width: 100,
                cellStyle: commonCellStyle,
            },

            {
                field: "wic",
                headerName: `${t("WORK_IN_COMPLETED_BY")}`,
                width: 120,
                cellStyle: commonCellStyle,
            },

            {
                field: "order_status",
                headerName: t("ORDER_STATUS"),
                width: 113,
                onCellClicked: (params) => handleFundIdCellClick(params),
            },

            {
                field: "wip",
                headerName: t("WORK_IN_PROGRESS_BY"),
                width: 126,
                headerClass: "common-header-right",
                cellStyle: {
                    ...commonCellStyle,
                    textAlign: "center",
                    marginTop: "-0.05rem",
                },
                cellRenderer: (params: any) => {
                    const { wip, is_fund_locked, fund_group, sr_no: rowId } = params.data;
                    const hasPermission = hasWritePermission(fund_group, "write");
                    const buttonState = wipButtonStates[rowId];
                    const isLoading = loadingButtons[rowId];
                    const backgroundColor = isLoading ? theme.colors.violet[8] : undefined;
                    const buttonProps = {
                        className: `wippillstyle ${buttonState === (is_fund_locked === "true" ? ME : I_M_STARTING)}`,
                        loading: isLoading,
                        loaderProps: { type: "dots" },
                        style: { backgroundColor },
                        disabled: isButtonDisabled,
                    };
            
                    if (!hasPermission) {
                        return (
                            <Button
                                {...buttonProps}
                                className="wippillstyle-disable"
                                disabled
                            >
                                {buttonState || I_M_STARTING}
                            </Button>
                        );
                    }
            
                    if (is_fund_locked === "true") {
                        setCheckState(buttonState ?? ME);
                        return (
                            <Button
                                {...buttonProps}
                                onClick={() => handleWipButtonClick(rowId, params)}
                                onMouseEnter={() => handleWipButtonHover(rowId)}
                                onMouseLeave={() => handleWipButtonLeave(rowId)}
                            >
                                {buttonState || ME}
                            </Button>
                        );
                    }
            
                    // Default case for is_fund_locked === "false"
                    return (
                        <Button
                            {...buttonProps}
                            onClick={() => handleWipButtonClick(rowId, params)}
                        >
                            {buttonState || I_M_STARTING}
                        </Button>
                    );
                },
            },
        
            
            {
                field: "spend_cash_bef",
                headerName: t("SPENDABLE_CASH_BEF"),
                width: 105,
                cellStyle: commonCellStyle,
                headerClass: 'common-header-right',
                onCellClicked: handleFundIdCellClick,
                cellRenderer: (params: any) => renderCellWithViolations(
                    params,
                    "spend_cash_bef",
                    "spend_cash_bef_violated_tolerances",
                    "spend_cash_bef_violated_tolerances_lower",
                    "spend_cash_bef_violated_tolerances_upper",
                    "spend_cash_bef"
                ),
            },
            {
                field: "spend_cash_aft",
                headerName: t("SPENDABLE_CASH_AFT"),
                width: 105,
                headerClass: 'common-header-right',
                cellStyle: commonCellStyle,
                onCellClicked: handleFundIdCellClick,
                cellRenderer: (params: any) => renderCellWithViolations(
                    params,
                    "spend_cash_aft",
                    "spend_cash_aft_violated_tolerances",
                    "spend_cash_aft_violated_tolerances_lower",
                    "spend_cash_aft_violated_tolerances_upper",
                    "spend_cash_aft"
                ),
            },
            {
                field: "unequitized_cash_bef",
                headerName: t("UNEQUITIZED_CASH_BEF"),
                headerClass: 'common-header-right',
                width: 105,
                cellStyle: commonCellStyle,
                onCellClicked: handleFundIdCellClick,
                cellRenderer: (params: any) => renderCellWithViolations(
                    params,
                    "unequitized_cash_bef",
                    "unequitized_cash_bef_violated_tolerances",
                    "unequitized_cash_bef_violated_tolerances_lower",
                    "unequitized_cash_bef_violated_tolerances_upper",
                    "unequitized_cash_bef"
                ),
            },
            {
                field: "unequitized_cash_aft",
                headerName: t("UNEQUITIZED_CASH_AFT"),
                headerClass: 'common-header-right',
                width: 105,
                cellStyle: commonCellStyle,
                onCellClicked: handleFundIdCellClick,
                cellRenderer: (params: any) => renderCellWithViolations(
                    params,
                    "unequitized_cash_aft",
                    "unequitized_cash_aft_violated_tolerances",
                    "unequitized_cash_aft_violated_tolerances_lower",
                    "unequitized_cash_aft_violated_tolerances_upper",
                    "unequitized_cash_aft"
                ),
            },

            {
                field: "assets",
                headerName: `${t("ASSET_MAX_ACT_WGT")}`,
                headerClass: 'common-header-right',
                width: 110,
                cellStyle: commonCellStyleWithoutTolerance,
                onCellClicked: (params) => handleFundIdCellClick(params),
            },
            {
                field: "industry",
                headerName: `${t("INDUSTRY_MAX_ACT_WGT")}`,
                headerClass: 'common-header-right',
                width: 120,
                cellStyle: commonCellStyleWithoutTolerance,
                onCellClicked: (params) => handleFundIdCellClick(params),
            },
            {
                field: "country",
                headerName: `${t("COUNTRY_MAX_ACT_WGT")}`,
                headerClass: 'common-header-right',
                width: 115,
                cellStyle: commonCellStyleWithoutTolerance,
                onCellClicked: (params) => handleFundIdCellClick(params),
            },
            {
                field: "currency",
                headerName: `${t("CURRENCY_MAX_ACT_WGT")}`,
                headerClass: 'common-header-right',
                width: 115,
                cellStyle: commonCellStyleWithoutTolerance,
                onCellClicked: (params) => handleFundIdCellClick(params),

            },
            {
                field: "size",
                headerName: `${t("SIZE_MAX_ACT_WGT")}`,
                headerClass: 'common-header-right',
                width: 100,
                cellStyle: commonCellStyleWithoutTolerance,
                onCellClicked: (params) => handleFundIdCellClick(params),
            },
            {
                field: "shortpos",
                headerName: `# ${t("SHORT_POSITIONS")}`,
                headerClass: 'common-header-right',
                width: 90,
                cellStyle: commonCellStyleWithoutTolerance,
                onCellClicked: (params) => handleFundIdCellClick(params),
            },
            {
                field: "nohold",
                headerName: `# ${t("NO_HOLD")}`,
                headerClass: 'common-header-right',
                width: 94,
                cellStyle: commonCellStyleWithoutTolerance,
                onCellClicked: (params) => handleFundIdCellClick(params),
            },
            {
                field: "nonbench",
                headerName: `${t("NON_BENCHMARK")}`,
                headerClass: 'common-header-right',
                width: 100,
                cellStyle: commonCellStyleWithoutTolerance,
                onCellClicked: (params) => handleFundIdCellClick(params),
            },
            {
                field: "activerisk",
                headerName: `${t("ACTIVE_RISK")}`,
                headerClass: 'common-header-right',
                width: 100,
                cellStyle: commonCellStyleWithoutTolerance,
                onCellClicked: (params) => handleFundIdCellClick(params),
            },
            {
                field: "equityactiverisk",
                headerName: `${t("EQUITY_ACTIVE_RISK")}`,
                headerClass: 'common-header-right',
                width: 110,
                cellStyle: commonCellStyleWithoutTolerance,
            },
        ]

        let fieldsToExclude: string[] = [];
        if (selected === 'COMPLETED') {
            fieldsToExclude = ["order_status", "wip", "data_missing"];
        }
        if (selected === 'NOT_READY') {
            fieldsToExclude = ["wic", "wip"];
        }
        if (selected === 'READY') {
            fieldsToExclude = ["data_missing", "wic"];
        }

        return defaultCols.filter((col) => !fieldsToExclude.includes(col.field || ""));
    }, [
        selected,
        fundIdHeader,
        t,
        handleFundIdCellClick,
        hasWritePermission,
        renderCellWithViolations,
        inBadge,
        outBadge,
        cellWithoutBold,
        cellicon,
        commonCellStyle,
        commonCellStyleWithoutTolerance,
        wipButtonStates,
        handleWipButtonClick,
        handleWipButtonHover,
        handleWipButtonLeave,
        isButtonDisabled,
        loadingButtons,
        theme,
        setCheckState,
        ME,
        I_M_STARTING,
    ]);
};
