import React, { useMemo } from "react";
import { Card, Center } from "@mantine/core";
import { useTranslation } from "react-i18next";
import SkeletonLoader from "../../Common/Skeleton";
import { useAppSelector } from "../../../store/hooks";
import { Header } from "./components/Header";
import { SectionRenderer } from "./components/SectionRenderer";
import { generateSections } from "./helpers/generateSections";
import { isMarketCounterSingle } from "./helpers/isMarketCounterSingle";

type Props = {
	param: string;
	TITLE: string;
};

export const AllCountersCard = ({ param, TITLE }: Props) => {
	const { t } = useTranslation();
	const { counters, loading, error } = useAppSelector((state) => state.market);

	const isValidCounterKey = (key: string): key is keyof typeof counters => key in counters;

	const key = param.includes("future") ? "market_on_close_plus_days_counters" : param;
	const counts = isValidCounterKey(key) ? counters[key] : null;
	const sections = useMemo(() => {
		if (!counts || !isMarketCounterSingle(counts)) return [];
		return generateSections(counts, t);
	}, [counts, t]);

	if (
		param === "market_on_open_counters" &&
		isMarketCounterSingle(counts) &&
		counts.total_funds_in_aggregate_count === 0
	) {
		return null;
	}

	if (!counts || !isMarketCounterSingle(counts)) {
		return (
			<Card withBorder shadow='sm'>
				<Center>No detailed data available for this counter</Center>
			</Card>
		);
	}

	return (
		<Card
			withBorder
			shadow='sm'
			style={{
				width: "100%",
				paddingLeft: "11px",
				paddingTop: "11px",
			}}
		>
			<Header title={t(TITLE)} count={counts.total_funds_in_aggregate_count ?? 0} />
			<SectionRenderer sections={sections} />
		</Card>
	);
};

export default AllCountersCard;
