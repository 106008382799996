// IconWrapper.tsx
import React from 'react';
import { useMantineTheme } from '@mantine/core';
import "../Leftnavbar/Leftnav.css";


interface IconWrapperProps {
    name: string;
    alt?: string;
    className?: string;
    handleAction?:()=>void
}

const IconWrapper: React.FC<IconWrapperProps> = ({ name, alt, className,handleAction }) => {
    const theme = useMantineTheme();
    const src = `/assets/${name}.svg`;
    return (

        <img src={src} alt={alt} className={className} onClick={handleAction}/>

    );
};

export default IconWrapper;
