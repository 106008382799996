import React from 'react';
import { Modal, Text, Flex, Box } from '@mantine/core';
import { DeleteModalUserProps } from '../../types';
import CustomButton from '../Common/Buttons/CustomButton';
import { t } from 'i18next';

const DeleteModalUser: React.FC<DeleteModalUserProps> = ({
  opened,
  onClose,
  modalType,
  TITLE,
  message,
  onSubmit,
  centered = true,
  withCloseButton = false,
  radius = '1.875rem',
  className = 'delete-modal'
}) => {
  return (
    <Modal
      opened={opened && modalType === 'delete'}
      onClose={onClose}
      centered={centered}
      withCloseButton={withCloseButton}
      radius={radius}
      className={className}
      size={'29.375rem'}
    >
      <Box pr={'xxs'}>
        <Text ta={'center'} size='1rem' fw={600} mt={"md"}>{t(TITLE)}</Text>
        <Text ta={'center'} mt={"2rem"} size={'0.8125rem'}>{t(message)}</Text>
        <Flex justify="center" align="center" mt="2.25rem" mb={"2.2rem"} ml={'-1.5rem'}>
          <CustomButton variant={'cancel'} onClick={onClose} children={"CANCEL"} />
          <CustomButton variant='submit' onClick={onSubmit} children={"DELETE"} />
        </Flex>
      </Box>
    </Modal>
  );
};

export default DeleteModalUser;
