import {
	PositionSummaryApiResponse,
	PositionSummaryChartCategories,
	PositionSummaryChartProps,
	PositionSummaryTableCategories,
	PositionSummaryTableProps,
} from "../../types";
import { Box, Button, Flex, Grid, useMantineTheme } from "@mantine/core";
import PositionTile from "../../components/PositionSummary/PositionTile";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import { services } from "../../services";
import SkeletonLoader from "../../components/Common/Skeleton";
import ErrorMessage from "../../components/Common/ErrorMessage";
import { getConfig } from "../../utils/conversions";
import './scstyles.css'

const tableData: PositionSummaryTableProps[] = [
	{
		category: "NoOfPos",
		status: "IN",
		data: [
			// {
			// 	asset: "MSFT",
			// 	before: 2000,
			// 	after: 0,
			// },
			// {
			// 	asset: "AMZN",
			// 	before: 1500,
			// 	after: 0,
			// },
			// {
			// 	asset: "IBM",
			// 	before: 3400,
			// 	after: 0,
			// },
			// {
			// 	asset: "GOOGL",
			// 	before: 5000,
			// 	after: 0,
			// },
			// {
			// 	asset: "APPL",
			// 	before: 1500,
			// 	after: 0,
			// },
			// {
			// 	asset: "INTL",
			// 	before: 6000,
			// 	after: 4000,
			// },
		],
	},
	{
		category: "NoHold",
		status: "IN",
		data: [
			// {
			// 	asset: "MSFT",
			// 	before: 2000,
			// 	after: 2000,
			// },
			// {
			// 	asset: "AMZN",
			// 	before: 1500,
			// 	after: 1500,
			// },
			// {
			// 	asset: "IBM",
			// 	before: 3400,
			// 	after: 3400,
			// },
			// {
			// 	asset: "GOOGL",
			// 	before: 5000,
			// 	after: 5000,
			// },
			// {
			// 	asset: "APPL",
			// 	before: 1500,
			// 	after: 1500,
			// },
			// {
			// 	asset: "INTL",
			// 	before: 6000,
			// 	after: 6000,
			// },
		],
	},
];

const PositionSummary = () => {
	const [tileInFocus, setTileInFocus] = useState(null);
	const [chartData, setChartData] = useState<PositionSummaryApiResponse[] | []>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const entityRef = useRef(null);
	const industryRef = useRef(null);
	const currencyRef = useRef(null);
	const assetRef = useRef(null);
	const sizeRef = useRef(null);
	const noOfPosRef = useRef(null);
	const noHoldRef = useRef(null);
	const { t } = useTranslation();
	const { fund_id, transaction_id, target_date, target_mode, calc_date } = useParams();
	const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
	const theme = useMantineTheme();

	const config = getConfig("position_summary");
	const showOption: number = +(config?.DISPLAY_OPTION?.value || "1");
	const [tileNameInFocus, setTileNameInFocus] = useState("");

	const refs: Record<string, MutableRefObject<null>> = {
		entityRef,
		industryRef,
		currencyRef,
		assetRef,
		sizeRef,
		noOfPosRef,
		noHoldRef,
	};

	useEffect(() => {
		setLoading(true);
		const params = {
			fund_id: fund_id,
			target_date: target_date,
			target_mode: target_mode || "MARKET_ON_OPEN",
			calc_date: calc_date
		};
		axios
			.get(services.FUND_ASSETS_AGG, { params })
			.then((res) => {
				setError(null);
				if (res.data.status === "fail" || res.data.message === "fail") {
					setChartData([]);
				} else setChartData(res.data.data);
				setLoading(false);
			})
			.catch((err) => {
				setError(err.response.status);
				setChartData((chartData) => chartData);
				setLoading(false);
			});
	}, []);

	const scrollToDiv = (ref: MutableRefObject<any>, titleName: string) => {
		window.scrollTo({
			behavior: "smooth",
			top: ref?.current?.offsetTop ? ref.current.offsetTop : 0,
		});
		ref && setTileInFocus(ref.current);
		setTileNameInFocus(titleName);
	};

	const getPositionSummaryForCategory = (cat: string): PositionSummaryChartProps | null => {
		let filteredProto = chartData.filter((obj) => cat in obj)[0]; //filter the right object from data
		if (filteredProto === undefined) return null;
		let protoObject = filteredProto[cat as PositionSummaryChartCategories]!!;
		let positionSummary: PositionSummaryChartProps = {
			type: cat as PositionSummaryChartCategories,
			status: protoObject[0].out_of_tolerance ? "OUT" : "IN",
			min_limit: protoObject[0].min_limit,
			max_limit: protoObject[0].max_limit,
			beforeData: protoObject.filter((item) => !item.from_algo_run)[0].element,
			afterData: protoObject.filter((item) => item.from_algo_run)[0].element,
		};
		return positionSummary;
	};

	const charts: {
		category: PositionSummaryChartCategories;
		tab: string;
		TITLE: string;
		name: keyof typeof refs;
		ref: MutableRefObject<null>;
		positionSummary?: PositionSummaryChartProps | null;
	}[] = [
			{
				category: "country_max_active_weight",
				tab: t("Country Max Act Weight"),
				TITLE: t("Country Max Act Weight") + " (%)",
				name: "entityRef",
				ref: refs["entityref"],
				positionSummary: getPositionSummaryForCategory("country_max_active_weight"),
			},
			{
				category: "sector_max_active_weight",
				tab: t("Industry Max Act Weight"),
				TITLE: t("Industry Misweight") + " (%)",
				name: "industryRef",
				ref: refs["industryRef"],
				positionSummary: getPositionSummaryForCategory("sector_max_active_weight"),
			},
			{
				category: "asset_max_active_weight",
				tab: t("Asset Max Act Weight"),
				TITLE: t("Asset Max Act Weight") + " (%)",
				name: "assetRef",
				ref: refs["assetRef"],
				positionSummary: getPositionSummaryForCategory("asset_max_active_weight"),
			},
			{
				category: "currency_max_active_weight",
				tab: t("Currency Max Act Weight"),
				TITLE: t("Currency Max Act Weight") + " (%)",
				name: "currencyRef",
				ref: refs["currencyRef"],
				positionSummary: getPositionSummaryForCategory("currency_max_active_weight"),
			},
		];

	const renderButton = (item: any, selectedCategory: string | null, setSelectedCategory: (category: string) => void) => (
		<Button
			key={item.category}
			onClick={() => {
				scrollToDiv(item.ref, item.category);
				setSelectedCategory(item.category);
			}}
			c={theme.colors.dark[9]}
			bg={selectedCategory === item.category ? theme.colors.violet[1] : theme.colors.yellow[0]}
			className="position-buttons"
		>
			{item.TITLE}
		</Button>
	);


	const tables: {
		category: PositionSummaryTableCategories;
		tab: string;
		TITLE: string;
		name: keyof typeof refs;
		ref: MutableRefObject<null>;
		positionSummary: PositionSummaryTableProps;
	}[] = [
			{
				category: "NoOfPos",
				tab: t("Number of Short Positions"),
				TITLE: t("Number of Short Positions"),
				name: "noOfPosRef",
				ref: refs["noOfPosRef"],
				positionSummary: tableData[0],
			},
			{
				category: "NoHold",
				tab: t("No Hold"),
				TITLE: t("No Hold"),
				name: "noHoldRef",
				ref: refs["noHoldRef"],
				positionSummary: tableData[1],
			},
		];

	if (loading) return <SkeletonLoader />;
	else if (error) return <ErrorMessage errorCode={error} />;
	return (
		<>
			<Flex mt='md' mb='xl' justify='space-between'>
				{charts.map((item) => {
					if (item.positionSummary === undefined || !item.positionSummary) return null;
					return renderButton(item, selectedCategory, setSelectedCategory);
				})}

				{tables.map((item) => {
					if (item.positionSummary === undefined || !item.positionSummary) return null;
					return renderButton(item, selectedCategory, setSelectedCategory);
				})}
			</Flex>

			{
				showOption === 1 ?
					<>
						{charts.map((chart) => {
							if (chart.positionSummary === undefined || !chart.positionSummary) return null;
							return (
								<Box key={chart.category} ref={chart.ref}>
									<PositionTile
										TITLE={chart.TITLE}
										name={chart.name}
										positionSummary={chart.positionSummary}
										tileInFocus={tileInFocus}
										type='CHART'
										option={showOption}
									/>
								</Box>
							);
						})}
					</>
					:
					<Grid columns={2} align="flex-start">
						{charts.map((chart) => {
							if (chart.positionSummary === undefined || !chart.positionSummary) return null;
							return (
								<Grid.Col
									className={`position-summary-box ${tileNameInFocus === chart.category ? ' selected' : ''}`}
									span={1}
									key={chart.category}
									ref={chart.ref}
									style={{ padding: "10px 30px 0px" }}
								>
									<PositionTile
										TITLE={chart.TITLE}
										name={chart.name}
										positionSummary={chart.positionSummary}
										tileInFocus={tileInFocus}
										type='CHART'
										option={showOption}
									/>
								</Grid.Col>
							);
						})}
					</Grid>
			}
			{tables.map((table) => {
				return (
					<Box key={table.category} ref={table.ref}>
						<PositionTile
							TITLE={table.TITLE}
							name={table.name}
							positionSummary={table.positionSummary}
							tileInFocus={tileInFocus}
							type='TABLE'
							option={showOption}
						/>
					</Box>
				);
			})}
		</>
	);
};

export default PositionSummary;
