import { Flex } from '@mantine/core';
import IconWrapper from './IconWrapper';

const LoaderIcon = ({ name, alt }: { name: string, alt: string,}) => {
	return (
		<>
			<Flex h={'100%'} justify={'center'}>
				<IconWrapper name={name} alt={alt} />
			</Flex>
		</>
	);
}

export default LoaderIcon