import React, { useEffect, useState } from 'react';
import { Flex, Text, ActionIcon, Collapse } from '@mantine/core';
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';

interface CollapsibleSectionProps {
  title: string;
  count: number;
  children: React.ReactNode;
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({ title, count, children }) => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
		if (count === 0) {
			setOpened(opened)
		} else {
			setOpened(!opened)
		}
	}, [count]);

  return (
    <Flex direction="column" gap="xxs" mt={'sm'} >
      <Flex gap="sm">
        <Text size="lg" fw="600">{`${title}`}</Text>
        <Text ml='-0.5rem' size="lg" fw="600">{`(${count})`}</Text>
        <ActionIcon
          variant="transparent"
          color="gray"
          aria-label="Toggle"
          onClick={() => setOpened(!opened)}
        >
          {opened ? <IconChevronUp /> : <IconChevronDown />}
        </ActionIcon>
      </Flex>
      <Collapse in={opened}>{children}</Collapse>
    </Flex>
  );
};

export default CollapsibleSection;
