import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FC, Suspense, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../Agtablehomepage/Agstyles.css";
import { useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";
import "./ThresoldLeft.css";
import { ThresoldLeftProps } from "../../types";
import { calculateColorShade } from "../../utils/colorUtils";
import { directPercentage } from "../../utils/conversions";


export const ThresoldLeft: FC<ThresoldLeftProps> = ({ rowData }) => {
  const { t, i18n } = useTranslation();
  const theme = useMantineTheme();
  const [languageKey, setLanguageKey] = useState(i18n.language);

  useEffect(() => {
    setLanguageKey(i18n.language);
  }, [i18n.language]);

  const generateColumnsFromData = (data: any) => {
    const columns: ColDef[] = [];
  
    Object.keys(data).forEach((key) => {
      const isOutOfTolerance = data[key]?.is_out_of_tolerance ?? false;
  
      const column: ColDef = {
        field: key,
        headerName: t(key), // Use translation for header name
        width: 120,
        headerClass: "ag-right-aligned-header custom-header",
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellClass: "spendCashCell",
        cellStyle: isOutOfTolerance
          ? (params: any) => {
              const fieldValue = parseFloat(params.data[key]?.stats ?? 0);
              const violatedTolerance = parseFloat(params.data[key]?.violated_tolerance ?? 0);
              const upper = parseFloat(params.data[key]?.upper_threshold ?? 0);
              const lower = parseFloat(params.data[key]?.lower_threshold ?? 0);
  
              const isBetweenThresholds = violatedTolerance > lower && violatedTolerance < upper;
  
              return {
                background: isBetweenThresholds
                  ? theme.colors.yellow[0]
                  : calculateColorShade(fieldValue, violatedTolerance, theme),
                textAlign: "right",
              };
            }
          : { backgroundColor: theme.colors.yellow[0], textAlign: "right" },
      };
  
      if (isOutOfTolerance) {
        column.cellRenderer = (params: any) => {
          const fieldValue = params.data[key];
  
          const violatedToleranceValue = parseFloat(fieldValue?.violated_tolerance ?? 0);
          const upper = parseFloat(params.data[key]?.upper_threshold ?? 0);
          const lower = parseFloat(params.data[key]?.lower_threshold ?? 0);
  
          const symbol =
            violatedToleranceValue === upper
              ? ">"
              : violatedToleranceValue === lower
              ? "<"
              : "";
  
          return (
            <div>
              <div className="spendcashstyles">
                {directPercentage(parseFloat(params.data[key]?.stats ?? 0))}
              </div>
              <div
                style={{
                  color: theme.colors.grey[5],
                }}
                className="secondtextincell"
              >
                {symbol}{" "}
                {directPercentage(parseFloat(params.data[key]?.violated_tolerance ?? 0))}
              </div>
            </div>
          );
        };
      } else {
        // Only show the stats section if is_out_of_tolerance is false
        column.cellRenderer = (params: any) => (
          <div className="spendcash">
            {directPercentage(parseFloat(params.data[key]?.stats ?? 0))}
          </div>
        );
      }
  
      columns.push(column);
    });
  
    return columns;
  };
  

  const colDefs = useMemo(() => {
    if (rowData.length === 0) {
      return [];
    }

    const firstRow = rowData[0];
    return generateColumnsFromData(firstRow);
  }, [rowData, t, theme]);

  const maxColumns = colDefs.length;

  const calculateMaxWidth = (numColumns: number): number => {
    return numColumns * 120;
  };

  const tableWidth = useMemo(() => calculateMaxWidth(maxColumns), [maxColumns]);

  const calculateMaxHeight = (
    numRows: number
  ): { height: number; borderBottom: string } => {
    switch (numRows) {
      case 1:
        return { height: 90, borderBottom: "1px solid #ccc" };
      default:
        return { height: 40 * numRows, borderBottom: "1px solid #ccc" };
    }
  };

  const tableHeight = useMemo(
    () => calculateMaxHeight(rowData.length),
    [rowData.length]
  );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {rowData.length > 0 ? (
        <div
          className="fund-query ag-theme-quartz"
          style={{
            width: `${tableWidth}px`,
            height: `${tableHeight.height}px`,
            overflow:
              tableHeight.height > calculateMaxHeight(8).height
                ? "auto"
                : "hidden",
            borderBottom: tableHeight.borderBottom,
            marginTop: theme.spacing.sm,
          }}
        >
          <AgGridReact
            key={languageKey}
            rowData={rowData}
            columnDefs={colDefs}
            rowHeight={40}
            domLayout="autoHeight"
            maxConcurrentDatasourceRequests={1}
            maxBlocksInCache={1}
            suppressRowHoverHighlight={true}
          />
        </div>
      ) : (
        <div> </div>
      )}
    </Suspense>
  );
};
