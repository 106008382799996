import { MarketCounterSingle } from "../../../../types";
import { TFunction } from "i18next";
import { LABELS_WITH_DETAILS } from "../../../Common/Labels/labelDefinitions";

export const generateSections = (counts: MarketCounterSingle, t: TFunction) => [
	{
		label: t(LABELS_WITH_DETAILS.READY.labelKey),
		values: [
			counts.ready_state_counts?.in_with_orders ?? 0,
			counts.ready_state_counts?.out_with_orders ?? 0,
			counts.ready_state_counts?.out_with_no_orders ?? 0,
		],
		labels: LABELS_WITH_DETAILS.READY.labels,
		colors: LABELS_WITH_DETAILS.READY.colors,
	},
	{
		label: t(LABELS_WITH_DETAILS.NOT_READY.labelKey),
		values: [
			counts.not_ready_state_counts?.data_missing ?? 0,
			counts.not_ready_state_counts?.benchmark_not_updated ?? 0,
			counts.not_ready_state_counts?.start_of_day_recon_not_done ?? 0,
		],
		labels: LABELS_WITH_DETAILS.NOT_READY.labels,
		colors: LABELS_WITH_DETAILS.NOT_READY.colors,
	},
	{
		label: t(LABELS_WITH_DETAILS.COMPLETED.labelKey),
		values: [
			counts.completed_state_counts?.in_with_no_order ?? 0,
			counts.completed_state_counts?.in_after_placing_order ?? 0,
			counts.completed_state_counts?.out_approved_with_overrides ?? 0,
		],
		labels: LABELS_WITH_DETAILS.COMPLETED.labels,
		colors: LABELS_WITH_DETAILS.COMPLETED.colors,
	},
];
