import { PositionSummaryChartProps, PositionSummaryTableProps } from '../../types'
import { ActionIcon, Badge, Collapse, Flex, Grid, Title, Text, Box, useMantineTheme } from '@mantine/core'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import PositionChart from './PositionChart'
import PositionChartOpt2 from './PositionChartOption2'
import { useDisclosure } from '@mantine/hooks'
import { PositionTable } from './PositionTable'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { getBadgeStyles } from '../../utils/getStyles'

type PositionTileProps = {
    TITLE: string;
    positionSummary: PositionSummaryChartProps | PositionSummaryTableProps;
    name: string;
    tileInFocus: string | null;
    type: "TABLE" | "CHART";
    option: number;
}

const PositionTile = ({ TITLE, positionSummary, tileInFocus, name, type, option }: PositionTileProps) => {
    const [opened, { toggle }] = useDisclosure(!tileInFocus || tileInFocus === name);
    const { status } = positionSummary
    const { i18n, t } = useTranslation()
    const theme = useMantineTheme()
    const { inBadge, outBadge } = getBadgeStyles(theme);

    // Hide on load if there is no data for table
    useEffect(() => {
        if(type ==="TABLE" && (positionSummary as PositionSummaryTableProps).data.length === 0 && opened) {
            toggle();
        }
    }, []);

    const SUM_OF = t('SUM_OFF')

    return (
        <Box mb="2rem">
            <Flex
                gap="md"
                justify="flex-start"
                align="center"
                direction="row"
                wrap="wrap"
                mb="xs">
                <Title order={5} m="0">{SUM_OF} {TITLE}</Title>
                {status === 'OUT' ? outBadge : inBadge}
                <ActionIcon variant="transparent"
                    color="gray"
                    aria-label="Settings"
                    mt={theme.spacing.negativeXs}
                    onClick={toggle}>
                    {opened ? <IconChevronUp /> : <IconChevronDown />}
                </ActionIcon>
            </Flex>
            <Collapse in={opened}>
                {type === "TABLE" ?
                    <Grid>
                        <PositionTable data={(positionSummary as PositionSummaryTableProps).data} TITLE={TITLE} />
                    </Grid>
                    :
                    option === 1 ?
                    (<Grid >
                        <Grid.Col span={6}>
                            <Flex justify="center">
                                <PositionChart
                                    data={(positionSummary as PositionSummaryChartProps).beforeData}
                                    delta={(positionSummary as PositionSummaryChartProps).afterData.map(row => row.delta)}
                                    min_limit={(positionSummary as PositionSummaryChartProps).min_limit}
                                    max_limit={(positionSummary as PositionSummaryChartProps).max_limit}
                                    TITLE={TITLE}
                                    type="Before" />
                            </Flex>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Flex justify="center">
                                <PositionChart
                                    data={(positionSummary as PositionSummaryChartProps).afterData}
                                    delta={(positionSummary as PositionSummaryChartProps).afterData.map(row => row.delta)}
                                    TITLE={TITLE}
                                    type="After"
                                    min_limit={(positionSummary as PositionSummaryChartProps).min_limit}
                                    max_limit={(positionSummary as PositionSummaryChartProps).max_limit} />
                            </Flex>
                        </Grid.Col>
                    </Grid>) :
                    (
                        <Flex justify="center" className='position-summary-graph-area'>
                        <PositionChartOpt2 data={(positionSummary as PositionSummaryChartProps).afterData}
                            TITLE={TITLE}
                            type="After"
                            delta={(positionSummary as PositionSummaryChartProps).afterData.map(row => row.delta)}
                            min_limit={(positionSummary as PositionSummaryChartProps).min_limit}
                            max_limit={(positionSummary as PositionSummaryChartProps).max_limit} />
                    </Flex>
                    )
                }
            </Collapse>
        </Box>
    )
}

export default PositionTile