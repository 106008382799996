import React, { useState, useEffect, useRef } from "react";
import {
	ActionIcon,
	Box,
	Card,
	Collapse,
	Flex,
	useMantineTheme,
	Text,
	TextInput,
	Button,
} from "@mantine/core";
import IconWrapper from "../Common/IconWrapper";
import { ApiResponse, Data, PresetProps, Document, DocumentDate } from '../../types';
import { services } from "../../services";
import axios from "axios";
import ContextMenu from "./ContextMenu2";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Preset.css";
import useClickOutside from "./Presets/useClickOutside";
import {
	ContextMenuState,
	handleEvent,
	handleRenameFolders,
	handleSave,
	updateContextMenu,
} from "../../utils/handleRename";
import CustomActionIcon from "./Presets/CustomActionIcon";
import FolderTextInput from "./Presets/FolderTextInput";
import { DATE_SPEC_TODAY, DATE_SPEC_TOMORROW, DATE_SPEC_YESTERDAY, getSpecialDate } from '../../constants';
import { t } from "i18next";

export function Preset({
	isPresetOpen,
	setIsPresetOpen,
	onDocumentClick,
	handleReresh,
}: PresetProps) {
	const theme = useMantineTheme();
	const [creatingFolder, setCreatingFolder] = useState<boolean>(false);
	const [folderTitle, setFolderTitle] = useState<string>("");
	const [folderNames, setFolderNames] = useState([]);
	const [renamingDocId, setRenamingDocId] = useState<string | null>(null);
	const [renamingFolderId, setRenamingFolderId] = useState<string | null>(null);
	const [newName, setNewName] = useState<string>("");
	const [data, setData] = useState<Data[]>([]);
	const [storedFolders, setStoredFolders] = useState<string[]>([]);
	const [contextMenuVisible, setContextMenuVisible] = useState(false);
	const [newEmptyFolderNameDelete, setEmptyNewFolderNameDelete] = useState<boolean>(false);
	const [newFolderNameDelete, setNewFolderNameDelete] = useState<boolean>(false);
	const [newFolderName, setNewFolderName] = useState("");
	const [newEmptyFolderName, setNewEmptyFolderName] = useState("");
	const [documents, setDocuments] = useState<DocumentDate[]>([]);

	const loadStoredFolderNames = () => {
		const storedFolderNames = Object.keys(localStorage)
			.filter((key) => key.startsWith("folderName")) // Assuming you prefix stored folder names
			.map((key) => localStorage.getItem(key) || "");

		setStoredFolders(storedFolderNames);
	};

	useEffect(() => {
		loadStoredFolderNames();
	}, []);

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	const [contextMenu, setContextMenu] = useState<ContextMenuState>({
		visible: false,
		x: 0,
		y: 0,
		renameFunction: () => { },
		deleteFunction: () => { }
	  });

	  const handleRenamePreset = (docId: string) => {
		setRenamingDocId(docId);
		const currentName = data
		  .flatMap((item) => item.documents)
		  .find((doc) => doc.uuid === docId)?.name || ""
		setNewName(currentName);
		setContextMenu({ ...contextMenu, visible: false });
	  };
	  const handleDelete = async (docId: string) => {
		setContextMenu({ ...contextMenu, visible: false });
		if (docId) {
		  try {
			const folderUUIDdelete = data.find((item) =>
			  item.documents.some((doc) => doc.uuid === docId),
			)?._id;
			await axios.delete(`${services.GET_PRESETS}/${docId || folderUUIDdelete}`);
			console.log("Document deleted:", docId);
			fetchData();
		  } catch (error) {
			console.error("Error deleting document:", error);
		  }
		}
	  };
	  const handleRightClick = (
		event: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
		docId: string,
	  ) => updateContextMenu(event, setContextMenu, { renameFunction: () => handleRenamePreset(docId), deleteFunction: () => handleDelete(docId) });
	
	  const handleFolderRightClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		folderId: string,
		folderName: string,
	  ) => updateContextMenu(event, setContextMenu, { renameFunction: () => handleRenameFolder(folderId, folderName), deleteFunction: () => handleDeleteFolder(folderId, folderName) });

	  const handleDeleteFolder = async (folderId: string, folderName: string) => {
		setContextMenu({ ...contextMenu, visible: false });
		if (!folderId) return;
	
		const folderHasPresets = (data.find((item) => item._id === folderName)?.documents?.length || 0) > 1;
		console.log("check the folder its id or name: ", folderHasPresets)
		if (folderHasPresets) {
		  toast.error(t("ERROR_DELETE_FOLDER"));
		  return;
		}
		if (folderId) {
		  try {
			const folderUUIDdelete = data.find((item) =>
			  item.documents.some((doc) => doc.uuid === folderId),
			)?._id;
			await axios.delete(`${services.GET_PRESETS}/${folderId || folderUUIDdelete}`);
			console.log("Document deleted:", folderId);
			fetchData();
		  } catch (error) {
			console.error("Error deleting document:", error);
		  }
		}
	  };
	

	useEffect(() => {
		fetchData();
		localStorage.removeItem('folderName');
	}, []);

	useEffect(() => {
		console.log("contextMenu", contextMenu);
	}, [contextMenu]);

	const getSpecialDate = (dateSpec: string): Date => {
		const today = new Date();
		if (dateSpec === DATE_SPEC_TODAY) return today;
		if (dateSpec === DATE_SPEC_YESTERDAY) {
			const yesterday = new Date(today);
			yesterday.setDate(today.getDate() - 1);
			return yesterday;
		}
		if (dateSpec === DATE_SPEC_TOMORROW) {
			const tomorrow = new Date(today);
			tomorrow.setDate(today.getDate() + 1);
			return tomorrow;
		}
		return today;
	};
	const processDates = (data: any) => {
		return data.map((item: any) => {
			const processedDocuments = item.documents.map((doc: any) => {
				let processedStartDate: Date;
				let processedEndDate: Date;

				// Check and process start_date
				if (doc.start_date === DATE_SPEC_TODAY || doc.start_date === DATE_SPEC_YESTERDAY || doc.start_date === DATE_SPEC_TOMORROW) {
					processedStartDate = getSpecialDate(doc.start_date);
				} else {
					processedStartDate = new Date(doc.start_date);
				}

				// Check and process end_date
				if (doc.end_date === DATE_SPEC_TODAY || doc.end_date === DATE_SPEC_YESTERDAY || doc.end_date === DATE_SPEC_TOMORROW) {
					processedEndDate = getSpecialDate(doc.end_date);
				} else {
					processedEndDate = new Date(doc.end_date);
				}

				return {
					...doc,
					start_date: processedStartDate,
					end_date: processedEndDate,
				};
			});
			return {
				...item,
				documents: processedDocuments,
			};
		});
	};
	const fetchData = async () => {
		try {
			const response = await axios.get(services.GET_PRESETS);
			const data = response.data;

			if (data.api_status === 'success') {
				const processedData = processDates(data.data);
				setData(processedData);
				const names = response.data.data.map((item: { _id: any }) => item._id);
				setFolderNames(names);
			} else {
				console.error('API error:', data.message);
			}
		} catch (error) {
			console.error('Fetch error:', error);
		}
	};


	const contextMenuRef = useRef<HTMLDivElement>(null);
	useClickOutside({
		ref: contextMenuRef,
		handler: () => setContextMenu({ ...contextMenu, visible: false }),
		condition: contextMenu.visible,
	});

	useClickOutside({
		ref: contextMenuRef,
		handler: () => setContextMenuVisible(false),
		condition: contextMenuVisible,
	});

	const togglePreset = () => {
		setIsPresetOpen(!isPresetOpen);
	};

	const handleCreateFolderClick = () => {
		setCreatingFolder(true);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFolderTitle(e.target.value);
	};

	const handleInputKeyPress = async (
		e: React.KeyboardEvent<HTMLInputElement> | React.DragEvent<HTMLInputElement>,
	) => {
		if ((e as React.KeyboardEvent).key === "Enter") {
			handleEnterKey();
		} else if ((e as React.DragEvent).type === "drop") {
			handleDropEvent(e as React.DragEvent<HTMLInputElement>);
		}
	};
	const handleInputKeyPressCreateButton = async (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter" && folderTitle.trim()) {
			await createFolder();
			await fetchData();
		} else if (e.key === "Enter") {
			toast.error("Folder name cannot be empty");
		}
	};

	const handleInputBlur = async () => {
		if (folderTitle.trim()) {
			await createFolder();
			await fetchData();
		}

	};

	const createFolder = async () => {
		try {
			const presets = JSON.parse(localStorage.getItem("presetName") || "[]");

			let newFolderTitle = folderTitle;
			let counter = 1;
			const presetExists = presets.some((preset: any) => preset._id === folderTitle);

			if (presetExists) {
				while (presets.some((preset: any) => preset._id === `${folderTitle}${counter}`)) {
					counter++;
				}
				newFolderTitle = `${folderTitle}${counter}`;
			}

			const uuid = uuidv4();
			const newDocument = {
				uuid,
				name: "",
				asset_id: "",
				asset_id_type: "",
				data_type: "",
				end_date: "",
				fund_group: "",
				fund_id: "",
				on_loan: false,
				settled: false,
				start_date: "",
				total: 0,
				folder_name: newFolderTitle || DEFAULT_FOLDER_NAME,
			};

			const newPreset = {
				_id: newFolderTitle,
				documents: [newDocument],
			};
			presets.push(newPreset);
			localStorage.setItem("presetName", JSON.stringify(presets));

			await axios.post(`${services.GET_PRESETS}/${uuid}`, newDocument);

			setData(presets);
			setCreatingFolder(false);
			setFolderTitle("");
		} catch (error) {
			console.error("Error creating folder:", error);
		}
	};


	const handleEnterKey = async () => {
		try {
			const uuid = uuidv4();
			const newDocument = {
				uuid: uuid,
				name: "",
				asset_id: "",
				asset_id_type: "",
				data_type: "",
				end_date: "",
				fund_group: "",
				fund_id: "",
				on_loan: false,
				settled: false,
				start_date: "",
				total: 0,
				folder_name: folderTitle,
			};

			await axios.post(services.GET_PRESETS, newDocument);

			setData(data);
			setCreatingFolder(false);
			setFolderTitle("");
		} catch (error) {
			console.error("Error creating folder:", error);
		}
	};

	const DEFAULT_FOLDER_NAME = "Untitled Folder";

	const handleDropEvent = async (e: React.DragEvent<HTMLInputElement>) => {
		try {
			e.preventDefault();
			const uuid = uuidv4();
			const draggedData = JSON.parse(e.dataTransfer.getData("application/json")) as Document;

			const {
				// asset_id,
				// asset_id_type,
				data_type,
				end_date,
				fund_group,
				// fund_id,
				name,
				// on_loan,
				// settled,
				start_date,
				// total,
			} = draggedData;

			const targetElement = e.target as HTMLInputElement;
			const folderName = targetElement.placeholder;

			const newDocument = {
				uuid: uuid,
				name: name,
				// asset_id: asset_id,
				// asset_id_type: asset_id_type,
				data_type: data_type,
				end_date: end_date,
				fund_group: fund_group,
				// fund_id: fund_id,
				// on_loan: on_loan,
				// settled: settled,
				start_date: start_date,
				// total: total,
				folder_name: folderName,
			};

			await axios.post(`${services.GET_PRESETS}/${uuid}`, newDocument);
			// Delete the old preset using its UUID
			console.log("Dragged UUID:", draggedData.uuid);
			if (draggedData && typeof draggedData.uuid === "string") {
				await axios.delete(`${services.GET_PRESETS}/${draggedData.uuid}`);
				console.log("Deleted old preset with UUID:", draggedData.uuid);
				fetchData();
			} else {
				console.error("Invalid UUID:", draggedData?.uuid);
			}
			setData(data);
			setCreatingFolder(false);
			setFolderTitle("");
		} catch (error) {
			console.error("Error creating folder from drop event:", error);
		}
	};

	const handleRename = (docId: string, currentName: string) => {
		setRenamingDocId(docId);
		setNewName(currentName);
		setContextMenu({ ...contextMenu, visible: false });
	};

	const handleRenameFolder = (folderId: string, currentName: string) => {
		setRenamingFolderId(folderId);
		setNewName(currentName);
		setContextMenu({ ...contextMenu, visible: false });
	};

	const handleRenameClick = async (docId: string) => {
		if (newName.trim() !== "") {
			try {
				await axios.post(`${services.GET_PRESETS}/${docId}`, { name: newName });
				setData(data);
				setRenamingDocId(null);
				console.log("Document renamed:", docId, newName);
				fetchData();
			} catch (error) {
				console.error("Error renaming document:", error);
			}
		} else {
			setRenamingDocId(null);
		}
	};

	const handleRenameInputEvent = async (
		e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
		docId: string,
	) => {
		if (e.type === "keypress" && (e as React.KeyboardEvent).key !== "Enter") {
			return;
		}
		await handleRenameClick(docId);
	};

	const handleRenameFolderInputKeyPress = (
		e: React.KeyboardEvent<HTMLInputElement>,
		folderId: string,
	) => {
		if (e.key === "Enter" && newName.trim() !== "") {
			try {
				localStorage.setItem(folderId, newName.trim());
				setRenamingFolderId(null);
				console.log("Folder renamed:", folderId, newName);
			} catch (error) {
				console.error("Error renaming folder:", error);
			}
		}
	};


	const renderFolders = () => {
		const folderMap = new Map<string, Document[]>();
		const presetsWithoutFolder: Document[] = [];
		localStorage.setItem("presetName", JSON.stringify(data));

		if (!data || !Array.isArray(data)) {
			fetchData();
			return null;
		}

		data.forEach((item) => {
			if (!item._id) {
				item.documents.forEach((doc: Document) => {
					presetsWithoutFolder.push(doc);
				});
				return;
			}
			if (!folderMap.has(item._id)) {
				folderMap.set(item._id, []);
			}
			for (let i = 0; i < item.documents.length; i++) {
				const doc = item.documents[i];
				folderMap.get(item._id)?.push(doc);
			}
		});

		const sortedFolderEntries = Array.from(folderMap.entries()).sort((a, b) => {
			if (a[0] === "default") return -1;
			if (b[0] === "default") return 1;
			return 0;
		});

		const presetsWithoutFolderContent = presetsWithoutFolder.map((doc, index) => (
			<Flex key={index} ml={"2rem"} align={"center"}>
				{renamingDocId === doc.uuid ? (
					<TextInput
						autoFocus
						value={newName}
						onChange={(e) => setNewName(e.target.value)}
						onKeyPress={(e) => handleRenameInputEvent(e, doc.uuid)}
						onBlur={(e) => handleRenameInputEvent(e, doc.uuid)}
						w={200}
						h={28}
						radius={"md"}
						size={"xs"}
						style={{ marginBottom: "4px" }}
					/>
				) : (
					<Text
						size={theme.fontSizes.md}
						c={theme.colors.grey[5]}
						style={{ cursor: "pointer" }}
						onClick={() => onDocumentClick(doc.uuid)}
					// onContextMenu={(event) => handleRightClick(event, doc.uuid)}
					>
						{doc.name} {/* Display blank space if name is empty */}
					</Text>
				)}
			</Flex>
		));

		const foldersContent = sortedFolderEntries.map(([folderName, documents]) => (
			<div key={folderName} className='folder-wrapper'>
				<Flex
					align={"center"}
					mt={"0.2rem"}
					mb={"0.2rem"}
				// onContextMenu={(event) => handleFolderRightClick(event, folderName)}
				>
					{folderName !== "default" && <IconWrapper name='folder' className='allfundqueryicon' />}
					{renamingFolderId === folderName ? (
						<TextInput
							autoFocus
							value={newName}
							onChange={(e) => setNewName(e.target.value)}
							onKeyPress={(e) => handleRenameFolderInputKeyPress(e, folderName)}
							onBlur={() => setRenamingFolderId(null)}
							radius={"md"}
							size={"xs"}
							className='inputfolder'
						/>
					) : (
						<TextInput
							ml={"0.2rem"}
							mt={"0.2rem"}
							size={theme.fontSizes.md}
							disabled
							c={theme.colors.grey[5]}
							unstyled
							className='dropfolder'
							onDrop={(event) => {
								event.preventDefault();
								const name = event.dataTransfer.getData("text");
								setFolderTitle(name);
								handleInputKeyPress(event);
							}}
							onDragOver={(event) => event.preventDefault()}
							placeholder={
								localStorage.getItem(folderName) || (folderName === "default" ? " " : folderName)
							}
						/>
					)}
				</Flex>

				{documents.map((doc, index) => (
					<Flex key={index} ml={"0.25rem"} align={"center"}>
						{renamingDocId === doc.uuid ? (
							<TextInput
								autoFocus
								value={newName}
								onChange={(e) => setNewName(e.target.value)}
								onKeyPress={(e) => handleRenameInputEvent(e, doc.uuid)}
								onBlur={(e) => handleRenameInputEvent(e, doc.uuid)}
								radius={"md"}
								size={"xs"}
								className='inputfolder'
							/>
						) : (
							<Text
								size={theme.fontSizes.md}
								c={theme.colors.grey[5]}
								mb={theme.spacing.xs}
								style={{ cursor: "pointer" }}
								onClick={() => onDocumentClick(doc.uuid)}
								// onContextMenu={(event) => handleRightClick(event, doc.uuid)}
								draggable
								onDragStart={(event) => {
									event.dataTransfer.setData(
										"application/json",
										JSON.stringify({
											// asset_id: doc.asset_id,
											// asset_id_type: doc.asset_id_type,
											data_type: doc.data_type,
											end_date: doc.end_date,
											fund_group: doc.fund_group,
											// fund_id: doc.fund_id,
											name: doc.name,
											// on_loan: doc.on_loan,
											// settled: doc.settled,
											start_date: doc.start_date,
											// total: doc.total,
											folder_name: doc.folder_name,
											uuid: doc.uuid,
										}),
									);
								}}
							>
								{doc.name}
							</Text>
						)}
					</Flex>
				))}
			</div>
		));

		return (
			<>
				{presetsWithoutFolderContent.length > 0 && (
					<>
						<Flex align={"center"} ml={"0.2rem"} mt={"md"}>
							<IconWrapper name='folder' className='allfundqueryicon' />
							<Text ml={"0.2rem"} size={theme.fontSizes.md} c={theme.colors.grey[5]}>
								{" "}
							</Text>{" "}
							{/* Adjust text for blank folder */}
						</Flex>
						{presetsWithoutFolderContent}
						<Box mb={3} /> {/* Margin bottom of 3rem */}
					</>
				)}
				{foldersContent.map((content, index) => (
					<React.Fragment key={index}>
						{content}
						<Box mb={3} /> {/* Margin bottom of 3rem */}
					</React.Fragment>
				))}
			</>
		);
	};

	const handleRenameEmptyFolder = (folderId: string, newName: string) => {
		handleRenameFolders("folderName", newName, setEmptyNewFolderNameDelete, setContextMenuVisible);
	};

	const handleRenameEmpty = (folderEmpty: string, currentName: string) => {
		handleRenameFolders(
			"deletedFolderName",
			newFolderName,
			setNewFolderNameDelete,
			setContextMenuVisible,
			fetchData,
		);
	};

	const handleEmptyDeleteFolder = () => {
		localStorage.removeItem("folderName");
		fetchData();

		const newEmptyFolderName = localStorage.getItem("folderName");
		if (newEmptyFolderName) {
			localStorage.removeItem("folderName");
			console.log(`Cleared 'deletedFolderName' from localStorage`);
		}
		window.location.reload();
	};

	const handleDeleteEmptyFolder = () => {
		localStorage.removeItem("deletedFolderName");
		fetchData();

		const deletedFolderName = localStorage.getItem("deletedFolderName");
		if (deletedFolderName) {
			localStorage.removeItem("deletedFolderName");
			console.log(`Cleared 'deletedFolderName' from localStorage`);
		}
		window.location.reload();
	};

	const handleDrop = (event: any) => {
		event.preventDefault();
		const name = event.dataTransfer.getData("text");
		setFolderTitle(name);
		handleInputKeyPress(event);

		Object.keys(localStorage).forEach((key) => {
			if (key.includes("folderName")) {
				localStorage.removeItem(key);
			}
		});

		const storedFolderNames = Object.keys(localStorage)
			.filter((key) => key.startsWith("folderName"))
			.map((key) => localStorage.getItem(key) || "");
		setStoredFolders(storedFolderNames);
	};

	const handleDropLocalStorage = (event: any) => {
		event.preventDefault();
		const name = event.dataTransfer.getData("text");
		setFolderTitle(name);
		handleInputKeyPress(event);
	};

	const checkDelete: string[] = folderNames;
	const handleKeyDownDelete = (event: { key: string }) =>
		handleEvent(event, newFolderName, "deletedFolderName", setNewFolderNameDelete);
	const handleBlurSaveDelete = () =>
		handleSave(newFolderName, "deletedFolderName", setNewFolderNameDelete);
	const handleKeyDownEmpty = (event: { key: string }) =>
		handleEvent(event, newEmptyFolderName, "folderName", setEmptyNewFolderNameDelete);
	const handleBlurSaveEmpty = () =>
		handleSave(newEmptyFolderName, "folderName", setEmptyNewFolderNameDelete);
	const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		event.target.select();
	};
	const deletedName: string = localStorage.getItem("deletedFolderName") as string;

	return (
		<div>
			<Box maw={400} mx='auto' mt={"-0.99rem"} p={"relative"}>
				<Box h={"5vh"}>
					<Flex justify='flex-start'>
						<CustomActionIcon onClick={togglePreset} />
					</Flex>
					<Collapse in={isPresetOpen}>
						<Card
							bg={theme.colors.grey[1]}
							w={"18.75rem"}
							h={"90vh"}
							mt={"-3.22rem"}
							ml={"-1rem"}
							radius={"0"}
						>
							<Flex justify={"space-between"} align={"center"} mt={"-0.3rem"}>
								<ActionIcon
									variant='transparent'
									color={theme.colors.grey[8]}
									onClick={togglePreset}
								>
									<IconWrapper name='allfundtab' />
								</ActionIcon>
								<Text fw={"700"} size={theme.fontSizes.xs} c={theme.colors.grey[5]}>
									{t("PRESETS")}
								</Text>
								<ActionIcon
									variant='transparent'
									color={theme.colors.grey[8]}
									onClick={handleCreateFolderClick}
								>
									<IconWrapper name='createfolder' className='allfundqueryicon' />
								</ActionIcon>
							</Flex>

							<div className={"preset-search"}>
								<TextInput
									w={259}
									h={28}
									placeholder={t("SEARCH")}
									leftSection={<IconWrapper name='search' />}
									radius={"md"}
									fs={"13px"}
									size={"xs"}
									mb={"lg"}
								/>
							</div>
							{creatingFolder && (
								<>
									{renderFolders()}
									<Flex align={"center"} ml={"xl"} mb={"md"} className='folder-wrapper'>
										<IconWrapper name='folder' className='allfundqueryicon' />
										<TextInput
											autoFocus
											value={folderTitle}
											onChange={handleInputChange}
											onKeyPress={handleInputKeyPressCreateButton}
											onBlur={handleInputBlur}
											ml={"0.2rem"}
											mt={"0.2rem"}
											size={theme.fontSizes.md}
											c={theme.colors.grey[5]}
											placeholder={"Untitled Folder"}
											className='inputfolder'
										/>
									</Flex>
								</>
							)}

							<div>
								{storedFolders.length > 0 &&
									storedFolders.map((folder, index) => (
										<div key={index}>
											<Flex
												ml={theme.spacing.xl}
												mt={"md"}
												align={"center"}
												onContextMenu={(event) => handleRightClick(event, folder)}
											>
												<IconWrapper name='folder' className='allfundqueryicon' />
												{newEmptyFolderNameDelete ? (
													<TextInput
														ref={inputRef}
														ml={"0.2rem"}
														size={theme.fontSizes.md}
														c={theme.colors.grey[5]}
														defaultValue={folder}
														onChange={(event) => setNewEmptyFolderName(event.target.value)}
														onKeyDown={handleKeyDownEmpty}
														onBlur={handleBlurSaveEmpty}
														className='inputfolder'
														onFocus={handleFocus}
														autoFocus
													/>
												) : (
													<TextInput
														ml={"0.2rem"}
														size={theme.fontSizes.md}
														c={theme.colors.grey[5]}
														disabled
														className='dropfolder'
														placeholder={folder}
														onDrop={handleDrop}
														onDragOver={(event) => event.preventDefault()}
														autoFocus
													/>
												)}
											</Flex>
										</div>
									))}

								{localStorage.getItem("deletedFolderName") &&
									!checkDelete.includes(localStorage.getItem("deletedFolderName") as string) &&
									deletedName !== "default" && (
										<div className='folder-wrapper'>
											<Flex
												align={"center"}
												ml={"0.2rem"}
												mt={"0.2rem"}
												onContextMenu={(event) => handleRightClick(event, deletedName)}
											>
												<IconWrapper name='folder' className='allfundqueryicon' />
												<FolderTextInput
													newFolderNameDelete={newFolderNameDelete}
													newFolderName={newFolderName}
													setNewFolderName={setNewFolderName}
													handleDropLocalStorage={handleDropLocalStorage}
													handleKeyDownDelete={handleKeyDownDelete}
													handleBlurSaveDelete={handleBlurSaveDelete}
													handleFocus={handleFocus}
												/>
											</Flex>
										</div>
									)}
							</div>

							{!creatingFolder && renderFolders()}
							<div style={{ marginLeft: "-5.5rem" }} ref={contextMenuRef}>
								<ContextMenu
									x={contextMenu.x}
									y={contextMenu.y}
									visible={contextMenu.visible}
									actionRename={contextMenu.renameFunction}
									actionDelete={contextMenu.deleteFunction}
									actionTextRename={t("RENAME")}
									actionTextDelete={t("DELETE")}
								/>
							</div>
						</Card>
					</Collapse>
				</Box>
			</Box>
		</div>
	);
}
