import React, { useEffect, useState } from "react";
import { OrderTable } from "../../components/Algojournal/OrderTable";
import "./AnalysisOrderTab.css";
import axios from "axios";
import { Flex, useMantineTheme, Text } from "@mantine/core";
import { services } from "../../services";
import { useParams, useSearchParams } from "react-router-dom";
import PageTitle from "../../components/Common/PageTitle";
import { AgTableComponent } from "../../components/Agtablehomepage/AgTableComponent";
import { RowInfo } from "../../types";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../components/Common/ErrorMessage";
import { useAppSelector } from "../../store/hooks";
import SkeletonLoader from "../../components/Common/Skeleton";
import { getCurrentUTCDate } from "../../utils/dateUtils";
import { computeOrderSummary } from "../../utils/responseUtils";
import { currentDate } from "../../constants";

const OrderCompletion = () => {
    const [orderData, setOrderData] = useState<any>(null);
    const theme = useMantineTheme();
    const [searchParams] = useSearchParams();
    const { fund_id, transaction_id, target_date } = useParams();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { t } = useTranslation();
    const [rowDataSet, setRowDataSet] = useState<RowInfo[]>([]);
    const { user } = useAppSelector(state => state.user);
    const targetMode = searchParams.get("target_mode");
    const formattedTargetMode = targetMode ? targetMode.toUpperCase().replace(/-/g, "_") : null;
    const [isLoading, setIsLoading] = useState(true);
    const [totalBuyShare, setTotalBuyShare] = useState<number>();
    const [totalSellShare, setTotalSellShare] = useState<number>();

    const [totalBuyAmountInMillions, setTotalBuyAmountInMillions] = useState<number>();
    const [totalSellAmountInMillions, setTotalSellAmountInMillions] = useState<number>();

    const [totalBuyPct, setTotalBuyPct] = useState<number>();
    const [totalSellPct, setTotalSellPct] = useState<number>();

	const [orderListId, setOrderListId] = useState<string[]>([]);


    const POLLING_INTERVAL = Number(process.env.POLLING_INTERVAL) || 5000;
    const MAX_ATTEMPTS = Number(process.env.MAX_POLLING_ATTEMPTS) || 10;

    const queryParams = {
        market_state: targetMode || '',
        fund_state: "FUND_STATE_COMPLETED",
        date: getCurrentUTCDate()
    };

    const fetchOrder = async (attempt = 1) => {
        try {
            const uuid = localStorage.getItem('uuid');
            const response = await axios.get(services.ORDER_LIST, {
                params: {
                    fund_id,
                    date: target_date,
                    target_mode: formattedTargetMode
                },
            });

            if (response.data.data) {
                setOrderData(response.data);
                let fundStats = await axios.get(`${services.FUND_STATE}?${new URLSearchParams(queryParams)}`);
                const filteredFund = fundStats.data.data.filter((fund: any) => fund.fund_id === fund_id);

                const transformedData = filteredFund.map((item: any, index: number) => ({
                    sr_no: index + 1,
                    fund_id: item.fund_id,
                    fund_name: item.fund_attributes.fund_name,
                    tol_start: item.start_of_the_day_status === "TOLERANCE_OUT" ? t("OUT") : t("IN"),
                    tol_current: (() => {
                        const readyStateFlags =
                            item.fund_state?.fund_ready_state_flags || item.fund_state?.fund_completed_state_flags;

                        if (readyStateFlags && Object.values(readyStateFlags).some((flag) => flag)) {
                            const readyState = Object.keys(readyStateFlags).find((key) => readyStateFlags[key]);

                            if (readyState) {
                                return readyState.split("_")[0].toUpperCase();
                            }
                        }
                        return null;
                    })(),
                    order_status: "Ready",
                    next_step: "Review orders",
                    wip: item.wipStatus,
                    spend_cash_bef: item.fund_stats.spendable_cash.toFixed(3),
                    spend_cash_aft: item.new_fund_stats.spendable_cash.toFixed(3),
                    unequitized_cash_bef: `${item.fund_stats.unequitized_cash.toFixed(3)}`,
                    unequitized_cash_aft: `${item.new_fund_stats.unequitized_cash.toFixed(3)}`,
                    assets: `${item.fund_stats.asset_max_active_weight.toFixed(3)}`,
                    industry: `${item.fund_stats.sector_max_active_weight.toFixed(3)}`,
                    country: `${item.fund_stats.country_max_active_weight.toFixed(3)}`,
                    currency: `${item.fund_stats.currency_max_active_weight.toFixed(3)}`,
                    size: `${item.fund_stats.size_max_active_weight.toFixed(3)}`,
                    shortpos: `${item.fund_stats.num_short_positions.toFixed(3)}`,
                    nohold: `${item.fund_stats.num_no_holds.toFixed(3)}`,
                    nonbench: `${item.fund_stats.num_non_benchmark_assets.toFixed(3)}`,
                    activerisk: `${item.fund_stats.active_risk.toFixed(3)}`,
                    equityactiverisk: `${item.fund_stats.active_risk.toFixed(3)}`,
                    as_of_date: item.as_of_date,
                    transaction_id: item.transaction_id,
                    target_date_data_date: item.target_date_data_date,
                    is_fund_locked: item.is_fund_locked,
                }));

                setRowDataSet(transformedData);

                setIsLoading(false);

				let { total_buy_shares, 
                    total_sell_shares, 
                    total_buy_amount_in_millions, 
                    total_sell_amount_in_millions, 
                    total_buy_pct, 
                    orderListArray } = computeOrderSummary(response);

				setTotalBuyShare(total_buy_shares);
				setTotalSellShare(total_sell_shares);

				setTotalBuyAmountInMillions(total_buy_amount_in_millions);
				setTotalSellAmountInMillions(total_sell_amount_in_millions);

				setTotalBuyPct(total_buy_pct);
				setTotalSellPct(total_buy_pct);

                return;
            }
        } catch (error: any) {
            console.error("Error making API request:", error);
            handleApiError(error);
        }

        if (attempt < MAX_ATTEMPTS) {
            setTimeout(() => fetchOrder(attempt + 1), POLLING_INTERVAL);
        } else {
            setIsLoading(false);
            setErrorMessage("Max attempts reached. No Data Found");
        }
    };

    const handleApiError = (error: any) => {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    setErrorMessage(error.response.data.detail || "Unknown error occurred");
                    break;
                case 403:
                    setErrorMessage(error.response.data.detail || "Token Expired");
                    break;
                case 500:
                    setErrorMessage(error.response.data.detail || "Internal Server Error");
                    break;
                default:
                    setErrorMessage("No Data Found");
                    break;
            }
        } else {
            setErrorMessage("No Data Found");
        }
    };

    useEffect(() => {
        fetchOrder();
    }, []);

    if (isLoading) {
        return <div><SkeletonLoader /></div>;
    }

    if (errorMessage) {
        return <ErrorMessage errorMessage={errorMessage} />;
    }

    const allOrders: any[] =
        orderData?.data?.reduce((accumulator: any[], entry: any) => {
            const filteredOrders = entry.orders.map((order: any) => {
                const { column1, column2, ...rest } = order;
                return rest;
            });
            return accumulator.concat(filteredOrders);
        }, []) || [];

    return (
        <div>
            <PageTitle TITLE={fund_id} back />
            <div style={{ padding: theme.spacing.xxl }}>
                <Text fw='700' size='1.25rem' mb={"1rem"}>Status of the fund at the order completion</Text>
                <AgTableComponent rowData={rowDataSet} />
                <OrderTable
                    key='commonHeader'
                    orders={allOrders}
                    TITLE={"List of completed orders"}
                    initialTotalBuyShares={totalBuyShare}
                    initialTotalSellShares={totalSellShare}
                    initialTotalBuyAmountInMillions={totalBuyAmountInMillions}
                    initialTotalSellAmountInMillions={totalSellAmountInMillions}
                    initialTotalBuyPct={totalBuyPct}
                    initialTotalSellPct={totalSellPct} 
                    initialOrderListID={orderListId}/>
            </div>
        </div>
    );
};

export default OrderCompletion;
