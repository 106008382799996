import './Leftnav.css';
import { Box, Button, Container, Flex, Paper, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../routes';
import { useRef } from 'react';
import IconWrapper from '../Common/IconWrapper';
import { handleLogout } from '../../utils/handleLogOut';

const AdminNavbar = () => {
    const theme = useMantineTheme();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const paperRef = useRef<HTMLDivElement>(null);

    const labelsWithIcons = [
        {
            icon: <IconWrapper name="dashboard" />,
            label: t("DASHBOARD"),
            link: routes.DASHBOARD
        },
        {
            icon: <IconWrapper name="usersAdmin" />,
            label: t("USERS"),
            link: routes.USERS
        },
        {
            icon: <IconWrapper name="funds" />,
            label: t("INVESTMENTS"),
            link: routes.INVESTMENTS
        },
        {
            icon: <IconWrapper name="preferences" />,
            label: t("PREFERENCES"),
            link: routes.COMINGPREFERENCE
        },
        {
            icon: <IconWrapper name="softwareUpdates" />,
            label: t("SOFTWARE_UPDATES"),
            link: routes.COMINGUPDATES
        },
        {
            icon: <IconWrapper name="support" />,
            label: t("CUSTOMER_SUPPORT"),
            link: routes.COMINGSUPPORT
        },
    ];

    return (
        <Container>
            <Paper shadow="sm" bg={theme.colors.grey[0]} className='paper' ref={paperRef}>
                <div className='header'>
                    <Box className={"header"}>
                        <IconWrapper name='Logo-long' className="nav-logo" />
                    </Box>
                </div>
                <Box className="inputnavbar">
                    <TextInput
                        w={180}
                        h={28}
                        placeholder={t("ASK_ANYTHING")}
                        leftSection={<IconWrapper name="AskAnythingIcon" />}
                        radius={"md"}
                        size={"xs"}
                        variant="filled"
                    />
                </Box>
                <Box mt={'md'}>
                    {labelsWithIcons.map((item, idx) => (
                        <Link key={idx} to={item.link}>
                            <Flex
                                className={`usernameTer liststyle`}
                                ml={'0.47rem'}
                                style={{
                                    background: item.link === pathname
                                        ? theme.colors.grey[9]
                                        : theme.colors.grey[0],
                                }}
                            >
                                {item.icon}
                                <Text
                                    className='fundlisttext'
                                    color={theme.colors.grey[5]}
                                    ml="md"
                                    mt={'-0.1rem'}
                                >
                                    {item.label}
                                </Text>
                            </Flex>
                        </Link>
                    ))}
                </Box>
                <Button bg={'transparent'} mt={'1.5rem'} ml={'-1.25rem'} onClick={handleLogout}>
                    <Flex
                        className={`usernameTer liststyle`}
                        align="flex-start"
                        justify="flex-start"
                    >
                        <IconWrapper name='sign-out' />
                        <Text
                            className='fundlisttext'
                            color={theme.colors.grey[5]}
                            ml="md"
                            mt={'-0.1rem'}
                        >
                            {t("SIGN_OUT")}
                        </Text>
                    </Flex>
                </Button>

            </Paper>
        </Container>
    );
};

export default AdminNavbar;