import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridOptions } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Box, Text } from '@mantine/core';
import CustomNoRowsOverlay from '../../Common/customNoRowsOverlay';
import "../../../pages/AdminPages/Users/AdminPage.css";
import { useDisclosure } from '@mantine/hooks';
import axios from 'axios';
import { services } from '../../../services';
import { FundTableProps, FundGroups } from '../../../types';
import FundGroupModalUser from './FundGroupModalUser';
import DeleteModalUser from '../DeleteModalUser';
import AdminTableHeader from '../AdminTableHeader';
import { t } from 'i18next';
import { useDeselectAllOnRowUnselect } from '../../../utils/useDeselectAllOnRowUnselect';

const FundGroup: React.FC<FundTableProps> = ({ fundGroupCounts, roleAndFundGroupCounts, onUniqueFundGroup }) => {
    const [loading, setLoading] = useState(true);
    const [group, setGroup] = useState<FundGroups[]>([]);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [modalType, setModalType] = useState<null | 'create' | 'update' | 'delete'>(null);
    const [modalOpened, { open: openModal, close: closeModal }] = useDisclosure(false);
    const gridRef = useRef<AgGridReact<any>>(null);
    const [gridApi, setGridApi] = useState<any>(null);

    const handleSelectionChanged = (event: any) => {
        setIsRowSelected(event.api.getSelectedNodes().length > 0);
    };

    const handleModalToggle = (type: 'create' | 'update' | 'delete') => {
        if (modalType === type && modalOpened) {
            setModalType(null);
            closeModal();
        } else {
            setModalType(type);
            if (!modalOpened) openModal();
        }
    };

    const handleDeleteUser = () => {
        console.log("Delete API coming soon");
    };

    const fetchFundGroup = () => {
        axios.get(services.GET_FUND_GROUPS)
            .then(response => {
                const fundGroups = response.data;
                setGroup(fundGroups.map((fundGroupObj: { fundGroup: string, uniqueUserCount: number, uniqueRoleCount: number }) => ({
                    fund_group: fundGroupObj.fundGroup,
                    userCounts: fundGroupObj.uniqueUserCount,
                    roleCount: fundGroupObj.uniqueRoleCount
                })));
                setLoading(false);
    
                const uniqueFundGroup = Array.from(new Set(fundGroups.map((fg: { fundGroup: string }) => fg.fundGroup)));
                console.log("uniqueDescriptions", uniqueFundGroup);
    
                onUniqueFundGroup(uniqueFundGroup);
            })
            .catch(error => {
                console.error('Error fetching fund groups:', error);
                setLoading(false);
            });
    };
    

    useEffect(() => {
        fetchFundGroup();
    }, []);

    const columnDefs: ColDef[] = [
        { headerName: t("FUND_ID"), field: 'fund_id' },
        { headerName: t('FUND_GROUP'), field: 'fund_group' },
        {
            headerName: t('ROLES_COUNT'),
            field: 'roleCount',
            valueGetter: (params: { data: any; }) => {
                const fundGroup = params.data.fund_group;
                const uniqueRoles = roleAndFundGroupCounts[fundGroup];
                return uniqueRoles ? uniqueRoles.size : 0;
            }
        },
        {
            headerName: t('USER_COUNT'), field: 'userCounts',
            valueGetter: (params: { data: any; }) => fundGroupCounts[params.data.fund_group] || 0
        }
    ];

    const defaultColDef = {
        flex: 1,
        minWidth: 150,
    };

    const customHeight = () => {
        if (group.length > 1) {
            const heightInRem = 2.15 + (group.length * 1.75);
            return `${heightInRem}rem`;
        } else {
            return "3.8rem";
        }
    };

    const rowSelectionType = 'single';
    const rowHeight = 30;

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => `- ${t("NO_FUND_GROUP_HERE")} -`,
        };
    }, []);

    const gridOptions: GridOptions = {
        suppressRowHoverHighlight: true,
    };

    const gridApiRef = useRef<any>(null);

    const onGridReady = (params: any) => {
        gridApiRef.current = params.api; // Store gridApi in ref
        setGridApi(params.api);
    };

    useDeselectAllOnRowUnselect(gridApiRef, isRowSelected);

    return (
        <Box w={'80rem'} onClick={() => setIsRowSelected(false)}>
            <Box maw={"57.2rem"}>
                <Box mr={'8.5rem'}>
                    <AdminTableHeader
                        isRowSelected={isRowSelected}
                        handleEditUser={() => handleModalToggle('update')}
                        handleDeleteClick={() => handleModalToggle('delete')}
                        resetCreateUser={() => handleModalToggle('create')}
                        headerTitle='FUND_GROUPS'
                        createTitle='CREATE_FUND_GROUP'
                    />
                </Box>

                <div className='action-item ag-theme-quartz'
                    style={{
                        height: customHeight(),
                        width: "85%",
                        maxHeight: "23.125rem",
                        minHeight: "3.8rem",
                        marginTop: "0.7rem",
                    }}
                >
                    <AgGridReact
                        rowData={group}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowSelection={rowSelectionType}
                        rowHeight={rowHeight}
                        noRowsOverlayComponent={CustomNoRowsOverlay}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                        onSelectionChanged={handleSelectionChanged}
                        gridOptions={gridOptions}
                        rowMultiSelectWithClick={true}
                        onGridReady={onGridReady}
                    />
                </div>

                <FundGroupModalUser opened={modalOpened && modalType === 'create'} onClose={() => handleModalToggle('create')} TITLE='CREATE_NEW_FUND_GROUP' buttonName="CREATE" />
                <FundGroupModalUser opened={modalOpened && modalType === 'update'} onClose={() => handleModalToggle('update')} TITLE='UPDATE_FUND_GROUP' buttonName="SET" />
                <DeleteModalUser
                    opened={modalOpened && modalType === 'delete'}
                    onClose={() => handleModalToggle('delete')}
                    modalType={modalType}
                    TITLE='DELETE_FUND_GROUP'
                    message='DELETE_MESSAGE'
                    onSubmit={handleDeleteUser}
                />
            </Box>
        </Box>
    );
};

export default FundGroup;
