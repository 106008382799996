import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community'; // Import ColDef for typing
import 'ag-grid-community/styles/ag-grid.css'; // Import Ag-Grid styles
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme
import axios from 'axios';
import LoaderIcon from '../../../components/Common/LoaderIcon';

const Attributes: React.FC = () => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetching API data
  useEffect(() => {
    axios.get('/api/fund-attributes')
      .then((response) => {
        setRowData(response.data.data); 
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  const columnDefs: ColDef[] = useMemo(() => [
    { 
      headerName: '', 
     valueGetter: (params) => (params.node && params.node.rowIndex !== null) ? params.node.rowIndex + 1 : '',
      width: 80, 
      sortable: false, 
      filter: false,
      cellStyle: { textAlign: 'right' },
    },
    { headerName: 'Fund Name', field: 'fund_name' },
    { headerName: 'Primary Fund Manager', field: 'primary_fund_manager' },
    { headerName: 'Currency', field: 'fund_currency' },
    { headerName: 'Domicile', field: 'fund_domicile' },
    { headerName: 'Jurisdiction', field: 'legal_jurisdiction' },
    { headerName: 'Benchmark ID', field: 'benchmark_id' },
    { headerName: 'Fund Structure', field: 'fund_structure' },
    { headerName: 'Fund ID', field: 'fund_id' },
    { headerName: 'Group', field: 'fund_group' },
    { headerName: 'Valuation Type', field: 'valuation_type' },
    { headerName: 'Optimal Futures Basket ID', field: 'optimal_futures_basket_id' },
    {
      headerName: 'Forty Act Fund',
      field: 'forty_act_fund',
      cellRenderer: (params: { value: any; }) => (params.value ? 'Yes' : 'No'),
    },
    {
      headerName: 'Qualified Institutional Investor',
      field: 'qualified_institutional_investor',
      cellRenderer: (params: { value: any; }) => (params.value ? 'Yes' : 'No'),
    },
    {
      headerName: 'Qualified Sophisticated Investor',
      field: 'qualified_sophisticated_investor',
      cellRenderer: (params: { value: any; }) => (params.value ? 'Yes' : 'No'),
    },
    {
      headerName: 'Eligible under Rule 144A',
      field: 'eligible_under_rule_144a',
      cellRenderer: (params: { value: any; }) => (params.value ? 'Yes' : 'No'),
    },
  ], []);
  

  const customHeight = () => {
    if (rowData.length > 1) {
        const heightInRem = 1.876 + rowData.length * 1.9 ;
        return `${heightInRem}rem`;
    }};

  return (
    <> {loading ? (
      <LoaderIcon name='loading-large' alt='loading icon' />
    ) : (<div className='fund-query ag-theme-quartz' style={{
      height: customHeight(),
      width: "57.625rem",
      minHeight: "4.4rem",
      maxHeight:'85vh',
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}>

      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        suppressRowHoverHighlight={true}
        rowHeight={30}
        defaultColDef={{
          sortable: true,
          filter: true,
          resizable: true,
        }}
      />

    </div>)}
    </>

  );
};

export default Attributes;
