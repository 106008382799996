import React from "react";
import { Link } from "react-router-dom";
import { Text } from "@mantine/core";
import IconWrapper from "../Common/IconWrapper";
import CustomBadge from "./CustomBadge";

interface SidebarItemProps {
	title?: string; 
	icon: string;
	label: string;
	count?: number;
	path: string;
	isSelected: boolean;
	onClick: () => void;
    hideHeaderCount?: boolean; 
    isNavItem?: boolean;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
	title,
	icon,
	label,
	count,
	path,
	isSelected,
	onClick,
    hideHeaderCount,
    isNavItem = false,
}) => {
	return (
		<div>
			{title && (
				<Link to={path} className='section-header-link'>
					<div className='section-header'>
						<Text fw={700} size='md' c='gray'>
							{title}
						</Text>
						{!hideHeaderCount && count !== undefined && (
							<CustomBadge count={count} className='header-count-badge' />
						)}
					</div>
				</Link>
			)}
			<Link to={path} className={`label-link ${isSelected ? "clicked-row" : ""}`}onClick={onClick}>
                <div className="label-row
                ">
					<IconWrapper name={icon} className={`label-icon ${isNavItem ? "nav-item-icon" : ""}`} />
					<Text size='sm' fw={500} className='label-text'>
						{label}
					</Text>
					{count !== undefined && <CustomBadge count={count} className='section-count-badge' />}
				</div>
			</Link>
		</div>
	);
};

export default SidebarItem;
