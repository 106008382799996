import { Button, TextInput, useMantineTheme, Text, Flex, Modal, Checkbox } from "@mantine/core";
import { SetStateAction, useEffect, useState } from "react";
import { CalendarComponent } from "../../components/AllFundQuery/CalendarComponent";
import axios from "axios";
import AllFundQueryTable from "../../components/AllFundQuery/AllFundQueryTable";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/Common/PageTitle";
import ErrorMessage from "../../components/Common/ErrorMessage";
import { format, isToday, isYesterday, isTomorrow, isValid, subDays, addDays } from 'date-fns';
import { useResizable } from "react-resizable-layout";
import 'react-resizable/css/styles.css';
import "./AllFundQuery.css";
import { cn } from "../../utils/cn";
import SampleSplitter from "../../components/Common/Splitter/SampleSplitter";
import { Preset } from "../../components/AllFundQuery/Preset";
import { services } from "../../services";
import { v4 as uuidv4 } from 'uuid';
import Select from "../../components/Common/Select/Select";
import SavePresetModal from "../../components/AllFundQuery/SavePresetModal";
import React from "react";
import { formatDate } from "../../components/AllFundQuery/PresetsOptions2/PresetsUtils";
import { getDateFromSpecifier } from "../../utils/calendarUtils";

type PresetFunds = string | any[];

export function AllFundQuery() {
    const theme = useMantineTheme();
    // const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedTypeOfData, setSelectedTypeOfData] = useState<string | null>("holdings");
    const [fundGroup, setFundGroup] = useState<string>("");
    const [fundId, setFundId] = useState<string>("");
    const [assetId, setAssetId] = useState<string>("");
    const [assetIdType, setAssetIdType] = useState<string | null>("sedol");
    const today = new Date();
    const [selectedFromDate, setSelectedFromDate] = useState<Date | null>(today);
    const [selectedToDate, setSelectedToDate] = useState<Date | null>(today);
    const [isFundGroupDisabled, setIsFundGroupDisabled] = useState(false);
    const [isFundIdDisabled, setIsFundIdDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isSavePresetModalOpen, setIsSavePresetModalOpen] = useState(false);
    const [gridData, setGridData] = useState<any[]>([]);
    const [isPresetOpen, setIsPresetOpen] = useState(true);
    const [presetFunds, setPresetFunds] = useState<PresetFunds>([]);
    const [presetFundGroup, setPresetFundGroup] = useState<PresetFunds>([]);
    const [presetAssetID, setPresetAssetID] = useState<PresetFunds>([]);
    const [presetStartDate, setPresetStartDate] = useState<Date | null>(today);
    const [presetEndDate, setPresetEndDate] = useState<Date | null>(today);
    const [presetDataType, setPresetDataType] = useState<string[]>([]);
    const [presetAssetType, setPresetAssetType] = useState<string[]>([]);
    const [isPresetDataLoaded, setIsPresetDataLoaded] = useState<boolean>(false);
    const [presetName, setPresetName] = useState<string>("");
    const [selectedFolderName, setSelectedFolderName] = useState('');

    const [checkboxState, setCheckboxState] = React.useState<{
        settled: boolean;
        onLoan: boolean;
        total: boolean;
        approved: boolean;
        pending: boolean;
        executed: boolean;
        confirmed: boolean;
        unsettled: boolean;
    }>({
        settled: false,
        onLoan: false,
        total: false,
        approved: false,
        pending: false,
        executed: false,
        confirmed: false,
        unsettled: false,
    });

    const checkboxConfig: Record<string, { key: keyof typeof checkboxState; label: string }[]> = {
        Holdings: [
            { key: 'settled', label: 'SETTLED' },
            { key: 'onLoan', label: 'ON_LOAN' },
            { key: 'total', label: 'TOTAL' },
        ],
        Orders: [
            { key: 'approved', label: 'APPROVED' },
            { key: 'pending', label: 'PENDING' },
        ],
        Trades: [
            { key: 'executed', label: 'EXECUTED' },
            { key: 'confirmed', label: 'CONFIRMED' },
            { key: 'unsettled', label: 'UNSETTLED' },
        ],
        INDEX_CHANGE: [],
        TARGETS_TOLS: [],
    };

    const handleCheckboxChange = (key: keyof typeof checkboxState) => {
        setCheckboxState((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const checkboxes = selectedTypeOfData ? checkboxConfig[selectedTypeOfData] || [] : [];

    const isResizing = 200;

    const toggleSavePresetModal = () => {
        setIsSavePresetModalOpen(!isSavePresetModalOpen);
    };

    const handleRefreshStateFundGroup = () => {
        setFundId('');
        setPresetFunds('');
        setPresetAssetID('');
    };
    const handleRefreshStateFundId = () => {
        setFundGroup('');
        setPresetFundGroup('');
        setPresetAssetID('');
    };

    const handleRefreshStateAssetId = () => {
        setPresetFunds('');
        setPresetFundGroup('');
    };

    const handleDocumentClick = async (uuid: string) => {
        setIsLoading(true);
        const initialPresetFunds = ' ';
        setPresetFunds(initialPresetFunds);
        setFundId(initialPresetFunds);
        setPresetFundGroup(initialPresetFunds);
        setFundGroup(initialPresetFunds);
        setPresetAssetID(initialPresetFunds);
        setIsPresetDataLoaded(false);
        setIsLoading(true);

        try {
            // Get the entire data set from local storage
            const storedData = localStorage.getItem('presetName');

            if (storedData) {
                const data = JSON.parse(storedData);

                let presetItem = null;
                for (const folder of data) {
                    presetItem = folder.documents.find((item: { uuid: string }) => item.uuid === uuid);
                    if (presetItem) break;
                }

                if (presetItem) {
                    setPresetFunds(presetItem.fund_id);
                    setPresetAssetID(presetItem.asset_id);
                    setPresetFundGroup(presetItem.fund_group);
                    setPresetStartDate(new Date(presetItem.start_date));
                    setPresetEndDate(new Date(presetItem.end_date));
                    setPresetDataType(presetItem.data_type);
                    setPresetAssetType(presetItem.asset_id_type);
                    setIsPresetDataLoaded(true);
                } else {
                    console.error("No matching item found in local storage for the provided UUID.");
                }
            } else {
                console.error("No data found in local storage.");
            }
        } catch (error) {
            console.error("Error fetching document data:", error);
        } finally {
            setIsLoading(false);
        }
    };


    const checkDataType = (presetDataType: string | any[]): string => {
        if (presetDataType.length > 0) {
            switch (presetDataType) {
                case "DATA_TYPE_TRADES":
                    return "Holdings";
                case "DATA_TYPE_ORDERS":
                    return "Orders";
                case "DATA_TYPE_BENCHMARK_CHANGES":
                    return "Index Change";
                case "DATA_TYPE_TRADES":
                    return "Trades";
                case "DATA_TYPE_TARGETS_AND_TOLERANCES":
                    return "Targets & TOLs";
                default:
                    return selectedTypeOfData || "Holdings";
            }
        } else {
            return selectedTypeOfData || "";
        }
    };

    const checkAssetType = (presetAssetType: string | any[]): string => {
        if (presetAssetType.length > 0) {
            switch (presetAssetType) {
                case "sedol":
                    return "sedol";
                case "cusip":
                    return "cusip";
                case "isin":
                    return "isin";
                case "bloomberg_ticker":
                    return "bloomberg_ticker";
                case "acumain_asset_id":
                    return "acumain_asset_id";
                default:
                    return assetIdType || "sedol";
            }
        } else {
            return assetIdType || "";
        }
    };

    const checkFundID = (presetFunds: PresetFunds) => {
        if (Array.isArray(presetFunds) && presetFunds.length > 0) {

            return presetFunds.join(",");
        } else if (typeof presetFunds === 'string') {

            return presetFunds || fundId;
        } else {

            return ""; // This is where the error occurs
        }
    };

    const checkFundGroup = (presetFundGroup: PresetFunds) => {
        if (Array.isArray(presetFundGroup) && presetFundGroup.length > 0) {
            return presetFundGroup.join(",");
        } else if (typeof presetFundGroup === 'string') {
            return presetFundGroup || fundGroup;
        } else {
            return ''; // Default value when presetFundGroup is not a string or array
        }
    };

    const checkAssetID = (presetAssetID: PresetFunds) => {
        if (Array.isArray(presetAssetID) && presetAssetID.length > 0) {
            return presetAssetID.join(",");
        } else if (typeof presetAssetID === 'string') {
            return presetAssetID || assetId;
        } else {
            return '';
        }
    };

    const handleFromDateChange = (date: Date | null) => {
        setSelectedFromDate(date);
    };

    useEffect(() => {
        const initialDataType = checkDataType(presetDataType);
        setSelectedTypeOfData(initialDataType);
        const updatedFundId = checkFundID(presetFunds);
        setFundId(updatedFundId);
        const updateFundGroup = checkFundGroup(presetFundGroup);
        setFundGroup(updateFundGroup);
        const updatedAssetId = checkAssetID(presetAssetID)
        setAssetId(updatedAssetId)
        const updatedAssetType = checkAssetType(presetAssetType)
        setAssetIdType(updatedAssetType)
        setSelectedFromDate(presetStartDate)
        setSelectedToDate(presetEndDate)
        if (presetDataType && presetFunds && presetFundGroup) {
            setIsPresetDataLoaded(true);
        } else {
            setIsPresetDataLoaded(false);
        }

    }, [presetDataType, presetFunds, presetFundGroup]);

    const {
        isDragging: isTerminalDragging,
        position: terminalH,
        splitterProps: terminalDragBarProps,
    } = useResizable({
        axis: "y",
        initial: isResizing,
        min: 0,
        reverse: true,
    });


    const handleToDateChange = (date: Date | null) => {
        setSelectedToDate(date);
    };

    const handleSubmit = async () => {
        setIsLoading(true);

        const formData = {
            fund_group: fundGroup,
            fund_id: fundId,
            asset_id: assetId,
            asset_id_type: assetIdType,
            start_date: getDateFromSpecifier(selectedFromDate),
            end_date: getDateFromSpecifier(selectedToDate),
            settled: checkboxState.settled,
            on_loan: checkboxState.onLoan,
            total: checkboxState.total,
            approved: checkboxState.approved,
            pending: checkboxState.pending,
            executed: checkboxState.executed,
            confirmed: checkboxState.confirmed,
            unsettled: checkboxState.unsettled,
        };

        const filteredFormData = Object.fromEntries(
            Object.entries(formData).filter(([key, value]) => value !== "" && value !== " " && value !== undefined)
        );
        let endpoint = services.ASSETS;

        if (selectedTypeOfData === "DATA_TYPE_ORDERS") {
            endpoint = services.ORDERS;
          } 
          if (selectedTypeOfData === "DATA_TYPE_BENCHMARK_CHANGES") {
            endpoint = services.BENCHMARK;
          } 
          if (selectedTypeOfData === "DATA_TYPE_TARGETS_AND_TOLERANCES") {
            endpoint = services.TARGETS;
          } 
          if (selectedTypeOfData === "DATA_TYPE_TRADES") {
            endpoint = services.TRADES;
          } 
          if (selectedTypeOfData === "cashflows") {
            endpoint = services.CASHFLOW;
          }

        try {
            const response = await axios.get(endpoint, {
                params: filteredFormData,
            });

            let mappedData;

            const error_message = response.data.message;
            if (response.data.data === null) {
                setErrorMessage(error_message);
            } else {
                // Process the successful API response
                if (endpoint === services.TARGETS) {
                    mappedData = mapDatas(response.data.data, endpoint);
                } else {
                    mappedData = mapData(response.data.data, endpoint);
                }

                setGridData(mappedData);
                setErrorMessage(null);
                console.log("API Response:", response.data);
            }
        } catch (error: any) {
            console.error("Error making API request:", error);
            setErrorMessage(error.response.status);
        }
    };

    const handleSave = async (folderId: string | null = null) => {
        toggleSavePresetModal();
        const uuid = uuidv4();

        const formData: any = {
            name: presetName,
            folder_name: selectedFolderName || 'default',
            fund_group: fundGroup,
            fund_id: fundId,
            asset_id: assetId,
            asset_id_type: assetIdType,
            start_date: formatDate(selectedFromDate),
            end_date: formatDate(selectedToDate),
            settled: checkboxState.settled,
            on_loan: checkboxState.onLoan,
            total: checkboxState.total,
            approved: checkboxState.approved,
            pending: checkboxState.pending,
            executed: checkboxState.executed,
            confirmed: checkboxState.confirmed,
            unsettled: checkboxState.unsettled,
            data_type: selectedTypeOfData,

        };
        const presetapi = `${services.GET_PRESETS}/${uuid}`;

        try {
            const response = await axios.post(presetapi, JSON.stringify(formData), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const error_message = response.data.message;
            Promise.resolve(response.data.data)
                .then((data) => {
                    if (data === null) {
                        setErrorMessage(error_message);
                        return Promise.reject('No data');
                    }
                    setErrorMessage(null);
                    console.log("API Response:", response.data);

                    if (!folderId) {
                        console.log("uuid", formData.name)
                        const fileData = {
                            name: formData.name,
                            folder_name: formData.folder_name,
                            fund_group: formData.fund_group,
                            fund_id: formData.fund_id,
                            asset_id: formData.asset_id,
                            asset_id_type: formData.asset_id_type,
                            start_date: formData.start_date,
                            end_date: formData.end_date,
                            settled: checkboxState.settled,
                            on_loan: checkboxState.onLoan,
                            total: checkboxState.total,
                            approved: checkboxState.approved,
                            pending: checkboxState.pending,
                            executed: checkboxState.executed,
                            confirmed: checkboxState.confirmed,
                            unsettled: checkboxState.unsettled,
                            data_type: formData.data_type,
                            uuid: formData.uuid
                        };

                        const unassignedFilesString = localStorage.getItem('unassignedFiles');
                        const unassignedFiles = unassignedFilesString ? JSON.parse(unassignedFilesString) : [];
                        unassignedFiles.push(fileData);
                        localStorage.setItem('unassignedFiles', JSON.stringify(unassignedFiles));
                        localStorage.setItem('presetName', JSON.stringify(data));
                    }
                    window.location.reload();
                })
                .catch(error => {
                    if (error !== 'No data') {
                        console.error("Error making API request:", error);
                        setErrorMessage(error.response.status)
                    }
                });
        } catch (error: any) {
            console.error("Error making API request:", error);
            setErrorMessage(error.response.status)
        }
    };


    const mapData = (data: any[], endpoint: string) => {
        console.log("Mapping data for endpoint:", endpoint);
        if (!Array.isArray(data)) {
            console.error("Invalid data format. Expected an array.");
            return [];
        }
        return data.map((item: any, index: number) => {
            const mappedItem: any = {
                sr_no: index + 1,
            };
            if (endpoint === services.ASSETS) {
                const asset = item.asset;
                const quantities = item.asset_quantities;

                
                mappedItem.fund_id = asset.fund_id;
                mappedItem.calc_date = asset.calc_date;
                mappedItem.asset_id = asset.asset_key.asset_id;
                mappedItem.bloomberg_ticker = asset.asset_key.bloomberg_ticker;
                mappedItem.currency = asset.asset_attributes.currency;
                mappedItem.price_date = asset.asset_prices.price_date;
                mappedItem.price = asset.asset_prices.prices_for_price_type.open_price;
                mappedItem.average_daily_volume = asset.asset_prices.average_daily_volume;
                mappedItem.min_trade_size = asset.asset_prices.min_trade_size;
                mappedItem.min_trade_increment = asset.asset_prices.min_trade_increment;
                mappedItem.source = asset.asset_prices.source;
                mappedItem.asset_group = asset.asset_group;
                mappedItem.asset_name = asset.asset_attributes.asset_name;
                mappedItem.issue_country = asset.asset_attributes.issue_country;
                mappedItem.trading_country = asset.asset_attributes.trading_country;
                mappedItem.risk_country = asset.asset_attributes.risk_country;
                mappedItem.sector = asset.asset_attributes.sector;

                if (quantities && quantities.length > 0) {
                    const quantity = quantities[0]; 
                    if (quantity.asset_group === 'EQUITY') {
                        console.log("quantity", quantity)
                        mappedItem.total_quantity = quantity.equity.total_quantity;
                        mappedItem.traded_quantity = quantity.equity.traded_quantity;
                        mappedItem.settled_quantity = quantity.equity.settled_quantity;
                        mappedItem.substituted_in_quantity = quantity.equity.substituted_in_quantity;
                        mappedItem.substituted_out_quantity = quantity.equity.substituted_out_quantity;
                        mappedItem.adjusted_quantity = quantity.equity.adjusted_quantity;
                        mappedItem.indirectly_held_quantity = quantity.equity.indirectly_held_quantity;
                        mappedItem.on_loan_quantity = quantity.equity.on_loan_quantity;
                        mappedItem.approved_orders_quantity = quantity.equity.approved_orders_quantity;
                        mappedItem.proposed_orders_quantity = quantity.equity.proposed_orders_quantity;
                        mappedItem.target_date = quantity.target_date;
                    } else if (quantity.asset_group === 'DERIVATIVES') {
                        mappedItem.total_quantity = quantity.futures.total_quantity;
                        mappedItem.traded_quantity = quantity.futures.traded_quantity;
                        mappedItem.settled_quantity = quantity.futures.settled_quantity;
                        mappedItem.substituted_in_quantity = quantity.futures.substituted_in_quantity;
                        mappedItem.substituted_out_quantity = quantity.futures.substituted_out_quantity;
                        mappedItem.adjusted_quantity = quantity.futures.adjusted_quantity;
                        mappedItem.indirectly_held_quantity = quantity.futures.indirectly_held_quantity;
                        mappedItem.on_loan_quantity = quantity.futures.on_loan_quantity;
                        mappedItem.approved_orders_quantity = quantity.futures.approved_orders_quantity;
                        mappedItem.proposed_orders_quantity = quantity.futures.proposed_orders_quantity;
                        mappedItem.target_date = quantity.target_date;
                    }
                }

            } else if (endpoint === services.ORDERS) {
                const order = item.orders;
                mappedItem.fund_id = order.fund_id;
                mappedItem.asset_id = order.asset_key.asset_id;
                mappedItem.bloomberg_ticker = order.asset_key.bloomberg_ticker;
                mappedItem.asset_type = order.asset_type;
                mappedItem.market = order.market;
                mappedItem.asset_name = order.asset_name;
                mappedItem.transaction_type = order.transaction_type;
                mappedItem.quantity = order.quantity;
                mappedItem.currency = order.currency;
                mappedItem.amount = order.amount;
                mappedItem.order_motivation = order.order_motivation;
                mappedItem.trading_benchmark = order.trading_benchmark;
                mappedItem.benchmark_date = order.benchmark_date;
                mappedItem.trade_date = order.trade_date;
                mappedItem.settle_date_offset = order.settle_date_offset;
                mappedItem.broker_identifier = order.broker_identifier;
                mappedItem.execution_instruction = order.execution_instruction;
                mappedItem.limit_type = order.limit_type;
                mappedItem.limit_value = order.limit_value;
                mappedItem.stop_type = order.stop_type;
                mappedItem.stop_value = order.stop_value;
                mappedItem.filled_quantity = order.filled_quantity;
                mappedItem.filled_amount = order.filled_amount;
                mappedItem.settlement_currency = order.settlement_currency;
                mappedItem.price_currency = order.price_currency;
                mappedItem.basket_name = order.basket_name;
                mappedItem.comments = order.comments;
                mappedItem.order_status = order.order_status;
                mappedItem.create_time = order.create_time;
                mappedItem.created_by = order.created_by;
                mappedItem.last_updated_time = order.last_updated_time;
                mappedItem.last_updated_by = order.last_updated_by;
                mappedItem.transaction_id = order.transaction_id;
                mappedItem.order_type = order.order_type;
            } else if (endpoint === services.BENCHMARK) {
                const benchmarkChange = item.benchmark_change;
                mappedItem.fund_id = item.fund_id;
                mappedItem.benchmark_id = item.benchmark_id;
                mappedItem.fund_currency = item.fund_currency;
                mappedItem.fund_group = item.fund_group;
                mappedItem.fund_name = item.fund_name;
                mappedItem.primary_fund_manager = item.primary_fund_manager;
                mappedItem.benchmark_identifier = benchmarkChange.benchmark_identifier;
                mappedItem.change_type = benchmarkChange.change_type;
                mappedItem.share_change = benchmarkChange.share_change;
                mappedItem.price = benchmarkChange.price;
                mappedItem.currency = benchmarkChange.currency;
                mappedItem.market_cap_change = benchmarkChange.market_cap_change;
                mappedItem.pct_change = benchmarkChange.pct_change;
                mappedItem.description = benchmarkChange.description;
                mappedItem.change_id = benchmarkChange.change_id;
                mappedItem.source_id = benchmarkChange.source_id;
                mappedItem.vendor_id = benchmarkChange.vendor_id;
                mappedItem.effective_date = benchmarkChange.effective_date;
                mappedItem.asset_id = benchmarkChange.asset_key.asset_id;
                mappedItem.bloomberg_ticker = benchmarkChange.asset_key.bloomberg_ticker;
            }

            return mappedItem;
        });
    };
    const mapDatas = (data: any[], endpoint: string) => {
        const mappedData: any[] = [];

        data.forEach((item: any) => {
            const { fund_id, fund_group, targets } = item;

            if (targets && Array.isArray(targets)) {
                targets.forEach((target: any, index: number) => {
                    console.log("Checking target:", target);

                    const mappedItem: any = {
                        sr_no: mappedData.length + 1,
                        fund_id,
                        type: target.type || null,
                        target_category: target.target_category || null,
                        lower_threshold: target.lower_threshold || null,
                        upper_threshold: target.upper_threshold || null,
                        target_value: target.hasOwnProperty("target_value") ? target.target_value : null,
                    };

                    console.log("Mapped Item:", mappedItem);

                    mappedData.push(mappedItem);
                });
            }
        });

        return mappedData;
    };

    const exportToCSV = () => {
        if (gridData.length === 0) {
            console.warn("No data to export.");
            return;
        }

        const headers = Object.keys(gridData[0]);

        // Adjust headers: capitalize first letter and replace "_" with " "
        const adjustedHeaders = headers.map((header) =>
            header.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase()),
        );

        const csvContent =
            "data:text/csv;charset=utf-8," +
            adjustedHeaders.join(",") +
            "\n" +
            gridData.map((row) => headers.map((header) => row[header]).join(",")).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "exported_data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const sedol = t("SEDOL");
    const cusip = t("CUSIP");
    const isin = t("ISIN");
    const bloomberg_ticker = t("BLOOMBERG_TICKER");
    const acumainassetid = t("ACUMAIN_ASSET_ID");
    const fundgroup = t("FUND_GROUP");
    const date = t("DATE");
    const from = t("FROM");
    const to = t("TO");
    const fundid = t("FUND_ID");
    const assetidtype = t("ASSET_ID_TYPE");
    const assetid = t("ASSET_ID");
    const typeofdata = t("TYPE_OF_DATA");
    const saveaspreset = t("SAVE_AS_PRESET");
    const getdata = t("GET_DATA");
    const exportlabel = t("EXPORT");

    const handleRefresh = async () => {
        setGridData([]);
        setErrorMessage(null);
    };

    const handleKeyPress = (event: { key: string; }) => {
        if (event.key === 'Enter') {
            handleSave();
        }
    };

    return (
        <div className="all-fund-query-container">
            <PageTitle TITLE={"FUND_QUERY"} />
            <Flex ml={"md"} mt={'sm'} pos={'relative'} className={("preset-wrapper")} >
                <Preset isPresetOpen={isPresetOpen} setIsPresetOpen={setIsPresetOpen} onDocumentClick={handleDocumentClick} handleReresh={handleRefresh} />
            </Flex>
            <div className={"flex flex-column h-screen overflow-hidden"}>
                <div className={"flex grow"}>
                    <div className="overflow-x-auto">
                        <div className={`main-content ${isPresetOpen ? 'shift-right' : ''}`}>
                            <div className='labels'>
                                <span className='labelfont'>{fundgroup}</span>
                                <TextInput
                                    value={isPresetDataLoaded ? presetFundGroup : fundGroup}
                                    onChange={(event) => setFundGroup(event.currentTarget.value)}
                                    w={328}
                                    size={"xs"}
                                    radius={"sm"}
                                    variant="filled"
                                    mr={theme.spacing.md}
                                    disabled={isFundGroupDisabled}
                                    onFocus={() => setIsFundIdDisabled(true)}
                                    onBlur={() => setIsFundIdDisabled(false)}
                                    onClick={handleRefreshStateFundGroup}
                                />

                            </div>

                            <div className='labels'>
                                <span className='labelfontId'>{fundid}</span>
                                <TextInput
                                    value={isPresetDataLoaded ? presetFunds : fundId}
                                    onChange={(event) => setFundId(event.currentTarget.value)}
                                    w={328}
                                    size={"xs"}
                                    radius={"sm"}
                                    variant="filled"
                                    mr={theme.spacing.md}
                                    disabled={isFundIdDisabled}
                                    onFocus={() => setIsFundGroupDisabled(true)}
                                    onBlur={() => setIsFundGroupDisabled(false)}
                                    onClick={handleRefreshStateFundId}
                                />
                            </div>
                            <div className='labels-space'>
                                <span className='assettype'>{assetidtype}</span>
                                <div>
                                    <Select
                                        data={[
                                            { value: 'sedol', label: sedol },
                                            { value: 'cusip', label: cusip },
                                            { value: 'isin', label: isin },
                                            { value: 'bloomberg_ticker', label: bloomberg_ticker },
                                            { value: 'acumain_asset_id', label: acumainassetid }
                                        ]}
                                        value={assetIdType}
                                        onChange={(value) => setAssetIdType(value || bloomberg_ticker)}
                                        className="heightcheck"
                                    />
                                </div>
                            </div>
                            <div className='labels'>
                                <span className='labelassetId'>{assetid}</span>
                                <TextInput
                                    value={isPresetDataLoaded ? presetAssetID : assetId}
                                    onChange={(event) => setAssetId(event.currentTarget.value)}
                                    w={328}
                                    size={"xs"}
                                    fs={"5px"}
                                    radius={"sm"}
                                    variant="filled"
                                    mr={theme.spacing.md}
                                    onClick={handleRefreshStateAssetId}
                                />
                            </div>
                            <div className='labels-space'>
                                <span className='labeltype'>{typeofdata}</span>
                                <Select
                                    data={[
                                        { value: "holdings", label: t("HOLDINGS") },
                                        { value: "orders", label: t("ORDERS") },
                                        { value: "trades", label: t("TRADES") },
                                        { value: "index_change", label: t("INDEX_CHANGE") },
                                        { value: "targets_tols", label: t("TARGETS_TOLS") },
                                        { value: "cashflows", label: t("CASHFLOWS") },
                                      ]}
                                    value={selectedTypeOfData}
                                    onChange={(value) => setSelectedTypeOfData(value || "Holdings")}
                                    ta={"center"}
                                    variant="transparent"
                                />
                            </div>

                            <Flex mt="xxs" ml="6.5rem">
                                {checkboxes.map(({ key, label }) => (
                                    <Flex key={key} align="center">
                                        <Checkbox
                                            color="gray"
                                            variant="outline"
                                            checked={checkboxState[key]}
                                            onChange={() => handleCheckboxChange(key)}
                                            ml={'1rem'}
                                        />
                                        <Text ml="xs">{t(label)}</Text>
                                    </Flex>
                                ))}
                            </Flex>

                            <div className='calender-start'>
                                <Text className='date'>{date}</Text>
                                <span className='datelabel'>{from}</span>
                                <CalendarComponent
                                    onSelect={handleFromDateChange}
                                    defaultDateValue={presetStartDate || selectedFromDate}
                                    placeholder='Select Date'
                                    style={{ top: "31px" }}
                                    decrementalCalender={true}
                                />

                                <span className='datelabel'>{to}</span>
                                <CalendarComponent
                                    onSelect={handleToDateChange}
                                    defaultDateValue={presetEndDate || selectedToDate}
                                    placeholder='Select Date'
                                    style={{ top: "31px" }}
                                    decrementalCalender={true}
                                />
                            </div>
                            <div className='buttonsection'>
                                <Button
                                    w={147}
                                    h={30}
                                    bg={"white"}
                                    c={"black"}
                                    className="button-style"
                                    onClick={toggleSavePresetModal} // Open the modal on click
                                >
                                    {saveaspreset}
                                </Button>

                                <SavePresetModal
                                    isOpen={isSavePresetModalOpen}
                                    onClose={toggleSavePresetModal}
                                    presetName={presetName}
                                    setPresetName={setPresetName}
                                    handleSave={handleSave}
                                    handleKeyPress={handleKeyPress}
                                    selectedFolderName={selectedFolderName}
                                    setSelectedFolderName={setSelectedFolderName}
                                />

                                <Button
                                    bg={theme.colors.violet[8]}
                                    w={147}
                                    h={30}
                                    className='buttonspace'
                                    onClick={() => {
                                        handleSubmit();
                                        handleRefresh();
                                    }}
                                >
                                    {getdata}
                                </Button>

                            </div>
                        </div>
                    </div>
                </div>

                <SampleSplitter
                    dir={"horizontal"}
                    isDragging={isTerminalDragging}
                    {...terminalDragBarProps}
                />
                <div className="get-data-content">
                    <div
                        className={cn(
                            "shrink-0 bg-darker contents",
                            isTerminalDragging && "dragging"
                        )}
                        style={{ height: terminalH }}
                    >
                        {gridData.length > 0 && (
                            <Flex justify='right' mr='2rem' p='lg'>
                                <Button
                                    bg={theme.colors.violet[8]}
                                    w={147}
                                    h={30}
                                    className='buttonspace'
                                    onClick={() => {
                                        exportToCSV();
                                    }}
                                >
                                    {exportlabel}
                                </Button>
                            </Flex>
                        )}

                        <div className="table-alignment">
                            {gridData.length > 0 && <AllFundQueryTable data={gridData} />}
                        </div>
                    </div>

                    <div style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                        {errorMessage && <ErrorMessage errorCode={errorMessage} errorMessage={errorMessage} />}
                    </div>
                </div>

            </div>
        </div >
    );
}