import React, { useMemo, useState } from "react";
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridOptions } from "ag-grid-community";
import { useMantineTheme } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import CustomNoRowsOverlay from '../Common/customNoRowsOverlay';
import "../Agtablehomepage/Agstyles.css";
import { ActionItemsTableProps } from "../../types";

const ActionItemsTable: React.FC<ActionItemsTableProps> = ({ setSelectedRows, rowData, updateSelectedRowCount, onSelectionChange, onCommentsChange }) => {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const commonCellStyle = {
        fontWeight: "400",
        fontSize: theme.fontSizes.md,
    };

    const [columnDefs, setColumnDefs] = useState<ColDef[]>(() => {
        const hasDueDate = rowData.some(item => item.due_date);
        const dynamicColumnDefs: ColDef[] = [
            { headerName: t('DUE_TIME'), field: 'due_time', width: 100 },
            { headerName: t('WORK_TYPE'), field: 'work_type', width: 224 },
            { headerName: t('STATUS'), field: 'status', width: 145 },
            { headerName: t('COPILOT_STATUS'), field: 'copilot_status', width: 145 },
            { headerName: t('FUND_ID'), field: 'fund_id', width: 106 },
            { headerName: t('FUND_NAME'), field: 'fund_name', width: 240 },
            { headerName: t('DESCRIPTION'), field: 'description', width: 450 },
            { headerName: t('PRIMARY_FM_NAME'), field: 'primary_fm_name', width: 130 },
            { headerName: t('COMMENT'), field: 'comment', width: 450 },
            { headerName: t('CREATED_TIME'), field: 'creation_time', width: 150 },
            { headerName: t('COMPLETED_TIME'), field: 'completed_time', width: 180 },
            { headerName: t('COMPLETED_BY'), field: 'completed_by', width: 160 }
        ];

        if (hasDueDate) {
            dynamicColumnDefs.unshift({ headerName: t('DUE_DATE'), field: 'due_date', width: 90 });
        }

        return dynamicColumnDefs;
    });

    const defaultColdef: ColDef = {
        headerClass: "custom-header-dashboard",
        cellStyle: commonCellStyle
    };

    const gridOptions: GridOptions = {
        rowData: rowData,
        suppressRowHoverHighlight: true,
        onSelectionChanged: () => {
            const selectedNodes = gridApi.getSelectedNodes();
            const selectedData = selectedNodes.map((node: { data: any; }) => node.data);
            setSelectedRows(selectedData);
            const ids = selectedNodes.map((node: { data: any; }) => node.data.id);
            const comment = selectedNodes.map((node: { data: any; }) => node.data.comments);
            onCommentsChange(comment);
            onSelectionChange(ids);
            updateSelectedRowCount(selectedData.length);
        },
    };

    let gridApi: { getSelectedNodes: () => any; };

    const onGridReady = (params: { api: { getSelectedNodes: () => any; }; }) => {
        gridApi = params.api;
    };

    const handleRowDoubleClick = (event: any) => {
        const { work_type, fund_group } = event.data;
        console.log("work_type", work_type)

        if (work_type === t('ALERT_CATEGORY_SOD_FUND_RECON_AGG_VIEW')) {
            navigate(`/sod-recon/${fund_group}`);
        }
    };

    const customheight = () => {
        if (rowData.length > 1) {
            const heightInRem = 1.876 + (rowData.length * 1.9);
            return `${heightInRem}rem`;
        } else {
            return "4rem";
        }
    };

    const rowSelectionType = 'multiple';
    const rowHeight = 30;
    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => `- ${t("NO_FUNDS_HERE")} -`,
        };
    }, []);

    return (
        <div
            className='action-item ag-theme-quartz'
            style={{
                height: customheight(),
                width: "100%",
                maxHeight: "23.125rem",
                minHeight: "4rem",
            }}
        >
            <AgGridReact
                columnDefs={columnDefs}
                rowData={rowData}
                gridOptions={gridOptions}
                onGridReady={onGridReady}
                rowSelection={rowSelectionType}
                rowHeight={rowHeight}
                defaultColDef={defaultColdef}
                noRowsOverlayComponent={CustomNoRowsOverlay}
                noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                onRowDoubleClicked={handleRowDoubleClick}
            />
        </div>
    );
};

export default ActionItemsTable;