import { Button, Flex, Select, TextInput } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { services } from "../../../../services";
import { FundPayload } from "../../../../types";

type createEditFundProps = {
    isEditFund: boolean;
    editableData?: FundPayload;
};

export const CreateEditFund: React.FC<createEditFundProps> = ({ isEditFund, editableData }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const initialFormData: FundPayload = {
        fund_id: "",
        fund_name: "",
        fund_currency: "",
        fund_domicile: "",
        legal_jurisdiction: "",
        forty_act_fund: false,
        primary_fund_manager: "",
        fund_managers: [],
        benchmark_id: "",
        fund_structure: "MASTER",
        fund_group: "",
        valuation_type: "",
        optimal_futures_basket_id: "",
        qualified_institutional_investor: false,
        qualified_sophisticated_investor: false,
        eligible_under_rule_144a: false,
        parent_fund_id: "",
        market_on_open_activity_allowed: false,
    };
    const [createFormData, setCreateFormData] = useState<FundPayload>(
        isEditFund ? (editableData as FundPayload) : initialFormData,
    );

    const inputList = [
        { label: "Fund ID", apiKey: "fund_id" },
        { label: "Fund Name", apiKey: "fund_name" },
        { label: "Fund Currency", apiKey: "fund_currency" },
        { label: "Fund Domicile", apiKey: "fund_domicile" },
        { label: "Legal Jurisdiction", apiKey: "legal_jurisdiction" },
        { label: "40-act", apiKey: "forty_act_fund" },
        { label: "Primary Fund Manager", apiKey: "primary_fund_manager" },
        { label: "Secondary Fund Manager (optional)", apiKey: "fund_managers" },
    ];

    const handleInputChange = (key: string, value: any) => {
        if (key === "forty_act_fund") {
            setCreateFormData((prev) => ({
                ...prev,
                [key]: value,
            }));
        }
        if (key === "fund_managers") {
            setCreateFormData((prev) => ({
                ...prev,
                [key]: Array.isArray(value) ? value : [value],
            }));
        }
        else {
            setCreateFormData((prev) => ({
                ...prev,
                [key]: value,
            }));
        }
    };

    const submitCreateFund = async () => {
        const payload = {
            ...createFormData,
            fund_structure: "MASTER",
            valuation_type: "MARKET_ON_OPEN"
        };
        const response = await axios.post(services.FUND_ATTRIBUTES, [payload]);
        if (response.status === 200) {
            setCreateFormData(initialFormData);
            navigate(-1);
        }
    };

    const cancelCreateFund = () => {
        setCreateFormData(initialFormData);
        navigate(-1);
    };
    return (
        <>
            <Flex m={"35px auto 0px"} w={"495px"} direction={"column"} className='create-fund'>
                {inputList.map((ele, index) => {
                    if (ele.apiKey === "forty_act_fund") {
                        return (
                            <div className="select-input" key={index}>
                                <div className="select-label">40-act</div>
                                <Select
                                    data={[
                                        { value: 'Y', label: t("Y") }, 
                                        { value: 'N', label: t("N") }, 
                                    ]}
                                    onChange={(value) => handleInputChange(ele.apiKey, value === 'Y')} 
                                />
                            </div>

                        );
                    } else if (ele.apiKey === "fund_managers") {
                        return (
                            <div className='text-input' key={index}>
                                <TextInput
                                    label="Secondary Fund Manager (optional)"
                                    value={createFormData[ele.apiKey as keyof FundPayload] as string || ""}
                                    onChange={(e) => handleInputChange(ele.apiKey, e.currentTarget.value)}

                                    mb={"16px"}
                                    w="100%"
                                />
                            </div>
                        );
                    } else {
                        return (
                            <TextInput
                                key={index}
                                label={ele.label}
                                value={createFormData[ele.apiKey as keyof FundPayload] as string || ""}
                                mb={"16px"}
                                w={index < 2 ? "100%" : index > 5 ? "100%" : "50%"}
                                onChange={(e) => handleInputChange(ele.apiKey, e.currentTarget.value)}
                            />
                        );
                    }
                })}

                <Flex m={"18px 0 280px 226px"} w={"270px"}>
                    <Button
                        className='cancel-btn'
                        onClick={() => {
                            cancelCreateFund();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button className='create-btn' onClick={() => submitCreateFund()}>
                        {isEditFund ? "Update" : "Create"}
                    </Button>
                </Flex>
            </Flex>

        </>
    );
};
