import { useEffect } from "react";
import axios from "axios";
import { services } from "../services";

const isValidISODate = (dateString: string): boolean => !isNaN(Date.parse(dateString));

export const fetchSystemDate = async (): Promise<void> => {
  try {
    const fundGroupData = localStorage.getItem('fundGroup');
    if (!fundGroupData) throw new Error('No fundGroup data found in localStorage');
    
    const fundGroupArray = JSON.parse(fundGroupData);
    const fundGroup = fundGroupArray?.[0]?.fundGroup;
    if (!fundGroup) throw new Error('Invalid fundGroup data structure');

    const response = await axios.get(`${services.SYSTEM_DATE}?fund_group=${encodeURIComponent(fundGroup)}`);
    const { api_status, data } = response.data;

    if (api_status !== 'success' || !data?.length) throw new Error('Invalid API response structure');

    const systemDate = data[0].todays_date;
    if (isValidISODate(systemDate)) {
      localStorage.setItem('systemDate', systemDate);
    } else {
      throw new Error(`Invalid system date format: ${systemDate}`);
    }
  } catch (error) {
    const err = error as Error;
    if (process.env.NODE_ENV !== 'production') {
      console.error('Error fetching system date:', err.message);
    }
  }
};

export const useSystemDatePolling = (intervalMs = 300000, skipInitialFetch = false) => {
  useEffect(() => {
    if (!skipInitialFetch) {
      fetchSystemDate();
    }

    try {
      const interval = setInterval(() => fetchSystemDate(), intervalMs);
      return () => clearInterval(interval);
    } catch (error) {
        const err = error as Error;
        if (process.env.NODE_ENV !== 'production') {
          console.error('Error polling:', err.message);
        }
      }
      
  }, [intervalMs, skipInitialFetch]);
};
