import React from "react";
import { Flex, Text, useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface HeaderProps {
	title: string;
	count: number;
	label?: string;
}

export const Header = React.memo(({ title, count, label = "funds" }: HeaderProps) => {
	const theme = useMantineTheme();
	const { t } = useTranslation();

	return (
		<Flex
			align='baseline'
			justify='center'
			pb='0.8rem'
			role='region'
			aria-labelledby='header-title'
			style={{
				fontFamily: theme.fontFamily || "inherit",
			}}
		>
			<Text
				id='header-title'
				ta='center'
				size='sm'
				fw={600}
				c={theme.colors.gray[7]}
				mr='0.6rem'
				style={{
					lineHeight: "1.2",
					whiteSpace: "nowrap",
				}}
			>
				{t(title)}:
			</Text>

			<Text
				ta='center'
				size='md'
				fw={600}
				c={theme.colors.gray[8]}
				mr='0.2rem'
				style={{
					lineHeight: "1.2",
				}}
			>
				{count}
			</Text>

			<Text
				ta='center'
				size='sm'
				fw={600}
				c={theme.colors.gray[7]}
				style={{
					lineHeight: "1.2",
				}}
			>
				{t(label)}
			</Text>
		</Flex>
	);
});

export default Header;
