import React from "react";
import { Flex, Box, Text } from "@mantine/core";
import { StackedBar } from "./StackedBar";
import styles from "./SectionRenderer.module.css";

interface SectionRendererProps {
	sections: { label: string; values: number[]; labels: string[] }[];
}

export const SectionRenderer = ({ sections }: SectionRendererProps) => (
	<>
		{sections.map((section, index) => (
			<Flex key={index} h='2.5rem' align='center' ml='0.5rem'>
				<Box className={styles.barLabel}>
					{section.label === "Not Ready" ? (
						<div className={styles.notReadyContainer}>
							<Text className={styles.notReadyText}>Not</Text>
							<Text className={styles.notReadyText}>Ready</Text>
						</div>
					) : (
						<Text>{section.label}</Text>
					)}
				</Box>
				<StackedBar values={section.values} labels={section.labels} />
			</Flex>
		))}
	</>
);
