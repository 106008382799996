import React from 'react';
import { Modal, Text, TextInput, Grid, Flex, useMantineTheme, Box } from '@mantine/core';
import { t } from 'i18next';
import { FundGroupModalUserProps } from '../../../types';
import CustomButton from '../../Common/Buttons/CustomButton';
import './FundGroupModal.css'

const FundGroupModalUser: React.FC<FundGroupModalUserProps> = ({ opened, onClose, TITLE, buttonName }) => {
    const theme = useMantineTheme();
    return (
        <Modal
            opened={opened}
            onClose={onClose}
            className='modal-fund-group'
            centered
            withCloseButton={false}
            radius={'1.875rem'}
            size={"34.375rem"}
        >
            <Box pl={'2.5rem'} >
                <Text ta={'center'} size='lg' fw={600} mt={"md"} pr={'4.0625rem'}>{t(TITLE)}</Text>
                <Box mt={'1.6rem'}>
                    <TextInput label={t("GROUP_ID")} ml='0.6rem' className='fund-group-textInput' />
                    <Box mt={'1.1rem'}>
                        <TextInput ml='0.6rem' label={t("GROUP_NAME")} className='fund-group-textInput' />
                    </Box>
                </Box>
                <Flex justify="flex-end" mt="2.37rem" mb={"3.5rem"} pr={'2.9rem'}>
                    <CustomButton variant={'cancel'} onClick={onClose} children={"CANCEL"} />
                    <CustomButton variant={'submit'} children={buttonName} />
                </Flex>
            </Box>
        </Modal>
    );
};

export default FundGroupModalUser;
