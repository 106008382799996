import React, { useContext, useEffect, useRef, useState } from "react";
// import "../AlgoJournal/AnalysisOrderTab.css";
import axios from "axios";
import { Flex, useMantineTheme, Text } from "@mantine/core";
import { services } from "../../services";
import PageTitle from "../../components/Common/PageTitle";
import { AgTableComponent } from "../../components/Agtablehomepage/AgTableComponent";
import { RowInfo } from "../../types";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../components/Common/ErrorMessage";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useInterval } from "../../utils/hooks/useInterval";
import { fetchFundManagerCounters } from "../../store/marketSlice";
import { getCurrentUTCDate } from "../../utils/dateUtils";
import { calculatePercentage, directPercentage } from "../../utils/conversions";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { currentDate } from "../../constants";

const AllFundStatus = () => {
    const theme = useMantineTheme();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { t, } = useTranslation();
    const [rowDataSet, setRowDataSet] = useState<RowInfo[]>([]);
    const { user } = useAppSelector(state => state.user);
    const [selectedMarketState, setSelectedMarketState] = useState('MARKET_ON_OPEN');
    const dispatch = useAppDispatch();
    const asOfDate = useSelector((state: RootState) => state.market.as_of_date);


    const handleMarketStateChange = (marketState: string) => {
        setSelectedMarketState(marketState);
    };

    useEffect(() => {
        console.log("Check the market state: ", selectedMarketState);
    }, [selectedMarketState]);


    const fetchDetails = async () => {
        try {
            const response = await axios.get(services.FUND_STATE, {
                params: {
                    market_state: selectedMarketState,
                    target_date: getCurrentUTCDate(),
                    all_funds_flag: true,
                    calc_date: getCurrentUTCDate(),
                },
            });
            setErrorMessage(null)
            console.log("Market Value", selectedMarketState)
            const apiDataResponse = response.data;

            const getViolatedTolerance = (violations: any[], type: string) => {
                const target = violations.find(
                    (violation: any) => violation.fund_target.type === type
                );
                return target ? target.violated_tolerance : null;
            };

            const getLowerThreshold = (violations: any[], type: string) => {
                const target = violations.find(
                    (violation: any) => violation.fund_target.type === type
                );
                return target ? target.fund_target.lower_threshold : null;
            };

            const getUpperThreshold = (violations: any[], type: string) => {
                const target = violations.find(
                    (violation: any) => violation.fund_target.type === type
                );
                return target ? target.fund_target.upper_threshold : null;
            };

            const rowDataSet: RowInfo[] = apiDataResponse.data.map((item: any, index: number) => {
                return {
                    sr_no: index + 1,
                    fund_id: item.fund_id,
                    fund_name: item.fund_attributes.fund_name,
                    fund_summary_type: item.fund_summary_type,
                    tol_start: item.start_of_the_day_status === "TOLERANCE_OUT" ? t("OUT") : t("IN"),
                    tol_current: (() => {
                        const readyStateFlags =
                            item.fund_state?.fund_ready_state_flags || item.fund_state?.fund_completed_state_flags;

                        if (readyStateFlags && Object.values(readyStateFlags).some((flag) => flag)) {
                            const readyState = Object.keys(readyStateFlags).find((key) => readyStateFlags[key]);

                            if (readyState) {
                                return readyState.split("_")[0].toUpperCase();
                            }
                        }
                        return null;
                    })(),
                    order_status: t(item.fund_state?.fund_state),
                    spend_cash_bef: calculatePercentage(item.fund_stats.spendable_cash, item.fund_stats.total_nav),
                    spend_cash_aft: calculatePercentage(item.new_fund_stats.spendable_cash, item.fund_stats.total_nav),
                    unequitized_cash_bef: calculatePercentage(item.fund_stats.unequitized_cash, item.fund_stats.total_nav),
                    unequitized_cash_aft: calculatePercentage(item.new_fund_stats.unequitized_cash, item.fund_stats.total_nav),
                    assets: item.fund_stats.asset_max_active_weight,
                    industry: item.fund_stats.sector_max_active_weight,
                    country: item.fund_stats.country_max_active_weight,
                    currency: item.fund_stats.currency_max_active_weight,
                    size: item.fund_stats.size_max_active_weight,
                    shortpos: item.fund_stats.num_short_positions,
                    nohold: item.fund_stats.num_no_holds,
                    nonbench: item.fund_stats.num_non_benchmark_assets,
                    activerisk: item.fund_stats.active_risk,
                    equityactiverisk: item.fund_stats.active_risk,
                    as_of_date: item.as_of_date,
                    transaction_id: item.transaction_id,
                    target_date_data_date: item.target_date_data_date,
                    is_fund_locked: item.is_fund_locked,
                    fund_group: item.fund_attributes.fund_group,
                    fund_state: item.fund_state.fund_state,
                    total_nav: item.fund_stats.total_nav,
                    spend_cash_bef_violated_tolerances: directPercentage(getViolatedTolerance(
                        item.fund_stats_with_target_violation,
                        "SPENDABLE_CASH"
                    )),
                    spend_cash_bef_violated_tolerances_lower: directPercentage(getLowerThreshold(item.fund_stats_with_target_violation, "SPENDABLE_CASH")),
                    spend_cash_bef_violated_tolerances_upper: directPercentage(getUpperThreshold(item.fund_stats_with_target_violation, "SPENDABLE_CASH")),



                    spend_cash_aft_violated_tolerances: directPercentage(getViolatedTolerance(
                        item.new_fund_stats_with_target_violation,
                        "SPENDABLE_CASH"
                    )),
                    spend_cash_aft_violated_tolerances_lower: directPercentage(getLowerThreshold(item.new_fund_stats_with_target_violation, "SPENDABLE_CASH")),
                    spend_cash_aft_violated_tolerances_upper: directPercentage(getUpperThreshold(item.new_fund_stats_with_target_violation, "SPENDABLE_CASH")),


                    unequitized_cash_bef_violated_tolerances: directPercentage(getViolatedTolerance(
                        item.fund_stats_with_target_violation,
                        "UNEQUITIZED_CASH"
                    )),
                    unequitized_cash_bef_violated_tolerances_lower: directPercentage(getLowerThreshold(item.fund_stats_with_target_violation, "UNEQUITIZED_CASH")),
                    unequitized_cash_bef_violated_tolerances_upper: directPercentage(getUpperThreshold(item.fund_stats_with_target_violation, "UNEQUITIZED_CASH")),



                    unequitized_cash_aft_violated_tolerances: directPercentage(getViolatedTolerance(
                        item.new_fund_stats_with_target_violation,
                        "UNEQUITIZED_CASH"
                    )),
                    unequitized_cash_aft_violated_tolerances_lower: directPercentage(getLowerThreshold(item.new_fund_stats_with_target_violation, "UNEQUITIZED_CASH")),
                    unequitized_cash_aft_violated_tolerances_upper: directPercentage(getUpperThreshold(item.new_fund_stats_with_target_violation, "UNEQUITIZED_CASH")),

                };
            });
            const filteredRowDataSet = rowDataSet.filter(Boolean);
            setRowDataSet(filteredRowDataSet);
        }

        catch (error: any) {
            console.error("Error making API request:", error);
            setErrorMessage(error.response.status)
        }
    };

    useEffect(() => {
        fetchDetails();
        dispatch(fetchFundManagerCounters(user ? user.username : ''));
    }, [selectedMarketState]);

    useInterval({
        callback: fetchDetails,
        type: "func"
    })

    const allfundlabel = t("ALL_FUND_STATUS")

    return (
        <div>
            <PageTitle TITLE={allfundlabel} onMarketStateChange={handleMarketStateChange} />
            <div className="main-content-all-fund-status">
                {errorMessage ? (
                    <ErrorMessage errorMessage={errorMessage} errorCode={errorMessage} />
                ) : (
                    <AgTableComponent rowData={rowDataSet} target_modes={selectedMarketState} />
                )
                }
            </div>
        </div>
    );
};

export default AllFundStatus;
