import { Box } from "@mantine/core";
import "./CreateFundPage.css";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { FundPayload } from "../../../../types";
import PageTitle from "../../../../components/Common/PageTitle";
import { services } from "../../../../services";
import { CreateEditFund } from "./CreateEditFund";
import './CreateFundPage.css'

export const CreateFundPage = () => {
	const [searchParams] = useSearchParams();
	const fundId = searchParams.get("fundId");
	const isEditFund = !!fundId;
	const [editableFundData, setEditableFundData] = useState<FundPayload | null>(null);
	const currentPageTitle = isEditFund ? "Edit a fund" : "Create a new fund";

	const getEditableData = async (fundId: string) => {
		try {
			const response = await axios.get(services.FUND_ATTRIBUTES);
			const filteredData = response.data.data.filter(
				(fund: FundPayload) => fund.fund_id === fundId,
			);
			if (filteredData.length === 0) {
				throw new Error(`Fund with ID ${fundId} not found`);
			}
			const editableData = filteredData[0];
			return editableData;
		} catch (error) {
			console.error("Error fetching data:", error);
			throw error;
		}
	};


	useEffect(() => {
		if (isEditFund) {
			const fetchData = async () => {
				try {
					const editableData = await getEditableData(fundId as string);
					setEditableFundData(editableData);
				} catch (error) {
					console.error("Failed to fetch editable data:", error);
				}
			};
			fetchData();
		}
	}, [fundId]);

	return (
		<Box>
			{isEditFund && <PageTitle TITLE={currentPageTitle} back/>}
			{isEditFund && editableFundData && (
				<CreateEditFund isEditFund={isEditFund} editableData={editableFundData} />
			)}
			{!isEditFund && (
				<>
					<PageTitle TITLE={currentPageTitle} back import_asset="IMPORT"/>
					<CreateEditFund isEditFund={isEditFund} />
				</>
			)}
		</Box>
	);
};
