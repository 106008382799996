import React, { useState, useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNavigate, useLocation } from "react-router-dom";
import { services } from "../../../../services";
import { Box, Text } from "@mantine/core";
import AdminTableHeader from "../../../../components/AdminPage/AdminTableHeader";
import { routes } from "../../../../routes";
import { applyDecorators, getTableData } from "../../../../utils/conversions";
import { t } from "i18next";
import CustomNoRowsOverlay from "../../../../components/Common/customNoRowsOverlay";
import DeleteModalUser from "../../../../components/AdminPage/DeleteModalUser";
import { ColumnDefType } from "../../../../types";
import { dateToString } from "../../../../utils/dateUtils";
import NoDataPage from "../../../../components/Common/NoDataPage";

const API_URL = `${services.ASSET_INVESTMENT}?asset_group=`;

const AssetTable: React.FC<{
    group: string;
    data: Record<string, any[]>;
    customHeight: (group: string) => string;
    generateColumnDefs: (group: string) => ColumnDefType[];
    onSelectionChanged: (event: any) => void;
}> = ({ group, data, customHeight, generateColumnDefs, onSelectionChanged }) => {
    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => `- ${t("NO_ASSETS_HERE")} -`,
        };
    }, []);

    return (
        <Box p="lg">
            <Text size="lg" fw={600}>
                {group}
            </Text>
            <div
                className="fund-query ag-theme-quartz"
                style={{
                    height: customHeight(group),
                    minHeight: "4rem",
                    maxHeight: "11.5rem",
                    margin: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <AgGridReact
                    columnDefs={generateColumnDefs(group)}
                    rowData={data[group]}
                    rowHeight={30}
                    rowSelection="single" // Allow single row selection
                    onSelectionChanged={onSelectionChanged} // Handle selection changes
                    noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                    noRowsOverlayComponent={CustomNoRowsOverlay}
                    suppressRowHoverHighlight={true}
                />
            </div>
        </Box>
    );
};

const AssetTables: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [opened, setOpened] = useState(false);
    const [payloadEditKey, setPayloadEditKey] = useState<string | undefined>(undefined);
    const [payloadEditValue, setPayloadEditValue] = useState<string | undefined>(undefined);

    const handleSelectionChanged = (event: any) => {
        const selectedNodes = event.api.getSelectedNodes();
        
        if (selectedNodes.length && selectedNodes[0].data) { // Check if data exists
            setSelectedRow(selectedNodes[0].data);
            console.log("Selected Node:", selectedNodes[0].data);
        } else {
            setSelectedRow(null);
            console.log("No valid selection");
        }
    };
    

    const [data, setData] = useState<Record<string, any[]>>({
        EQUITY: [],
        FUTURES: [],
        CURRENCY: [],
        CASH: [],
    });
    const [loading, setLoading] = useState<Record<string, boolean>>({
        EQUITY: true,
        FUTURES: true,
        CURRENCY: true,
        CASH: true,
    });

    const fetchData = async (group: string) => {
        try {
            const response = await axios.get(`${API_URL}${group}`);
            const assets = response.data?.data || [];
            setData((prev) => ({ ...prev, [group]: assets }));
        } catch (error) {
            console.error(`Error fetching ${group} data:`, error);
            setData((prev) => ({ ...prev, [group]: [] }));
        } finally {
            setLoading((prev) => ({ ...prev, [group]: false }));
        }
    };

    useEffect(() => {
        ["EQUITY", "FUTURES", "CURRENCY", "CASH"].forEach((group) =>
            fetchData(group)
        );
    }, []);

    const generateColumnDefs = (group: string) => {
        const columns = getTableData(group);
        return [
            {
                headerName: '',
                field: 'srNo',
                valueGetter: (params: any) => params.node.rowIndex + 1,
                sortable: false,
                filter: false,
                pinned: 'left', 
                width: 58,
            },
            ...columns.map((col) => ({
                headerName: t(col.colHeader),
                field: col.key,
                sortable: true,
                filter: true,
                valueFormatter: (params: any) => {
                    return applyDecorators(params.value, col.decorators);
                },
            })),
        ];
    };
    

    const customHeight = (group: string) => {
        if (data[group].length > 1) {
            const heightInRem = 1.876 + data[group].length * 1.9;
            return `${heightInRem}rem`;
        }
        if (data[group].length === 1) {
            const heightInRem = 1;
            return `${heightInRem}rem`;
        }
        return "4.6rem";
    };

    const resetCreateUser = () => {
        const currentPath = location.pathname;
        navigate(`${currentPath}/${routes.CREATE_ASSETS}`);
    };

    const handleEditUser = () => {
        if (selectedRow) {
          const processedRow = { ...selectedRow };

          if (processedRow.notice_date) {
            processedRow.notice_date = dateToString(processedRow.notice_date);
          }
      
          if (processedRow.last_trade_date) {
            processedRow.last_trade_date = dateToString(processedRow.last_trade_date);
          }
      
          if (processedRow.expiry_date) {
            processedRow.expiry_date = dateToString(processedRow.expiry_date);
          }
      
          const currentPath = location.pathname;
          navigate(`${currentPath}/${routes.CREATE_ASSETS}`, {
            state: { data: processedRow },
          });
        }
      };

    const handleDeleteClick = () => {
        if (selectedRow) {
            const assetKey = { ...selectedRow.asset_key };
            const assetKeyEntries = Object.entries(assetKey);
            if (assetKeyEntries.length > 0) {
                const [key, value] = assetKeyEntries[0] as [string, string];
                setPayloadEditKey(key);
                setPayloadEditValue(value);
                setOpened(true);
            }
        }
    };

    const handleDeleteConfirm = async () => {
        if (!payloadEditKey || !payloadEditValue) return; 
        try {
            await axios.delete(`${services.ASSET_INVESTMENT}?asset_id=${payloadEditValue}&asset_id_type=${payloadEditKey}`);
            console.log("Asset deleted successfully");
            const assetGroup = selectedRow?.asset_group;
            if (assetGroup) {
                await fetchData(assetGroup);
            }
            setSelectedRow(null);
            setOpened(false);
        } catch (error) {
            console.error("Error deleting asset", error);
        }
    };

    return (
        <div>
            <Box p="lg" mr="-1.7rem">
                <AdminTableHeader
                    headerTitle="show_search"
                    createTitle="CREATE_AN_ASSET"
                    isRowSelected={!!selectedRow} 
                    handleEditUser={handleEditUser}
                    handleDeleteClick={handleDeleteClick}
                    resetCreateUser={resetCreateUser}
                />
            </Box>

            {Object.values(data).every((groupData) => groupData.length === 0) ? (
             <NoDataPage />
         ) : (
             ["EQUITY", "FUTURES", "CURRENCY", "CASH"].map((group) => (
                 <AssetTable
                     key={group}
                     group={group}
                     data={data}
                     customHeight={customHeight}
                     generateColumnDefs={generateColumnDefs}
                     onSelectionChanged={handleSelectionChanged}
                 />
             ))
         )}

            <DeleteModalUser
                opened={opened}
                onClose={() => setOpened(false)}
                modalType="delete"
                TITLE="DELETE_ASSET"
                message="DELETE_ASSET_MESSAGE"
                onSubmit={handleDeleteConfirm}
            />

        </div>
    );
};

export default AssetTables;
