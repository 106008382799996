import React from 'react';
import { Flex, ActionIcon, useMantineTheme, Text, Autocomplete } from '@mantine/core';
import IconWrapper from '../Common/IconWrapper';
import { t } from 'i18next';
import { UserActionsProps } from '../../types';
import CustomButton from '../Common/Buttons/CustomButton';

const UserActions: React.FC<UserActionsProps> = ({
    isRowSelected,
    handleEditUser,
    handleDeleteClick,
    resetCreateUser,
    headerTitle,
    createTitle,

}) => {
    const theme = useMantineTheme();
    const icon = <IconWrapper name='search' />

    return (
        <Flex gap="md" justify="space-between" align="center" direction="row" wrap="wrap" mt={'xxxl'}>
            {headerTitle === 'show_search' ? (
                <Autocomplete
                    leftSectionPointerEvents="none"
                    leftSection={icon}
                    placeholder={t('SEARCH')}
                    w={298}
                    size='28'
                    className='investment-search'
                />
            ) : (
                <Text size={theme.fontSizes.lg} fw={600}>{t(headerTitle)}</Text>
            )}

            <Flex align="center" pr={'1.5rem'}>
                <ActionIcon
                    variant="transparent"
                    mr={theme.spacing.lg}
                    color={theme.colors.violet[8]}
                    onClick={handleEditUser}
                    disabled={!isRowSelected}
                    bg={isRowSelected ? "" : "transparent"}
                >
                    <IconWrapper name={isRowSelected ? 'pen' : 'disablepen'} />
                </ActionIcon>
                <ActionIcon
                    variant="transparent"
                    mr={theme.spacing.xxl}
                    color={theme.colors.violet[8]}
                    onClick={handleDeleteClick}
                    disabled={!isRowSelected}
                    bg={isRowSelected ? "" : "transparent"}
                >
                    <IconWrapper name={isRowSelected ? 'trash' : 'disbaletrash'} />
                </ActionIcon>
                <CustomButton variant={createTitle === 'CREATE_AN_ASSET' ? 'create-new-asset' : 'create'} onClick={resetCreateUser} children={t(createTitle)} />
            </Flex>
        </Flex>
    );
};

export default UserActions;
