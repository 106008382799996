import React from "react";
import { Flex, Tooltip } from "@mantine/core";

interface BarProps {
	values: number[];
	labels: string[];
	colors?: string[];
	height?: string;
}

const defaultColors = ["#ece3ff", "#ded0ff", "#651fff"];

export const StackedBar = ({
	values,
	labels,
	colors = defaultColors,
	height = "29px",
}: BarProps) => {
	const total = values.reduce((sum, value) => sum + value, 0);

	const normalize = (value: number, total: number) => (value / total) * 95;

	const calculateWidths = (value: number, index: number) => {
		let len = value > 25 ? 150 : value * 6;
		if (total > 31) {
			if (values[index] === Math.max(...values)) {
				len = 95;
			} else {
				const otherTotal = total - value;
				len = normalize(value, otherTotal);
			}
		}
		return len;
	};

	return (
		<Flex style={{ width: "100%" }}>
			{values.map((value, index) => {
				const backgroundColor = colors[index % colors.length];
				const fontColor = backgroundColor === "#651fff" ? "#fff" : "#000";

				return (
					<Tooltip
						key={index}
						label={labels[index]}
						aria-label={labels[index]}
						style={{
							boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
						}}
					>
						<div
							style={{
								width: `${calculateWidths(value, index)}%`,
								height: height,
								backgroundColor: backgroundColor,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								color: fontColor,
								fontSize: "11px",
							}}
						>
							{value > 0 ? value : ""}
						</div>
					</Tooltip>
				);
			})}
		</Flex>
	);
};
