import { useEffect, useState } from "react";
import { DatePicker, DateValue } from "@mantine/dates";
import { Button, TextInput, useMantineTheme, Text } from "@mantine/core";
import '@mantine/dates/styles.css';
import "./CalenderComponent.css";
import { format } from "date-fns";

type Props = {
    onSelect: (date: Date | null) => void;
    maxDate?: Date | undefined;
    placeholder: string;
    style?: object | null;
    decrementalCalender?: boolean;
    defaultDateValue?: Date | null;
};

const DATE_SPEC_TODAY = "DATE_SPEC_TODAY";
const DATE_SPEC_YESTERDAY = "DATE_SPEC_YESTERDAY";
const DATE_SPEC_TOMORROW = "DATE_SPEC_TOMORROW";

const weekArray = Array.from({ length: 7 }, (_, index) => index);

export const CalendarComponent = ({ onSelect, placeholder, style, decrementalCalender, maxDate, defaultDateValue }: Props) => {
    const theme = useMantineTheme();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);
    const [showCalendar, setShowCalendar] = useState(false);
    const [specialDate, setSpecialDate] = useState<string | null>(null);

    useEffect(() => {
        selectedDate && onSelect(selectedDate);
    }, [selectedDate]);

    useEffect(() => {
        if (defaultDateValue) handleCalendarSelect(defaultDateValue);
    }, [defaultDateValue]);

    useEffect(() => {
        if (specialDate === DATE_SPEC_TODAY) {
            setSelectedDate(new Date());
        } else if (specialDate === DATE_SPEC_YESTERDAY) {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            setSelectedDate(yesterday);
        } else if (specialDate === DATE_SPEC_TOMORROW) {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            setSelectedDate(tomorrow);
        }
    }, [specialDate]);


    const handleCalendarSelect = (date: DateValue | string | null) => {
        if (date === null) {
            setSelectedButtonIndex(0);
            setSelectedDate(null);
            setShowCalendar(false);
            return;
        }

        if (typeof date === 'string') {
            setSpecialDate(date);
            setSelectedDate(null);
            setShowCalendar(false);
            return;
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);
        const diffTime = date.getTime() - today.getTime();
        let diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
        if (decrementalCalender) diffDays *= -1;

        setSelectedButtonIndex(diffDays);
        setSpecialDate(null);
        setSelectedDate(date as Date);
        setShowCalendar(false);
    };

    const formatDateLabel = (days: number) => {
        if (decrementalCalender) days = days * -1;
        if (days === 0) return "Today";
        else if (days === 1) return "Tomorrow";
        else if (days === -1) return "Yesterday"
        const date = new Date();
        date.setDate(date.getDate() + days);

        const formattedDate = format(date, "E, MMM dd");
        return `${formattedDate} (${decrementalCalender ? "" : "+"}${days}d)`;
    };

    const handleDateButtonClick = (days: number) => {
        if (decrementalCalender) days *= -1;
        switch (days) {
            case 0:
                setSelectedButtonIndex(0);
                handleCalendarSelect(DATE_SPEC_TODAY);
                break;
            case 1:
                setSelectedButtonIndex(1);
                handleCalendarSelect(DATE_SPEC_TOMORROW);
                break;
            case -1:
                setSelectedButtonIndex(1);
                handleCalendarSelect(DATE_SPEC_YESTERDAY);
                break;
            default:
                const clickedDate = new Date();
                clickedDate.setHours(0, 0, 0, 0);
                clickedDate.setDate(clickedDate.getDate() + days);
                handleCalendarSelect(clickedDate);
                break;
        }
    };


    const renderDay = (date: Date) => {
        const selectedColor = {
            backgroundColor: theme.colors.grey[3],
            color: theme.colors.dark[9],
        };
        return (
            <div
                className='selectedicon'
                style={selectedDate && date.toDateString() === selectedDate.toDateString() ? selectedColor : undefined}
                onClick={() => {
                    setSelectedDate(date);
                }}
            >
                {date.getDate()}
            </div>
        );
    };

    return (
        <div
            className="calender-container"
            style={{
                display: "flex",
                flexDirection: "column",
                cursor: "pointer",
            }}
        >
            <TextInput
                className="calender-input"
                onClick={() => setShowCalendar(true)}
                value={
                    selectedDate ?
                        Math.abs(selectedButtonIndex) < 2 ? formatDateLabel(selectedButtonIndex)
                            : selectedDate.toDateString()
                        : ""
                }
            />

            {showCalendar && (
                <div className='calender' style={style ? style : {}}>
                    <DatePicker
                        className="date-picker"
                        highlightToday={true}
                        firstDayOfWeek={0}
                        onChange={(value) => {
                            handleCalendarSelect(value);
                        }}
                        weekdayFormat={(date) => format(date, "EEE").toUpperCase()}
                        renderDay={renderDay}
                        maxDate={maxDate}
                    />

                    <div className='buttons'>
                        {weekArray.map((dayIndex) => (
                            <Button
                                key={dayIndex}
                                onClick={() => handleDateButtonClick(dayIndex)}
                                className={`date-button${selectedButtonIndex === dayIndex ? " active" : ""}`}
                                h={34}
                                size="lg"
                            >
                                <Text size="0.9375rem" fw='500'>
                                    {formatDateLabel(dayIndex)}
                                </Text>
                            </Button>
                        ))}
                    </div>

                </div>
            )}
        </div>
    );
};
