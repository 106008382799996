import React, { useEffect, useRef, useState } from "react";
import { ThresoldLeft } from "../../components/Algojournal/ThresoldLeft";
import { rowInfo } from "../../types";
import { ThresoldRight } from "../../components/Algojournal/ThresoldRight";
import { OrderTable } from "../../components/Algojournal/OrderTable";
import axios from "axios";
import { useMantineTheme, Text, Badge, Flex, Box } from "@mantine/core";
import { services } from "../../services";
import { useParams } from "react-router-dom";
import { KeyValuePairs } from "../../types";
import ErrorMessage from "../../components/Common/ErrorMessage";
import SkeletonLoader from "../../components/Common/Skeleton";
import IconWrapper from "../../components/Common/IconWrapper";
import { t } from "i18next";
import { computeOrderSummary } from "../../utils/responseUtils";
import { directPercentage, getConfig } from "../../utils/conversions";
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import "./AnalysisOrderTab.css";
import { ISplitProps } from "split-pane-react/esm/types";
import { getBadgeStyles } from "../../utils/getStyles";

const AnalysisOrderTab: React.FC = () => {
	const [algoJournalData, setAlgoJournalData] = useState<any>(null);
	const [orderData, setOrderData] = useState<any>(null);
	const splitPaneRef = useRef<HTMLDivElement>(null);
	const theme = useMantineTheme();
	const { fund_id, transaction_id, target_mode, target_date, fund_state, calc_date } = useParams();
	const [errorMessage, setErrorMessage] = useState<string | null>();
	const [errorMessageOrder, setErrorMessageOrder] = useState<string | number | null>();
	const formattedTargetMode = target_mode ? target_mode.toUpperCase().replace(/-/g, "_") : null;
	const [loading, setLoading] = useState<boolean>(true);
	const [totalBuyShare, setTotalBuyShare] = useState<number>(0);
	const [totalSellShare, setTotalSellShare] = useState<number>(0);
	const [orderListId, setOrderListId] = useState<string[]>([]);
	const [totalBuyAmountInMillions, setTotalBuyAmountInMillions] = useState<number>(0);
	const [totalSellAmountInMillions, setTotalSellAmountInMillions] = useState<number>(0);
	const [totalBuyPct, setTotalBuyPct] = useState<number>(0);
	const [totalSellPct, setTotalSellPct] = useState<number>(0);
	const config = getConfig("order");

	const option: number = +(config?.DISPLAY_OPTION?.value || 1);
	const isOption1 = option === 1;
	interface displayConfigProps extends Partial<ISplitProps> {
		minSize: number | undefined,
		maxSize: string,
		defaultSizes: any,
	}

	const displayConfig: displayConfigProps = {
		split: isOption1 ? "horizontal" : "vertical",
		minSize: isOption1 ? 100 : 200,
		maxSize: isOption1 ? "80%" : "90%",
		resizerSize: isOption1 ? 0.5 : 0.5,
		defaultSizes: isOption1 ? [500, '60%', 'auto'] : [700, '60%', 'auto'],
	}

	const [sizes, setSizes] = useState(displayConfig.defaultSizes);
	const { inBadge, outBadge } = getBadgeStyles(theme);

	const allOrders: any[] =
		orderData?.data?.reduce((accumulator: any[], entry: any) => {
			const filteredOrders = entry.orders.map((order: any) => {
				const { column1, column2, ...rest } = order;
				return rest;
			});
			return accumulator.concat(filteredOrders);
		}, []) || [];

	const fetchData = async () => {
		try {
			setLoading(true);
			const response = await axios.get(services.ALGO_JOURNAL, {
				params: {
					fund_id: fund_id,
					target_date: target_date,
					target_mode: formattedTargetMode,
					calc_date: calc_date,
				},
			});
			setErrorMessage(null)
			setAlgoJournalData(response.data);
			if (response.data.data === null) {
				setErrorMessage(("NO_DATA_FOUND"));
				return;
			}
		} catch (error: any) {
			setErrorMessage(error.response.status);
		} finally {
			setLoading(false);
		}
	};

	const fetchOrder = async () => {
		try {
			setLoading(true);
			const response = await axios.get(services.ORDER_LIST, {
				params: {
					fund_id: fund_id,
					target_date: target_date,
					target_mode: formattedTargetMode,
					calc_date: calc_date
				},
			});
			setOrderData(response.data);
			setErrorMessageOrder(null)

			let { total_buy_shares,
				total_sell_shares,
				total_buy_amount_in_millions,
				total_sell_amount_in_millions,
				total_buy_pct, total_sell_pct, orderListArray } = computeOrderSummary(response);

			setOrderListId(orderListArray)

			setTotalBuyShare(total_buy_shares);
			setTotalSellShare(total_sell_shares);

			setTotalBuyAmountInMillions(total_buy_amount_in_millions);
			setTotalSellAmountInMillions(total_sell_amount_in_millions);

			setTotalBuyPct(total_buy_pct*100);
			setTotalSellPct(total_sell_pct*100);

			if (response.data.data === null) {
				setErrorMessageOrder("NO_DATA_FOUND");
				return;
			}
		} catch (error: any) {
			const status = error.response?.status ?? 'Unknown Error';
			setErrorMessageOrder(status);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
		fetchOrder();
	}, []);

	if (loading) {
		return <SkeletonLoader />;
	}

	if (!algoJournalData) {
		return <SkeletonLoader />;
	}

	const journalEntries = algoJournalData.data?.journal_entries || [];

	const RenderTablesMemoized = React.memo(({ journalEntries, theme }: any) => {
		return (
			<div>
				{errorMessage && <ErrorMessage errorMessage={errorMessage} />}
				{journalEntries.map((entry: any, index: number) => {
					const words = entry.description.split(" ");
					const lastWord = words.pop();
					const isLastWordIn = lastWord?.toUpperCase() === "IN";
					const isLastWordOut = lastWord?.toUpperCase() === "OUT";

					const toHead = (s: string | undefined) => {
						const n = typeof s === "string" ? s.toLowerCase().split("_") : [];
						const conv = n.map((x) => (x && x[0] ? x[0].toLocaleUpperCase() + x.slice(1) : ""));
						return conv.join(" ");
					};

					const obj: KeyValuePairs = {};
					entry?.fund_stats_examined_in_algo?.forEach((e: any) => {
						obj[toHead(e?.fund_target?.type ?? "")] = {
							stats: e.stats,
							violated_tolerance: e.violated_tolerance,
							is_out_of_tolerance: e.is_out_of_tolerance,
							upper_threshold: e.fund_target?.upper_threshold,
							lower_threshold: e.fund_target?.lower_threshold,
						};

					});

					const rightObj: KeyValuePairs = {};
					entry?.fund_stats_not_examined_in_algo?.forEach((e: any) => {
						rightObj[toHead(e?.fund_target?.type ?? "")] = {
							stats: e.stats,
							violated_tolerance: e.violated_tolerance,
							is_out_of_tolerance: e.is_out_of_tolerance,
						};
					});

					const rowDataLeft: rowInfo = obj as rowInfo;
					const rowDataRight: rowInfo = rightObj as rowInfo;

					const bulletSVG =
						index === journalEntries.length - 1
							? <IconWrapper name="purplebullet" className="bullet-image" />
							: <IconWrapper name="greybullet" className="bullet-image" />

					return (
						// Inside RenderTablesMemoized component
						<div
							key={index}
							className={`connection-line ${index !== journalEntries.length - 1 ? "has-divider" : ""
								}`}
						>
							{bulletSVG}
							<div>
								<Flex>
									<Text fw={600} size={theme.fontSizes.md} mb={'1rem'} className='description'>
										{words.join(" ")}{" "}
										{isLastWordIn ? inBadge : outBadge}
									</Text>
								</Flex>
								<div className='tablealign'>
									<ThresoldLeft rowData={[rowDataLeft]} />
									{entry?.fund_stats_not_examined_in_algo?.length > 0 ? (<div className='tablegap'>
										<div className='vertical-divider'></div>
										<ThresoldRight rowData={[rowDataRight]} />
									</div>) : <br/>}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		);
	});

	const RenderOrdersMemoized = React.memo(({ allOrders }: any) => {
		return (
			<Box h={'85vh'}>
				{Array.isArray(allOrders) && allOrders.length > 0 ? (
					<OrderTable key='commonHeader' orders={allOrders} TITLE="Orders" initialTotalBuyShares={totalBuyShare}
						initialTotalSellShares={totalSellShare}
						initialTotalBuyAmountInMillions={totalBuyAmountInMillions}
						initialTotalSellAmountInMillions={totalSellAmountInMillions}
						initialTotalBuyPct={totalBuyPct} initialTotalSellPct={totalSellPct} initialOrderListID={orderListId} />
				) : (
					<></>
				)}
			</Box>
		);
	});

	return (
		<>
			{fund_state === "Completed" ? (
				<div className="main-page-padding">
					<div className="order-table-with-border">
						<RenderOrdersMemoized allOrders={allOrders} />
					</div>
				</div>
			) : (
				<div className={`flex h-screen ${isOption1 ? "" : "order-option-2"}`}>
					<SplitPane
						split={displayConfig.split}
						sizes={sizes}
						onChange={setSizes}
						resizerSize={displayConfig.resizerSize}
						sashRender={() => <></> /* this is needed due to plugins limitation */}
					>
						<Pane minSize={displayConfig.minSize} maxSize={displayConfig.maxSize}>
							<div className="overflow-x-auto"></div>
							<div className='split-pane'>
								<div className="main-page-padding ">
									<div
										ref={splitPaneRef}
										className='tables-container'
									>
										{isOption1 && <Text size={theme.fontSizes.xl} fw={700}>{t("ANALYSIS")}</Text>}
										<RenderTablesMemoized
											journalEntries={journalEntries}
											theme={theme}
											errorMessageOrder={errorMessageOrder}
										/>
									</div>
								</div>
							</div>
						</Pane>
						<Pane>
							{errorMessageOrder ? (
								<Flex direction="column" align="center" style={{ height:  "100%", width: "100%", paddingTop: "2rem" }}>
									<h2>{t('NO_ORDERS_TITLE')}</h2>
									<div>{t('NO_ORDERS_MESSAGE')}</div>
								</Flex>
							) : (
								<div className="flex grow">
									<div className='order-table' style={{ paddingLeft: '1.5rem' }}>
										<RenderOrdersMemoized allOrders={allOrders} />
									</div>
								</div>
							)}
						</Pane>
					</SplitPane>
				</div>
			)}
		</>
	);

};

export default AnalysisOrderTab;

