import { GridOptions } from "ag-grid-community";
import { routes } from "../../routes";
import { t } from "i18next";

export const gridOptions: GridOptions = {
    suppressRowHoverHighlight: true,
};

export const rowSelectionType = 'single'
export const calculateMaxHeight = (routes.ALLFUNDSTATUS ? "44rem" : "23.8rem")

export const fundIdHeader = t("FUND_ID");
export const CANCEL = t("CANCEL")
export const I_M_STARTING = t("I_M_STARTING");
export const ME = t("ME");

export const disabledStatesForNavigation = {
    [ME]: 'false',
    [I_M_STARTING]: 'true',
};

  



