import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import { useMantineTheme } from '@mantine/core';
import { t } from 'i18next';
import CustomNoRowsOverlay from '../../../components/Common/customNoRowsOverlay';
import EditToggleButton from '../../../components/FundConfig/EditToggleButton';
import CustomModal from '../../../components/FundConfig/CustomModal';
import { services } from '../../../services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './FundConfig.css'
import LoaderIcon from '../../../components/Common/LoaderIcon';

interface RowData {
  ref_template_name: any;
  fund_id: string;
  original: {
    lower_threshold: number;
    upper_threshold: number;
    target_value: number;
    type: string;
    target_category: string;
  };
  lower_threshold: number;
  upper_threshold: number;
  target_value: number;
  type: string;
  target_category: string;
  disabled?: boolean; // Added property to manage row state
}


const TargetsTolerances: React.FC = () => {
  const [rowData, setRowData] = useState<RowData[]>([]);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCells, setEditedCells] = useState<Map<string, string>>(new Map());
  const [originalValues, setOriginalValues] = useState<Map<string, any>>(new Map());
  const theme = useMantineTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [permissions, setPermissions] = useState<{ [key: string]: boolean }>({});

  const handleToggle = async () => {
    await fetchPermissions(); // Call the fetchPermissions function
    setIsEditing((prev) => !prev); // Toggle the editing state
  };

  const fetchPermissions = async () => {
    try {
      const response = await axios.get('api/fund-permissions');
      const fundPermissions = response.data.data;

      const newRowData = rowData.map(row => {
        const fundPermission = fundPermissions.find((f: { fund_id: string; }) => f.fund_id === row.fund_id);
        const canEdit = fundPermission ? fundPermission.fund_group_permissions.includes('write') : false;
        console.log("fundPermission", canEdit)

        return { ...row, disabled: !canEdit }; // Set disabled based on permission
      });

      setRowData(newRowData);
      setPermissions(fundPermissions);
    } catch (error) {
      toast.error('Failed to fetch permissions.');
      console.error('Error fetching permissions:', error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(services.TARGETS);
      const fundsData = response.data.data;
      const targetRows = fundsData.flatMap((fund: any) =>
        fund.targets.map((target: any) => ({
          fund_id: fund.fund_id,
          fund_name: fund.fund_name,
          ref_template_name: fund.ref_template_name,
          original: {
            lower_threshold: target.lower_threshold,
            upper_threshold: target.upper_threshold,
            target_value: target.target_value,
            type: target.type,
            target_category: target.target_category,
          },
          lower_threshold: target.lower_threshold,
          upper_threshold: target.upper_threshold,
          target_value: target.target_value,
          type: target.type,
          target_category: target.target_category,
        }))
      );
      setRowData(targetRows);
      setLoading(false);
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error('Error fetching data:', error.message);
      } else {
        console.error('Unknown error fetching data:', error);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!isModalOpen) {
      fetchData();
    }
  }, [isModalOpen]);


  const handleCellClicked = (params: any) => {
    if (params.colDef.field === 'ref_template_name') {
      setSelectedTemplate(params.value);
      setIsModalOpen(true);
    }
  };

  const onCellValueChanged = (params: any) => {
    if (!isEditing) return;

    const { data, colDef } = params;
    const cellKey = `${params.rowIndex}-${colDef.field}`;
    setEditedCells((prev) => new Map(prev).set(cellKey, theme.colors.violet[1]));

    if (!originalValues.has(cellKey)) {
      originalValues.set(cellKey, data[colDef.field]);
    }
  };

  const handleSave = async () => {
    const groupedPayload: Record<string, any> = {};
    const allTargetsMap: Record<string, any[]> = {};

    rowData.forEach((row) => {
      if (!allTargetsMap[row.fund_id]) {
        allTargetsMap[row.fund_id] = [];
      }
      allTargetsMap[row.fund_id].push({
        type: row.type,
        target_category: row.target_category,
        lower_threshold: row.original.lower_threshold,
        upper_threshold: row.original.upper_threshold,
        target_name: '',
        target_value: row.original.target_value,
      });
    });

    rowData.forEach((row) => {
      const fundId = row.fund_id;
      const reference_template_name = row.ref_template_name;
      const targets = allTargetsMap[fundId];

      const targetIndex = targets.findIndex((target) => target.type === row.type);
      if (targetIndex !== -1) {
        let hasChanges = false;

        if (row.lower_threshold !== row.original.lower_threshold) {
          targets[targetIndex].lower_threshold = row.lower_threshold;
          hasChanges = true;
        }
        if (row.upper_threshold !== row.original.upper_threshold) {
          targets[targetIndex].upper_threshold = row.upper_threshold;
          hasChanges = true;
        }
        if (row.target_value !== row.original.target_value) {
          targets[targetIndex].target_value = row.target_value;
          hasChanges = true;
        }

        if (hasChanges) {
          if (!groupedPayload[fundId]) {
            groupedPayload[fundId] = { fund_id: fundId, ref_template_name: reference_template_name, targets: [] };
          }
          groupedPayload[fundId].targets = targets;
        }
      }
    });

    const payload = Object.values(groupedPayload);

    if (payload.length > 0) {
      try {
        const response = await axios.post(services.TARGETS, payload);
        console.log('Data saved successfully:', response.data);
        setEditedCells(new Map());
        setOriginalValues(new Map());
        await fetchData();
        setIsEditing(false)
      } catch (error: unknown) {
        let errorMessage = 'An unknown error occurred';
        if (axios.isAxiosError(error) && error.response?.data?.detail) {
          errorMessage = error.response.data.detail;
        }
        toast.error(errorMessage);
        console.error('Error updating:', errorMessage);
      }

    } else {
      console.log('No changes to save.');
    }
  };

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
    if (isEditing) {
      setEditedCells(new Map());
      setOriginalValues(new Map());
      fetchData();
    } else {
      fetchPermissions();
    }
  };


  const getCellStyle = (params: any) => {
    const cellKey = `${params.rowIndex}-${params.colDef.field}`;
    const bgColor = editedCells.get(cellKey) || '';
  
    if (params.colDef.field === 'target_value' && isEditing) {
      const { lower_threshold, upper_threshold, target_value } = params.data;
  
      if (target_value < lower_threshold || target_value > upper_threshold) {
        return {
          className: 'cell-edited',
          backgroundColor: bgColor,
        };
      }
    }
  
    return {
      className: 'cell-normal',
      backgroundColor: bgColor,
    };
  };
  
  const getRowStyle = (params: any) => {
    if (params.data.disabled) {
      return {
        className: 'row-disabled',
      };
    }
    return {
      className: 'row-normal',
    };
  };
  

const columnDefs: ColDef<any, any>[] = useMemo(() => [
  { headerName: 'Fund ID', field: 'fund_id', editable: false },
  { headerName: 'Fund Name', field: 'fund_name', editable: false },
  { headerName: 'Template', field: 'ref_template_name', editable: false },
  { headerName: 'Target Name', field: 'type', editable: false },
  { headerName: 'Target Category', field: 'target_category', editable: false },
  { 
    headerName: 'Target Value', 
    field: 'target_value', 
    editable: (params) => !params.data.disabled && isEditing, 
    cellStyle: getCellStyle 
  },
  { 
    headerName: 'Lower Threshold', 
    field: 'lower_threshold', 
    editable: (params) => !params.data.disabled && isEditing, 
    cellStyle: getCellStyle 
  },
  { 
    headerName: 'Upper Threshold', 
    field: 'upper_threshold', 
    editable: (params) => !params.data.disabled && isEditing, 
    cellStyle: getCellStyle 
  },
], [isEditing, editedCells]);

const gridOptions = {
  columnDefs,
  getRowStyle,
};

  const customHeight = () => {
    if (rowData.length > 1) {
      const heightInRem = 1.876 + rowData.length * 1.9;
      return `${heightInRem}rem`;
    }

    if (rowData.length === 0 && !loading) {
      const heightInRem = 6.7;
      return `${heightInRem}rem`;
    }
  };

  const noRowsOverlayComponentParams = useMemo(() => ({
    noRowsMessageFunc: () => `- ${t("NO_FUNDS_HERE")} -`,
  }), []);

  const getRowClass = (params: any) => {
    return params.data.disabled ? 'disabled-row' : '';
  };  
  
  return (
    <>{loading ? (
      <LoaderIcon name='loading-large' alt='loading icon' />
    ) : (<div className='fund-query ag-theme-quartz' style={{
      height: customHeight(),
      width: "57.625rem",
      minHeight: "4.4rem",
      maxHeight: '85vh',
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}>
      <EditToggleButton
        isEditing={isEditing}
        onToggle={handleToggle}
        onUpdate={handleSave}
        onDiscard={handleEditToggle}
      />
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        getRowClass={getRowClass}
        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        suppressRowHoverHighlight={true}
        noRowsOverlayComponent={CustomNoRowsOverlay}
        onCellClicked={handleCellClicked}
        rowHeight={30}
        defaultColDef={{
          sortable: true,
          filter: true,
          resizable: true,
        }}
        onCellValueChanged={onCellValueChanged}
      />
      <ToastContainer />
      {isModalOpen && (
        <CustomModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}

    </div>)}
    </>
  );
};

export default TargetsTolerances;
