import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import axios from 'axios';
import { services } from '../../../services';
import EditToggleButton from '../../../components/FundConfig/EditToggleButton';
import { useMantineTheme } from '@mantine/core';
import { t } from 'i18next';
import CustomNoRowsOverlay from '../../../components/Common/customNoRowsOverlay';
import { RowDataTOL } from '../../../types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './FundConfig.css'
import LoaderIcon from '../../../components/Common/LoaderIcon';

const TOLTemplates: React.FC = () => {
  const [rowData, setRowData] = useState<RowDataTOL[]>([]);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCells, setEditedCells] = useState<Map<string, string>>(new Map());
  const [originalValues, setOriginalValues] = useState<Map<string, any>>(new Map());
  const theme = useMantineTheme();
  const fetchData = () => {
    return axios.get(services.GET_TEMPLATES)
      .then((response) => {
        const fundsWithTargets: RowDataTOL[] = response.data.data.map((template: any) => {
          return template.targets.map((target: any) => ({
            template_name: template.template_name,
            type: target.type,
            target_category: target.target_category,
            target_value: target.target_value,
            lower_threshold: target.lower_threshold,
            upper_threshold: target.upper_threshold,
          }));
        }).flat();
        setRowData(fundsWithTargets);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onCellValueChanged = (params: any) => {
    if (!isEditing) return;

    const { data, colDef } = params;
    const cellKey = `${params.rowIndex}-${colDef.field}`;
    setEditedCells((prev) => new Map(prev).set(cellKey, theme.colors.violet[1]));

    if (!originalValues.has(cellKey)) {
      originalValues.set(cellKey, data[colDef.field]);
    }
  };

  const handleUpdate = async () => {
    const changesByTemplate: { [key: string]: any[] } = {};
    rowData.forEach(row => {
      const { template_name, type, target_category, lower_threshold, upper_threshold, target_name, target_value } = row;
  
      if (!changesByTemplate[template_name]) {
        changesByTemplate[template_name] = [];
      }
  
      changesByTemplate[template_name].push({
        type,
        target_category,
        lower_threshold,
        upper_threshold,
        target_name,
        target_value,
        template_name,
      });
    });
  
    const modifiedTemplates: { [key: string]: any[] } = {};
  
    editedCells.forEach((_, cellKey) => {
      const [rowIndex, field] = cellKey.split('-');
      const row = rowData[parseInt(rowIndex)];
      const { template_name, type } = row;
  
      if (!modifiedTemplates[template_name]) {
        modifiedTemplates[template_name] = [...changesByTemplate[template_name]];
      }
  
      const targetToUpdate = modifiedTemplates[template_name].find(target => target.type === type);
      if (targetToUpdate) {
        targetToUpdate[field] = row[field as keyof RowDataTOL];
      }
    });
  
    const payload = Object.keys(modifiedTemplates).map(template_name => ({
      template_name,
      targets: modifiedTemplates[template_name].map(target => ({
        type: target.type,
        target_category: target.target_category,
        lower_threshold: target.lower_threshold,
        upper_threshold: target.upper_threshold,
        target_name: target.target_name,
        target_value: target.target_value,
      })),
    }));
  
    if (payload.length === 0) {
      console.log('No changes to update.');
      return;
    }
  
    try {
      const response = await axios.post(services.GET_TEMPLATES, payload);
      console.log('Update successful:', response.data);
      setEditedCells(new Map());
  
      const latestDataResponse = await axios.get(services.GET_TEMPLATES);
      if (Array.isArray(latestDataResponse.data)) {
        setRowData(latestDataResponse.data);
      } else {
        console.error('Expected latest data to be an array:', latestDataResponse.data);
      }
      setIsEditing(false);
    } catch (error: unknown) {
      let errorMessage = 'An unknown error occurred';
      if (axios.isAxiosError(error) && error.response?.data?.detail) {
        errorMessage = error.response.data.detail;
      }
      toast.error(errorMessage);
      console.error('Error updating:', errorMessage);
    }
  };

  const handleDiscard = () => {
    setIsEditing(false);
    setEditedCells(new Map());
    fetchData();
  };

  const getCellStyle = (params: any) => {
    const cellKey = `${params.rowIndex}-${params.colDef.field}`;
    const bgColor = editedCells.get(cellKey) || '';
  
    if (params.colDef.field === 'target_value' && isEditing) {
      const { lower_threshold, upper_threshold, target_value } = params.data;
      if (target_value < lower_threshold || target_value > upper_threshold) {
        return {
          className: 'cell-edited',
          backgroundColor: bgColor,
        };
      }
    }
    return {
      className: 'cell-normal',
      backgroundColor: bgColor,
    };
  };
  

  const columnDefs: ColDef[] = useMemo(() => [
    { headerName: 'Template', field: 'template_name', editable: false, width: 120 },
    { headerName: 'Target Name', field: 'type', editable: false },
    { headerName: 'Target Category', field: 'target_category', editable: false },
    { headerName: 'Target Value', field: 'target_value', editable: isEditing, cellStyle: getCellStyle },
    { headerName: 'Lower Threshold', field: 'lower_threshold', editable: isEditing, cellStyle: getCellStyle },
    { headerName: 'Upper Threshold', field: 'upper_threshold', editable: isEditing, cellStyle: getCellStyle },
  ], [isEditing, editedCells]);

  const customHeight = () => {
    if (rowData.length > 1) {
      const heightInRem = 1.876 + rowData.length * 1.9;
      return `${heightInRem}rem`;
    }

    if (rowData.length === 0 && !loading) {
      const heightInRem = 6.75;
      return `${heightInRem}rem`;
    }
  };

  const noRowsOverlayComponentParams = useMemo(() => ({
    noRowsMessageFunc: () => `- ${t("NO_TEMPLATES_HERE")} -`,
  }), []);

  return (
    <>{loading ? (
      <LoaderIcon name='loading-large' alt='loading icon' />
    ) : (<div className='fund-query ag-theme-quartz' style={{
      height: customHeight(),
      width: "57.625rem",
      minHeight: "4.4rem",
      maxHeight: '85vh',
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}>

      <EditToggleButton
        isEditing={isEditing}
        onToggle={() => setIsEditing(!isEditing)}
        onUpdate={handleUpdate}
        onDiscard={handleDiscard}
      />
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        suppressRowHoverHighlight={true}
        noRowsOverlayComponent={CustomNoRowsOverlay}
        rowHeight={30}
        defaultColDef={{
          sortable: true,
          filter: true,
          resizable: true,
          editable: isEditing,
        }}
        onCellValueChanged={onCellValueChanged}
      />
      <ToastContainer />
    </div>)}
    </>);
};

export default TOLTemplates;
