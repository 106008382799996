import { Flex, Text } from '@mantine/core';
import { IconReportSearch } from '@tabler/icons-react';
import { t } from 'i18next';
import React from 'react';

const NoData: React.FC = () => {
  return (
    <Flex align={'center'} direction={'column'} mt={'7rem'}>
      <Flex align="center" direction="column">
        <IconReportSearch className="recent-presets-icon" />
      </Flex>
      <Text size='1.25rem' fw={600} mt={'3.6rem'}>{t('EMPTY_ASSET_MESSAGE')}</Text>
    </Flex>
  );
};

export default NoData;