import { MarketCounterSingle } from "../../../../types";

export const isMarketCounterSingle = (obj: unknown): obj is MarketCounterSingle => {
	return (
		obj !== null &&
		typeof obj === "object" &&
		!Array.isArray(obj) &&
		"total_funds_in_aggregate_count" in obj
	);
};
