interface LabelDetails {
	id: string;
	labelKey: string;
	labels: string[];
	colors: string[];
	icon: string;
}

type LabelsWithDetailsType = Record<string, LabelDetails>;

const COMMON_COLORS = ["#ece3ff", "#ded0ff", "#651fff"];

export const LABELS_WITH_DETAILS: LabelsWithDetailsType = [
	{
		id: "READY",
		labels: ["In with orders", "Out with orders", "Out without orders"],
		icon: "readyicon",
	},
	{
		id: "NOT_READY",
		labels: ["Data missing", "Benchmark not updated", "Start of day recon not done"],
		icon: "notreadyicon",
	},
	{
		id: "COMPLETED",
		labels: ["In with no order", "In after placing orders", "Out approved with overrides"],
		icon: "completedicon",
	},
].reduce<LabelsWithDetailsType>((acc, { id, labels, icon }) => {
	acc[id] = {
		id,
		labelKey: id,
		labels,
		colors: COMMON_COLORS,
		icon,
	};
	return acc;
}, {});
