import React, { useState, useEffect, useRef } from "react";
import {
  ActionIcon,
  Box,
  Collapse,
  Flex,
  useMantineTheme,
  Text,
  TextInput,
  Grid,
  Accordion,
} from "@mantine/core";
import IconWrapper from "../../Common/IconWrapper";
import { Data, Document, DocumentDrop, PresetOption2Props } from '../../../types';
import { services } from "../../../services";
import axios from "axios";
import ContextMenu from "../ContextMenu2";
import { v4 as uuid_v4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Preset.css";
import useClickOutside from "../Presets/useClickOutside";
import { ContextMenuState, handleEvent, handleSave as saveRenameFolder, updateContextMenu } from "../../../utils/handleRename";
import { t } from "i18next";
import { QueryForm } from "./QueryForm";
import { formatDate, processDates, renameDocument, renameDocumentFolder, savePreset } from "./PresetsUtils";
import "./PresetsOption2.css";
import { IconChevronLeft, IconChevronRight, IconPaperclip, IconX, IconStar, IconChevronUp } from "@tabler/icons-react";

export function Preset({
  onSubmit,
  addRecentPresets,
  selectedPreset,
  handleRefresh,
  setErrorMessage,
  isPresetOpen,
  setIsPresetOpen,
  isLeftNavOpen,
  setIsLeftNavOpen
}: PresetOption2Props) {
  const theme = useMantineTheme();
  const DEFAULT_FOLDER_NAME = "Untitled Folder";
  const [creatingFolder, setCreatingFolder] = useState<boolean>(false);
  const [folderTitle, setFolderTitle] = useState<string>("");
  const [renamingDocId, setRenamingDocId] = useState<string | null>(null);
  const [renamingFolderId, setRenamingFolderId] = useState<string | null>(null);
  const [newName, setNewName] = useState<string>("");
  const [renameUuids, setRenameUuids] = useState<string>("");
  const [data, setData] = useState<Data[]>([]);
  const [storedFolders, setStoredFolders] = useState<string[]>([]);
  const [newEmptyFolderNameDelete, setEmptyNewFolderNameDelete] = useState<boolean>(false);
  const [newEmptyFolderName, setNewEmptyFolderName] = useState("");
  const [fundGroup, setFundGroup] = useState<string>("");
  const [fundId, setFundId] = useState<string>("");
  const [assetId, setAssetId] = useState<string>("");
  const [assetIdType, setAssetIdType] = useState<string | null>("");
  const [selectedTypeOfData, setSelectedTypeOfData] = useState<string | null>("");
  const [settled, setSettled] = useState<boolean>(false);
  const [onLoan, setOnLoan] = useState<boolean>(false);
  const [total, setTotal] = useState<boolean>(false);
  const today = new Date();
  const [selectedFromDate, setSelectedFromDate] = useState<Date | null>(null);
  const [selectedToDate, setSelectedToDate] = useState<Date | null>(null);
  const [isSavePresetModalOpen, setIsSavePresetModalOpen] = useState(false);
  const [presetName, setPresetName] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFolderName, setSelectedFolderName] = useState('');
  const [totalGrid, leftGrid, centerGrid, rightGrid] = [12, 1.5, 9, 1.5];
  const inputRef = useRef<HTMLInputElement>(null);
  const [opened, setOpened] = useState<string | null>("Favorites");
  const [newNameFolder, setNewNameFolder] = useState<string>("");

  const customChevron = (item: string) =>
    opened === item ? (
      <IconChevronUp size={16} />) : (<IconChevronRight size={16} />
    );

  useEffect(() => {
    handleDocumentClick(selectedPreset);
    if (!isLeftNavOpen) setIsLeftNavOpen(true);
  }, [selectedPreset])

  useEffect(() => {
    if (data.length > 0) localStorage.setItem("presetName", JSON.stringify(data));
  }, [data]);

  useEffect(() => {
    fetchData();
    if (inputRef.current) inputRef.current.focus();
  }, []);

  useEffect(() => {
    !creatingFolder && setFolderTitle("");
  }, [creatingFolder])

  const handleDocumentClick = (uuid: string) => {
    setIsLoading(true);
  
    try {
      if (data) {
        let presetItem = null;
        
        for (const folder of data) {
          presetItem = folder.documents.find((item: { uuid: string }) => item.uuid === uuid);
          if (presetItem) break; 
        }

        if (presetItem) {
          console.log("presetItem", presetItem)
          addRecentPresets(presetItem.uuid);
          setFundId(presetItem.funds?.[0]); 
          setAssetId(presetItem.asset_specific_fields?.asset_keys?.[0]); 
          setFundGroup(presetItem.fund_group?.[0]);
          setSelectedTypeOfData(presetItem.data_type);
          setSelectedFromDate(presetItem.start_date ? new Date(presetItem.start_date) : null);
          setSelectedToDate(presetItem.end_date ? new Date(presetItem.end_date) : null);
          setAssetIdType(presetItem.asset_specific_fields?.asset_key_types);
          setIsPresetOpen(false);
        } else {
          console.warn("Preset data not found for the provided UUID."); 
        }
      } else {
        console.warn("No data found in local storage."); 
      }
    } catch (error) {
      console.error("Error fetching document data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    handleRefresh();
    setIsLoading(true);

    const formData = {
      fund_group: fundGroup,
      fund_id: fundId,
      asset_id: assetId,
      asset_id_type: assetIdType,
      start_date: formatDate(selectedFromDate),
      end_date: formatDate(selectedToDate),
      settled,
      on_loan: onLoan,
      total,
      selectedTypeOfData
    };
    await onSubmit(formData);
    setIsLoading(false);
  };

  const handleSave = async (folderId: string | null = null) => {
    toggleSavePresetModal();
    const uuid = uuid_v4();
    try {
      await savePreset(folderId, uuid, {
        presetName,
        selectedFolderName,
        fundGroup,
        fundId,
        selectedFromDate: formatDate(selectedFromDate),
        selectedToDate: formatDate(selectedToDate),
        settled,
        onLoan,
        total,
        selectedTypeOfData,
        assetId,
        assetIdType,
      });
      fetchData();
    } catch (error: any) {
      console.error("Error making API request:", error);
      setErrorMessage(error.message)
    }
  };

  const toggleSavePresetModal = () => setIsSavePresetModalOpen(!isSavePresetModalOpen);

  const [contextMenu, setContextMenu] = useState<ContextMenuState>({
    visible: false,
    x: 0,
    y: 0,
    renameFunction: () => { },
    deleteFunction: () => { }
  });

  const fetchData = async () => {
    localStorage.removeItem("folderName");
    const storedFolderNames = Object.keys(localStorage)
      .filter((key) => key.startsWith("folderName"))
      .map((key) => localStorage.getItem(key) || "");
    setStoredFolders(storedFolderNames);

    try {
      const response = await axios.get(services.GET_PRESETS);
      const { data } = response.data;

      if (response.data.api_status === 'success') {
        const processedData = processDates(data);
        setData(processedData);
      } else {
        console.error('API error:', data.message);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const contextMenuRef = useRef<HTMLDivElement>(null);
  useClickOutside({
    ref: contextMenuRef,
    handler: () => setContextMenu({ ...contextMenu, visible: false }),
    condition: contextMenu.visible,
  });

  const togglePreset = () => setIsPresetOpen(!isPresetOpen);

  const createNewDocument = (uuid: string, folderTitle: string, presetItem?: any) => ({
    uuid,
    folder_name: folderTitle,
    user_id: presetItem ? presetItem.user_id : "sanjay",
  });
  
  
  const handleInputKeyPressCreateButton = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") return;
    else {
      const uuid = uuid_v4();
      const newDocument = createNewDocument(uuid, folderTitle);
      axios.post(`${services.GET_PRESETS}`, [newDocument]);
      setCreatingFolder(false);
      await fetchData();
    }
  };
  
  const handleInputBlur = async () => {
    const finalFolderTitle = folderTitle.trim() || DEFAULT_FOLDER_NAME;
    const uuid = uuid_v4();
    const newDocument = createNewDocument(uuid, finalFolderTitle);
    axios.post(`${services.GET_PRESETS}`, [newDocument]);
    setCreatingFolder(false);
    await fetchData();
  };
  

  const handleRenamePreset = (docId: string) => {
    setRenamingDocId(docId);
    const currentName = data
      .flatMap((item) => item.documents)
      .find((doc) => doc.uuid === docId)?.name || ""
    setNewName(currentName);
    setContextMenu({ ...contextMenu, visible: false });
  };

  const handleRenameFolder = (folderId: string, folderName: string) => {
    setRenamingFolderId(folderId);
    const matchingFolder = data.find((item) => item._id === folderName);
  
    if (matchingFolder) {
      const uuids = matchingFolder.documents.map((doc) => doc.uuid).join(",");
      console.log("Matching UUIDs: ", uuids);
      setRenameUuids(uuids)
      setNewNameFolder(folderName);
    } else {
      console.log("No matching folder found");
    }
    setContextMenu({ ...contextMenu, visible: false });
  };
  
  const handleRenamePresetEvent = async (
    e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>,
    docId: string,
  ) => {
    if ((e.type === "keypress" && (e as React.KeyboardEvent).key !== "Enter") || newName.trim() === "") {
      return;
    }
    const check = await renameDocument(docId, newName);
    if (!check) return;
    setNewName("")
    setRenamingDocId(null);
    await fetchData();
  };

  const handleRenameFolderEvent = async (
    e: React.KeyboardEvent<HTMLInputElement>,
    folderId: string,
  ) => {
    if ((e.type === "keypress" && (e as React.KeyboardEvent).key !== "Enter") || newNameFolder.trim() === "") {
      return;
    }
    const check = await renameDocumentFolder(folderId, newNameFolder, renameUuids);
    console.log("folderId", folderId)
    if (!check) return;
    setRenamingFolderId(null);
    setNewNameFolder("");
    await fetchData();
  };

  const handleRightClick = (
    event: React.MouseEvent<HTMLParagraphElement, MouseEvent>,
    docId: string,
  ) => updateContextMenu(event, setContextMenu, { renameFunction: () => handleRenamePreset(docId), deleteFunction: () => handleDelete(docId) });

  const handleFolderRightClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    folderId: string,
    folderName: string,
  ) => updateContextMenu(event, setContextMenu, { renameFunction: () => handleRenameFolder(folderId, folderName), deleteFunction: () => handleDeleteFolder(folderId, folderName) });

  const handleDelete = async (docId: string) => {
    setContextMenu({ ...contextMenu, visible: false });
    if (docId) {
      try {
        const folderUUIDdelete = data.find((item) =>
          item.documents.some((doc) => doc.uuid === docId),
        )?._id;
        await axios.delete(`${services.GET_PRESETS}/${docId || folderUUIDdelete}`);
        console.log("Document deleted:", docId);
        fetchData();
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  const handleDeleteFolder = async (folderId: string, folderName: string) => {
    setContextMenu({ ...contextMenu, visible: false });
    if (!folderId) return;

    const folderHasPresets = (data.find((item) => item._id === folderName)?.documents?.length || 0) > 1;
    console.log("check the folder its id or name: ", folderHasPresets)
    if (folderHasPresets) {
      toast.error(t("ERROR_DELETE_FOLDER"));
      return;
    }
    if (folderId) {
      try {
        const folderUUIDdelete = data.find((item) =>
          item.documents.some((doc) => doc.uuid === folderId),
        )?._id;
        await axios.delete(`${services.GET_PRESETS}/${folderId || folderUUIDdelete}`);
        console.log("Document deleted:", folderId);
        fetchData();
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  const renderFolders = () => {
    const folderMap = new Map<string, Document[]>();
    const presetsWithoutFolder: Document[] = [];

    if (!data || !Array.isArray(data)) {
      fetchData();
      return null;
    }
    data.forEach((item) => {
      if (!item._id) {
        item.documents.forEach((doc: Document) => {
          presetsWithoutFolder.push(doc);
        });
        return;
      }
      if (!folderMap.has(item._id)) {
        folderMap.set(item._id, []);
      }
      for (let i = 0; i < item.documents.length; i++) {
        const doc = item.documents[i];
        folderMap.get(item._id)?.push(doc);
      }
    });

    const sortedFolderEntries = Array.from(folderMap.entries()).sort((a, b) => {
      if (a[0] === "default") return -1;
      if (b[0] === "default") return 1;
      return 0;
    });

    //Favourites
    const presetsWithoutFolderContent = presetsWithoutFolder.map((doc, index) => (
      <Flex key={index} align={"center"}>
        {renamingDocId === doc.uuid ? (
          <TextInput
            autoFocus
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            onKeyPress={(e) => handleRenamePresetEvent(e, doc.uuid)}
            onBlur={(e) => handleRenamePresetEvent(e, doc.uuid)}
            w={200}
            h={28}
            radius={"md"}
            size={"xs"}
            mb="xs"
          />
        ) : (
          <Text
            size={theme.fontSizes.md}
            c={theme.colors.dark[8]}
            style={{ cursor: "pointer" }}
            onClick={() => handleDocumentClick(doc.uuid)}
            onContextMenu={(event) => handleRightClick(event, doc.uuid)}
            mb="xs"
            draggable={presetsWithoutFolder.length > 1}
            onDragStart={(event) => {
              event.dataTransfer.setData(
                "application/json",
                JSON.stringify(doc),
              );
            }}
          >
            {doc.name}
          </Text>
        )}
      </Flex>
    ));

    //Folders and Presets
    const foldersContent = sortedFolderEntries
      .sort(([folderNameA], [folderNameB]) => {
        if (!folderNameA || !folderNameB) return 0; 
        return folderNameA.localeCompare(folderNameB);
      })
      .map(([folderName, documents]) => {
        const folderUuid = documents.length > 0 ? documents[0].uuid : null;
        const sortedDocuments = documents.slice().sort((a, b) => {
          if (!a.name || !b.name) return 0; 
          return a.name.localeCompare(b.name);
        });

        return (
          <div key={folderName} className="folder-wrapper">
            <Accordion.Item key={folderName} value={folderName}  mb={'sm'}>
              <Accordion.Control chevron={customChevron(folderName)}>
                <Flex align={"center"} key={folderName} className="folder-wrapper">
                  {folderName !== "default" && (
                    <IconWrapper name="fund-query-preset-folder" className="allfundqueryicon" />
                  )}
                  {renamingFolderId === folderUuid ? (
                    <TextInput
                      autoFocus
                      value={newNameFolder}
                      onChange={(e) => setNewNameFolder(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter")
                          folderUuid && handleRenameFolderEvent(e, folderUuid);
                      }}
                      radius={"md"}
                      size={"xs"}
                      mb={theme.spacing.sm}
                      className="inputfolder"
                    />
                  ) : (
                    <Text
                      size={theme.fontSizes.md}
                      ml={"xs"}
                      c={theme.colors.dark[8]}
                      style={{ cursor: "pointer" }}
                      onDrop={handleDrop}
                      onDragOver={(e) => e.preventDefault()}
                      data-folder-name={folderName || newNameFolder}
                      onContextMenu={(event) =>
                        folderUuid && handleFolderRightClick(event, folderUuid, folderName)
                      }
                      draggable={documents.length > 1}
                      onDragStart={(event) => {
                        if (folderUuid) {
                          event.dataTransfer.setData(
                            "application/json",
                            JSON.stringify({ folderName, folderUuid })
                          );
                        }
                      }}
                    >
                      {folderName || newNameFolder}
                    </Text>
                  )}
                </Flex>
              </Accordion.Control>

              <Accordion.Panel>
                {sortedDocuments.map((doc, index) => (
                  <Flex key={index} align={"center"} mt='xs'>
                    {renamingDocId === doc.uuid ? (
                      <TextInput
                        autoFocus
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") handleRenamePresetEvent(e, doc.uuid);
                        }}
                        onBlur={(e) => handleRenamePresetEvent(e, doc.uuid)}
                        radius={"md"}
                        size={"xs"}
                        mb={theme.spacing.sm}
                        className="inputfolder"
                      />
                    ) : (
                      <Text
                        size={theme.fontSizes.md}
                        c={theme.colors.dark[8]}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDocumentClick(doc.uuid)}
                        onContextMenu={(event) => handleRightClick(event, doc.uuid)}
                        draggable={documents.length > 1}
                        onDragStart={(event) => {
                          event.dataTransfer.setData(
                            "application/json",
                            JSON.stringify(doc),
                          );
                        }}
                      >
                        {doc.name !== "" ? doc.name : null}
                      </Text>
                    )}
                  </Flex>
                ))}
              </Accordion.Panel>
            </Accordion.Item>
          </div>
        );
      });


    return (
      <Accordion radius="sm" chevronPosition="left" variant="filled" mt={"sm"} value={opened} onChange={setOpened}>
        {foldersContent.map((content, index) => (
          <React.Fragment key={index}>
            {content}
            <Box mb={3} />
          </React.Fragment>
        ))}
        {presetsWithoutFolderContent.length > 0 && (
          <div className="folder-wrapper">
            <Accordion.Item key="Favorites" value="Favorites" mt='-0.2rem'>
              <Accordion.Control mb="xs" chevron={customChevron('Favorites')}>
                <Flex align={"center"} mt={"0.2rem"}>
                  <IconWrapper name="favourites" className="allfundqueryicon"/>
                  <Text ml="xs" size={theme.fontSizes.md} c={theme.colors.dark[8]} pt="0.25rem" >
                    {t("FAVOURITES")}
                  </Text>
                </Flex>
              </Accordion.Control>
              <Accordion.Panel pt="0.5rem">{presetsWithoutFolderContent}</Accordion.Panel>
            </Accordion.Item>
          </div>
        )}
      </Accordion>
    );
  };

  const handleDrop = async (e: React.DragEvent<HTMLElement>) => {
    try {
      e.preventDefault();
      const draggedData = JSON.parse(e.dataTransfer.getData("application/json")) as Document;
      const folderName = e.currentTarget.getAttribute('data-folder-name')

      const newDocument: DocumentDrop = {
        uuid: draggedData.uuid,
        folder_name: folderName || ''
      };

      if (draggedData && typeof draggedData.uuid === "string") {
        await axios.post(`${services.GET_PRESETS}`, [newDocument]);
        fetchData();
      } else {
        console.error("Invalid UUID:", draggedData?.uuid);
      }
      setCreatingFolder(false);
    } catch (error) {
      console.error("Error creating folder from drop event:", error);
    }
  };

  const handleKeyDownEmpty = (event: { key: string }) =>
    handleEvent(event, newEmptyFolderName, "folderName", setEmptyNewFolderNameDelete);
  const handleBlurSaveEmpty = () =>
    saveRenameFolder(newEmptyFolderName, "folderName", setEmptyNewFolderNameDelete);
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  return (
    <Flex direction="row-reverse" className="preset-option2-container">
      <div className="preset-leftNav-hidden">

        <ActionIcon
          variant='transparent'
          c={theme.colors.grey[8]}
          onClick={isPresetOpen ? togglePreset : () => {
            setIsLeftNavOpen(!isLeftNavOpen);

          }}
        >
          {isPresetOpen ? <IconX size={"1.2rem"} /> : isLeftNavOpen ? <IconChevronLeft /> : <IconChevronRight />}
        </ActionIcon>
      </div>
      <Collapse in={isLeftNavOpen} className="preset-leftNav" transitionTimingFunction="ease" transitionDuration={100}>
        <Grid columns={totalGrid}>
          <Grid.Col span={leftGrid} style={{ textAlign: "right" }}>
            <ActionIcon
              variant='transparent'
              color={theme.colors.grey[8]}
              onClick={togglePreset}
            >
              <IconPaperclip size="1rem" />
            </ActionIcon>
          </Grid.Col>
          <Grid.Col span={centerGrid} style={{ alignSelf: "center" }}>
            <Text fw={"700"} size={theme.fontSizes.md} c={theme.colors.dark[8]} style={{ visibility: isPresetOpen ? "visible" : "hidden" }}>
              {t("PRESETS")}
            </Text>
          </Grid.Col>
          <Grid.Col span={rightGrid}>
            <ActionIcon
              variant='transparent'
              color={theme.colors.grey[8]}
              onClick={() => setCreatingFolder(true)}
              className="text-center"
              style={{ visibility: isPresetOpen ? "visible" : "hidden" }}
            >
              <IconWrapper name='create-folder-preset' className='allfundqueryicon' />
            </ActionIcon>
          </Grid.Col>
        </Grid>
        <Collapse key="presets" in={isPresetOpen} transitionTimingFunction={isPresetOpen ? "ease-in" : "ease-out"} className="presets-list">
          <Grid columns={totalGrid}>
            <Grid.Col span={leftGrid}></Grid.Col>
            <Grid.Col span={centerGrid + rightGrid}>
              <div className={"preset-search"}>
                <TextInput
                  h={28}
                  placeholder={`${t("SEARCH")} ${t("PRESETS")}`}
                  leftSection={<IconWrapper name='search' />}
                  radius={"md"}
                  fs={"13px"}
                  size={"xs"}
                />
              </div>
            </Grid.Col>
          </Grid>
          {creatingFolder && (
            <Grid columns={totalGrid}>
              <Grid.Col span={leftGrid}></Grid.Col>
              <Grid.Col span={centerGrid} className='folder-wrapper'>
                {renderFolders()}
                <Flex align="center">
                  <IconWrapper name='fund-query-preset-folder' className='allfundqueryicon' />
                  <TextInput
                    autoFocus
                    value={folderTitle}
                    onChange={(e) => setFolderTitle(e.target.value)}
                    onKeyPress={handleInputKeyPressCreateButton}
                    onBlur={handleInputBlur}
                    mt={"0.2rem"}
                    size={theme.fontSizes.md}
                    c={theme.colors.dark[8]}
                    placeholder={DEFAULT_FOLDER_NAME}
                    className='inputfolder'
                  />
                </Flex>
              </Grid.Col>
            </Grid>
          )}
          <Grid columns={totalGrid}>
            <Grid.Col span={leftGrid}></Grid.Col>
            <Grid.Col span={centerGrid + rightGrid}>
              {storedFolders.length > 0 &&
                storedFolders.map((folder, index) => (
                  <div key={index}>
                    <Flex
                      mt={"md"}
                      align={"center"}
                    >
                      <IconWrapper name='fund-query-preset-folder' className='allfundqueryicon' />
                      {newEmptyFolderNameDelete ? (
                        <TextInput
                          ref={inputRef}
                          ml={"0.2rem"}
                          size={theme.fontSizes.md}
                          c={theme.colors.dark[8]}
                          defaultValue={folder}
                          onChange={(event) => setNewEmptyFolderName(event.target.value)}
                          onKeyDown={handleKeyDownEmpty}
                          onBlur={handleBlurSaveEmpty}
                          className='inputfolder'
                          onFocus={handleFocus}
                          autoFocus
                        />
                      ) : (
                        <TextInput
                          ml={"0.2rem"}
                          size={theme.fontSizes.md}
                          c={theme.colors.dark[8]}
                          disabled
                          className='dropfolder'
                          placeholder={folder}
                          onDrop={handleDrop}
                          onDragOver={(event) => event.preventDefault()}
                          autoFocus
                        />
                      )}
                    </Flex>
                  </div>
                ))}

              {!creatingFolder && renderFolders()}
              <div ref={contextMenuRef}>
                <ContextMenu
                  x={contextMenu.x}
                  y={contextMenu.y}
                  visible={contextMenu.visible}
                  actionRename={contextMenu.renameFunction}
                  actionDelete={contextMenu.deleteFunction}
                  actionTextRename={t("RENAME")}
                  actionTextDelete={t("DELETE")}
                />
              </div>
            </Grid.Col>
          </Grid>
        </Collapse>
        <Collapse key="form" in={!isPresetOpen} transitionTimingFunction={isPresetOpen ? "ease-out" : "ease-in"}>
          <Grid columns={totalGrid} mt={'0.4rem'} pl={'xxs'}>
            <Grid.Col span={leftGrid / 3}></Grid.Col>
            <Grid.Col span={totalGrid - leftGrid / 3}>
              <QueryForm {...{
                fundGroup, setFundGroup,
                fundId, setFundId,
                assetIdType, setAssetIdType,
                assetId, setAssetId,
                selectedTypeOfData, setSelectedTypeOfData,
                settled, setSettled,
                total, setTotal,
                onLoan, setOnLoan,
                selectedFromDate, setSelectedFromDate,
                selectedToDate, setSelectedToDate,
                toggleSavePresetModal,
                handleSubmit,
                presetName, setPresetName,
                isSavePresetModalOpen, handleSave,
                selectedFolderName, setSelectedFolderName
              }} />
            </Grid.Col>
          </Grid>
        </Collapse>
      </Collapse>

      <ToastContainer position='top-right' autoClose={8000} />
    </Flex>
  );
}