import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Flex, Select, TextInput, Box } from '@mantine/core';
import '../Investment.css';
import CustomButton from '../../../../components/Common/Buttons/CustomButton';
import PageTitle from '../../../../components/Common/PageTitle';
import { t } from 'i18next';
import IconWrapper from '../../../../components/Common/IconWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import { EquityPayload, AssetWithKey, FXPayload, FuturesPayload, PayloadType } from '../../../../types';
import { dateToString, stringToDate } from '../../../../utils/dateUtils';
import { services } from '../../../../services';

const AssetForm: React.FC = () => {
    const [assetClass, setAssetClass] = useState<string | null>(null);
    const [instrument, setInstrument] = useState<string | null>(null);
    const [instrumentOptions, setInstrumentOptions] = useState<string[]>([]);
    const [autofillSuggestions, setAutofillSuggestions] = useState<string[]>([]);
    const [priceMultiplier, setPriceMultiplier] = useState<number | undefined>(undefined);
    const [assetName, setAssetName] = useState<string>('');
    const [currency, setCurrency] = useState<string>('');
    const [issueCountry, setIssueCountry] = useState<string>('');
    const [tradingCountry, setTradingCountry] = useState<string>('');
    const [riskCountry, setRiskCountry] = useState<string>('');
    const [sector, setSector] = useState<string>('');
    const [tradableAsset, setTradableAsset] = useState<string>('Yes');
    const [primaryExchange, setPrimaryExchange] = useState<string>('');
    const [parentAssetId, setParentAssetId] = useState<string>('');
    const [underlyingAssetId, setUnderlyingAssetId] = useState<string>('');
    const [ratioToUnderlying, setRatioToUnderlying] = useState<number | undefined>(undefined);
    const [equivalentVotingRights, setEquivalentVotingRights] = useState<number | undefined>(undefined);
    const [noticeDate, setNoticeDate] = useState<string>('');
    const [lastTradeDate, setLastTradeDate] = useState<string>('');
    const [expiryDate, setExpiryDate] = useState<string>('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const location = useLocation();
    const navigate = useNavigate();

    const formatInstrument = (instrument: string) => {
        const instrumentMapping: { [key: string]: string } = {
            STOCK: "Stock",
            ETF: "ETF",
            FUTURES: "Futures",
            FUTURES_ASSET_TYPE_UNSPECIFIED: "Futures",
            EQUITY_INDEX_FUTURES: "Futures",
            CURRENCY_FUTURES: "Futures",
            COMMODITY_FUTURES: "Futures",
            STOCK_FUTURES: "Futures",
            INTEREST_RATE_FUTURES: "Futures",
            VOLATILITY_FUTURES: "Futures",
            BOND_FUTURES: "Futures",
            BOND_INDEX_FUTURES: "Futures",
            SPOT: "Spot"
        };
        return instrumentMapping[instrument] || instrument;
    };

    const handleCancel = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (location.state && location.state.data) {
            const { data } = location.state;
            setCurrency(data.currency || '');
            const suggestions = ['USD', 'UGS', 'UAK', 'UAH', 'UYU'];
            if (!suggestions.includes(data.currency)) {
                suggestions.push(data.currency);
            }
            setAutofillSuggestions(suggestions);
        }
    }, [location.state]);

    useEffect(() => {
        if (location.state && location.state.data && location.state.data.asset_group === 'EQUITY') {
            const { data } = location.state;
            console.log("data", location.state.asset_group)
            setAssetClass('Equity');
            setInstrument(formatInstrument(data.equity.asset_type));
            setAssetName(data.asset_name);
            setCurrency(data.currency);
            setIssueCountry(data.issue_country);
            setTradingCountry(data.trading_country);
            setRiskCountry(data.risk_country);
            setSector(data.sector);
            setPrimaryExchange(data.equity.primary_exchange);
            setParentAssetId(data.equity.parent_asset_id);
            setUnderlyingAssetId(data.equity.underlying_asset_id);
            setPriceMultiplier(data.equity.price_multiplier);
            setRatioToUnderlying(data.equity.ratio_to_underlying_asset);
            setEquivalentVotingRights(data.equity.equivalent_voting_rights);
        }
        if (location.state && location.state.data && location.state.data.asset_group === 'FUTURES') {
            const { data } = location.state;
            console.log("date",dateToString(data.Futures.expiry_date))
            setAssetClass('Equity');
            setInstrument(formatInstrument(data.Futures.asset_type || ''));
            setAssetName(data.asset_name || '');
            setCurrency(data.currency || '');
            setIssueCountry(data.issue_country || '');
            setTradingCountry(data.trading_country || '');
            setRiskCountry(data.risk_country || '');
            setSector(data.sector || '');
            setPrimaryExchange(data.Futures.primary_exchange || '');
            setParentAssetId(data.Futures.parent_asset_id || '');
            setUnderlyingAssetId(data.Futures.underlying_asset_id || '');
            setPriceMultiplier(data.Futures.price_multiplier || '');
            setRatioToUnderlying(data.Futures.ratio_to_underlying_asset || '');
            setEquivalentVotingRights(data.Futures.equivalent_voting_rights || '');
            setNoticeDate(dateToString(data.Futures.notice_date) || '');
            setLastTradeDate(dateToString(data.Futures.last_trade_date) || '');
            setExpiryDate(dateToString(data.Futures.expiry_date) || '');
        }
        if (location.state && location.state.data && location.state.data.asset_group === 'CURRENCY') {
            const { data } = location.state;
            console.log("data.FX.asset_type:", data.FX.asset_type)
            setAssetClass('Currency');
            setInstrument(formatInstrument(data.FX.asset_type));
            setAssetName(data.asset_name || '');
            setCurrency(data.currency || '');
            setIssueCountry(data.issue_country || '');
            setTradingCountry(data.trading_country || '');
            setRiskCountry(data.risk_country || '');
            setPriceMultiplier(data.FX.price_multiplier || '');
        }
    }, [location.state]);

    const rightSection = <Box mt={'md'} mr={'sm'}><IconWrapper name='select-dropdown-large' /></Box>

    useEffect(() => {
        if (location.state && location.state.data) {
            const { data } = location.state;

            let formattedInstrument = '';
            if (data.asset_group === 'EQUITY') {
                formattedInstrument = formatInstrument(data.equity.asset_type || '');
            } else if (data.asset_group === 'FUTURES') {
                formattedInstrument = formatInstrument(data.Futures.asset_type || '');
            }

            const options = getInstrumentOptionsBasedOnAssetClass(data.asset_group);
            setInstrumentOptions(options);

            if (options.includes(formattedInstrument)) {
                setInstrument(formattedInstrument);
            } else {
                setInstrument(null);
            }
        }
    }, [location.state]);

    useEffect(() => {
        if (location.state && location.state.data && location.state.data.asset_group === 'CURRENCY') {
            const { data } = location.state;
            console.log("data.FX.asset_type:", data.FX.asset_type);

            setInstrument(formatInstrument(data.FX.asset_type));
        }
    }, [location.state]);

    const getInstrumentOptionsBasedOnAssetClass = (assetGroup: string) => {
        switch (assetGroup.toLowerCase()) {
            case 'equity':
                return ['Stock', 'ETF', 'Futures'];
            case 'futures':
                return ['Futures'];
            case 'currency':
                return ['Spot'];
            default:
                return [];
        }
    };

    const handleAssetClassChange = (value: string | null) => {
        setAssetClass(value);
        if (value) {
            const options = getInstrumentOptionsBasedOnAssetClass(value);
            setInstrumentOptions(options);
            setInstrument((prev) => options.includes(prev!) ? prev : null);
        } else {
            setInstrumentOptions([]);
            setInstrument(null);
        }
    };

    const handleDateChange = (value: string, field: string) => {
        const dateFormatRegex = /^(0[1-9]|1[0-2])\/([0-2][0-9]|3[01])\/\d{4}$/;
        if (field === 'noticeDate') setNoticeDate(value);
        if (field === 'lastTradeDate') setLastTradeDate(value);
        if (field === 'expiryDate') setExpiryDate(value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: value && !dateFormatRegex.test(value) ? 'Please match the format mm/dd/yyyy' : '',
        }));
    };
    const handleAutofillChange = (input: string) => {
        const suggestions = input
            ? ['USD', 'UGS', 'UAK', 'UAH', 'UYU'].filter((code) =>
                code.startsWith(input)
            )
            : [];
        setAutofillSuggestions(suggestions);
    };

    const handleSubmit = async () => {
        const normalizedInstrument = instrument?.trim() || "";
        const normalizedAssetClass = assetClass?.trim();
        console.log('Asset Class:', assetClass);
        console.log('Instrument:', instrument);

        const assetId = location.state?.data?.asset_key?.asset_id; // If editing, get the asset_id for editing
        console.log("assetId", assetId)
        const assetKey = location.state?.data?.asset_key

        let payloadEditKey: string | undefined;
        let payloadEditValue: string | undefined;

        if (assetKey) {
            const assetKeyEntries = Object.entries(assetKey);  // Correctly extract entries from location.state
            if (assetKeyEntries.length > 0) {
                [payloadEditKey, payloadEditValue] = assetKeyEntries[0] as [string, string]; // Get key-value pair from asset_key
            }
        }

        // Common Payload
        const commonPayload = {
            asset_name: assetName,
            currency,
            issue_country: issueCountry,
            trading_country: tradingCountry,
            risk_country: riskCountry,
            sector,
            ...(assetKey && { asset_key: assetKey }), // Include asset_key only if it exists
        };

        // Case 1: Equity with Stock or Preferred Stock
        if (normalizedAssetClass === 'Equity' && ['Stock'].includes(normalizedInstrument)) {
            const assetType = normalizedInstrument === 'Stock' ? 'STOCK' : 'ETF';
            const equityPayload: EquityPayload & AssetWithKey = {
                equity: {
                    asset_type: assetType,
                    primary_exchange: primaryExchange,
                    parent_asset_id: parentAssetId,
                    underlying_asset_id: underlyingAssetId,
                    ratio_to_underlying_asset: ratioToUnderlying,
                    equivalent_voting_rights: equivalentVotingRights,
                    price_multiplier: priceMultiplier,
                },
                asset_group: 'EQUITY',
                ...commonPayload,
            };

            handleApiCall(equityPayload);
        }

        // Case 2: Currency with Spot
        else if (normalizedAssetClass === 'Currency' && normalizedInstrument === 'Spot') {
            const fxPayload: FXPayload & AssetWithKey = {
                FX: {
                    asset_type: 'SPOT',
                    price_multiplier: priceMultiplier,
                },
                asset_group: 'CURRENCY',
                ...commonPayload,
            };

            handleApiCall(fxPayload);
        }

        // Case 3: Equity with Futures
        else if (normalizedAssetClass === 'Equity' && instrument === 'Futures') {
            const futuresPayload: FuturesPayload & AssetWithKey = {
                Futures: {
                    asset_type: 'EQUITY_INDEX_FUTURES',
                    price_multiplier: priceMultiplier,
                    notice_date: stringToDate(noticeDate),
                    last_trade_date: stringToDate(lastTradeDate),
                    expiry_date: stringToDate(expiryDate),
                    underlying_asset_id: underlyingAssetId,
                },
                asset_group: 'FUTURES',
                ...commonPayload,
            };

            handleApiCall(futuresPayload);
        }

        // Case 4: Equity with ETF
        else if (normalizedAssetClass === 'Equity' && instrument === 'ETF') {
            const etfPayload: EquityPayload & AssetWithKey = {
                equity: {
                    asset_type: 'ETF',
                    price_multiplier: priceMultiplier,
                },
                asset_group: 'EQUITY',
                ...commonPayload,
            };

            handleApiCall(etfPayload);
        }

        else {
            console.error('Invalid asset class or instrument. No API call will be made.');
        }

        async function handleApiCall(payload: PayloadType) {
            try {
                const apiUrl = payloadEditKey && payloadEditValue
                    ? `${services.ASSET_INVESTMENT}?asset_id=${payloadEditValue}&asset_id_type=${payloadEditKey}&created_by=CREATED_BY_USER`
                    : `${services.ASSET_INVESTMENT}?created_by=CREATED_BY_USER`;

                const response = await axios.post(apiUrl, [payload]);
                console.log('API Response:', response.data);
                navigate(-1)
            } catch (error) {
                console.error('API Error:', error);
            }
        }
    };

    return (
        <Box>
            <PageTitle
                TITLE={location.state && location.state.data ? 'EDIT_ASSET' : 'CREATE_NEW_ASSET'}
                import_asset={location.state && location.state.data? '': 'IMPORT' }
                back
            />
            <Flex justify="center" mt={'1.3rem'} gap="xl">
                <Flex direction="column" gap="md" className="left-section">
                    <Select
                        label={t('ASSET_CLASS')}
                        data={['Equity', 'Currency']}
                        value={assetClass}
                        onChange={handleAssetClassChange}
                        className="asset-input"
                        rightSection={rightSection}
                        comboboxProps={{ offset: 0 }}
                        checkIconPosition="right"
                        rightSectionPointerEvents="none"
                    />
                    <Select
                        label={t('INSTRUMENT')}
                        data={instrumentOptions}
                        value={instrument}
                        onChange={(value) => setInstrument(value)}
                        disabled={!instrumentOptions.length}
                        rightSection={rightSection}
                        comboboxProps={{ offset: 0 }}
                        checkIconPosition="right"
                        rightSectionPointerEvents="none"
                        className="asset-input"
                    />
                    <TextInput
                        label={t('ASSET_NAME')}
                        value={assetName}
                        onChange={(e) => setAssetName(e.target.value)}
                        className="asset-input"
                    />
                    <Select
                        label={t('CURRENY')}
                        data={autofillSuggestions} // Ensure this list is correctly populated
                        searchable
                        value={currency}  // Correctly bound to `currency` state
                        onSearchChange={handleAutofillChange}
                        onChange={(value) => setCurrency(value || '')}
                        className="asset-input"
                        rightSection={rightSection}
                        comboboxProps={{ offset: 0 }}
                        checkIconPosition="right"
                        rightSectionPointerEvents="none"
                    />

                    <TextInput
                        label={t('ISSUE_COUNTRY')}
                        value={issueCountry}
                        onChange={(e) => setIssueCountry(e.target.value)}
                        className="asset-input"
                    />
                    <TextInput
                        label={t('TRADING_COUNTRY')}
                        value={tradingCountry}
                        onChange={(e) => setTradingCountry(e.target.value)}
                        className="asset-input"
                    />
                    <TextInput
                        label={t('RISK_COUNTRY')}
                        value={riskCountry}
                        onChange={(e) => setRiskCountry(e.target.value)}
                        className="asset-input"
                    />
                    <TextInput
                        label={t('PRICE_MULTIPLIER')}
                        value={priceMultiplier || ''}
                        min={1}
                        onChange={(e) => setPriceMultiplier(Number(e.target.value) || undefined)} // Convert string to number
                        className="asset-input"
                    />
                </Flex>
                {instrument === 'Stock' && (
                    <Flex direction="column" gap="md" className="right-section right-section-margin">
                        <Select
                            label={t('TRADABLE_ASSET')}
                            data={['Yes', 'No']}
                            value={tradableAsset}
                            onChange={(value) => setTradableAsset(value || 'Yes')}
                            rightSection={rightSection}
                            comboboxProps={{ offset: 0 }}
                            checkIconPosition="right"
                            rightSectionPointerEvents="none"
                            className="asset-input"
                        />
                        <TextInput
                            label={t('PRIMARY_EXCHANGE')}
                            value={primaryExchange}
                            onChange={(e) => setPrimaryExchange(e.target.value)}
                            className="asset-input"
                        />
                        <TextInput
                            label={t('SECTOR')}
                            value={sector}
                            onChange={(e) => setSector(e.target.value)}
                            className="asset-input"
                        />
                        <TextInput
                            label={t('PARENT_ASSET_ID')}
                            value={parentAssetId}
                            onChange={(e) => setParentAssetId(e.target.value)}
                            className="asset-input"
                        />
                        <TextInput
                            label={t('UNDERLYING_ASSET_ID')}
                            value={underlyingAssetId}
                            onChange={(e) => setUnderlyingAssetId(e.target.value)}
                            className="asset-input"
                        />
                        <TextInput
                            label={t('RATIO_TO_UNDERLYING_ASSET')}
                            value={ratioToUnderlying}
                            min={0}
                            onChange={(e) => setRatioToUnderlying(Number(e.target.value) || undefined)}
                            className="asset-input"
                        />
                        <TextInput
                            label={t('EQUIVALENT_VOTING_RIGHTS')}
                            value={equivalentVotingRights}
                            min={0}
                            onChange={(e) => setEquivalentVotingRights(Number(e.target.value || undefined))}
                            className="asset-input"
                        />
                    </Flex>
                )}
                {instrument === 'Futures' && (
                    <Flex direction="column" gap="md" className="right-section right-section-margin">
                        <TextInput
                            label={t('NOTICE_DATE')}
                            placeholder="mm/dd/yyyy"
                            value={noticeDate}
                            onChange={(e) => handleDateChange(e.target.value, 'noticeDate')}
                            className="investment-search"
                        />
                        {errors.noticeDate && (
                            <span className='error-message-fields'>
                                {errors.noticeDate}
                            </span>
                        )}
                        <TextInput
                            label={t('LAST_TRADE_DATE')}
                            placeholder="mm/dd/yyyy"
                            value={lastTradeDate}
                            onChange={(e) => handleDateChange(e.target.value, 'lastTradeDate')}
                            className="investment-search"
                        />
                        {errors.lastTradeDate && (
                            <span className='error-message-fields'>
                                {errors.lastTradeDate}
                            </span>
                        )}

                        <TextInput
                            label={t('EXPIRY_TRADE_DATE')}
                            placeholder="mm/dd/yyyy"
                            value={expiryDate}
                            onChange={(e) => handleDateChange(e.target.value, 'expiryDate')}
                            className="investment-search"
                        />
                        {errors.expiryDate && (
                            <span className='error-message-fields'>
                                {errors.expiryDate}
                            </span>
                        )}

                        <TextInput
                            label={t('UNDERLYING_ASSET_ID')}
                            value={underlyingAssetId}
                            onChange={(e) => setUnderlyingAssetId(e.target.value)}
                            className="investment-search"
                        />
                    </Flex>
                )}
            </Flex>
            <Flex
                gap="sm"
                justify="center"
                className={instrument === 'Stock' ? 'button-shift-right-section' : 'button-shift-left-section'}
            >
                <CustomButton variant="cancel" children="CANCEL" onClick={handleCancel} />
                <CustomButton
                    variant="create-asset"
                    onClick={handleSubmit}
                    children={location.state && location.state.data ? 'UPDATE': 'CREATE'}
                />
            </Flex>
        </Box>
    );
};

export default AssetForm;