import React, { useState } from 'react';
import { Modal, Text, Flex, useMantineTheme, Center } from '@mantine/core';
import CustomButton from '../Common/Buttons/CustomButton';
import { t } from 'i18next';
import { Dropzone } from '@mantine/dropzone';
import Papa from 'papaparse';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import IconWrapper from './IconWrapper';
import { services } from '../../services';

interface ImportModalProps {
    opened: boolean;
    onClose: () => void;
    TITLE: string;
    centered?: boolean;
    withCloseButton?: boolean;
    radius?: string;
}

const ImportModal: React.FC<ImportModalProps> = ({
    opened,
    onClose,
    TITLE,
    centered = true,
    withCloseButton = false,
    radius = '1.875rem',
}) => {
    const [files, setFiles] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState<'idle' | 'uploading' | 'success' | 'error'>('idle');
    const theme = useMantineTheme();
    const navigate = useNavigate();

    const handleDrop = (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (file) {
            setUploadStatus('uploading');
            setFiles([file]);

            setTimeout(() => {
                setUploadStatus('success');
            }, 1000);
        } else {
            setUploadStatus('error');
        }
    };

    const parseCSV = (file: File) => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                header: true,
                complete: (results) => resolve(results.data),
                error: (error) => reject(error),
            });
        });
    };

    const handleSubmit = async () => {
        if (files.length === 0) return;

        try {
            setLoading(true);
            setUploadStatus('uploading');
            const data: any[] = await parseCSV(files[0]) as any[];

            // Process each row in CSV
            for (const row of data) {
                const normalizedAssetClass = row['Asset Class']?.trim();
                const normalizedInstrument = row['Instrument']?.trim();

                const commonPayload = {
                    asset_name: row['Asset Name'],
                    currency: row['Currency'],
                    issue_country: row['Issue Country'],
                    trading_country: row['Trading Country'],
                    risk_country: row['Risk Country'],
                    sector: row['Sector'],
                };

                let payload = {};

                if (row['fund_structure'] && row['forty_act_fund']) {
                    payload = {
                        fund_name: row['fund_name'] || 'Acumain S&P 500 ETF',
                        primary_fund_manager: row['primary_fund_manager'] || 'sanjay',
                        fund_currency: row['fund_currency'] || 'USD',
                        fund_domicile: row['fund_domicile'] || 'US',
                        legal_jurisdiction: row['legal_jurisdiction'] || 'United States',
                        benchmark_id: row['benchmark_id'] || 'SP500',
                        fund_structure: row['fund_structure'],
                        fund_id: row['fund_id'] || 'ACU_0002',
                        fund_group: row['fund_group'] || 'All-Index',
                        fund_managers: [""],
                        valuation_type: row['valuation_type'] || 'MARKET_ON_CLOSE',
                        optimal_futures_basket_id: row['optimal_futures_basket_id'] || 'FUT_SP500_A',
                        forty_act_fund: row['forty_act_fund'] === 'true', 
                        qualified_institutional_investor: false,
                        qualified_sophisticated_investor: false,
                        eligible_under_rule_144a: false,
                        parent_fund_id: row['parent_fund_id'] || '',
                        market_on_open_activity_allowed: false,
                    };
    
                    // API call for this new payload
                    await axios.post('/api/fund-attributes', [payload]);
                }

                // Case 1: Equity with Stock
                if (normalizedAssetClass === 'Equity' && ['Stock'].includes(normalizedInstrument)) {
                    const assetType = normalizedInstrument === 'Stock' ? 'STOCK' : 'ETF';
                    payload = {
                        equity: {
                            asset_type: assetType,
                            primary_exchange: row['Primary Exchange'],
                            parent_asset_id: row['Parent Asset Id'],
                            underlying_asset_id: row['Underlying Asset Id'],
                            ratio_to_underlying_asset: row['Ratio to Underlying Asset'],
                            equivalent_voting_rights: row['Equivalent Voting Rights'],
                            price_multiplier: row['Price Multiplier'],
                        },
                        asset_group: 'EQUITY',
                        ...commonPayload,
                    };
                }
                // Case 2: Currency with Spot
                if (normalizedAssetClass === 'Currency' && normalizedInstrument === 'Spot') {
                    payload = {
                        FX: {
                            asset_type: 'SPOT',
                            price_multiplier: row['Price Multiplier'],
                        },
                        asset_group: 'CURRENCY',
                        ...commonPayload,
                    };
                }
                // Case 3: Equity with Futures
                if (normalizedAssetClass === 'Equity' && normalizedInstrument === 'Futures') {
                    payload = {
                        Futures: {
                            asset_type: 'EQUITY_INDEX_FUTURES',
                            price_multiplier: row['Price Multiplier'],
                        },
                        asset_group: 'FUTURES',
                        ...commonPayload,
                    };
                }
                // Case 4: Equity with ETF
                if (normalizedAssetClass === 'Equity' && normalizedInstrument === 'ETF') {
                    payload = {
                        equity: {
                            asset_type: 'ETF',
                            price_multiplier: row['Price Multiplier'],
                        },
                        asset_group: 'EQUITY',
                        ...commonPayload,
                    };
                }
                await axios.post(`${services.ASSET_INVESTMENT}?created_by=CREATED_BY_USER`, [payload]);
            }

            setUploadStatus('success');
            onClose();
            navigate(-1);

        } catch (error) {
            console.error("Error processing CSV:", error);
            setUploadStatus('error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            centered={centered}
            withCloseButton={withCloseButton}
            radius={radius}
            className='import-modal'
            size={'29.375rem'}
        >
            <Text ta={'center'} size='lg' fw={700} mt={"md"}>
                {t(TITLE)}
            </Text>

            <Dropzone
                onDrop={handleDrop}
                className='asset-dropzone'
            >
                {uploadStatus === 'uploading' && (
                    <Flex justify={'center'} mt={'-0.8rem'}>
                        <IconWrapper name='loading-small' className='import-loader' />
                    </Flex>
                )}

                {uploadStatus === 'idle' && (
                    <Text ta="center" c={theme.colors.grey[5]}>
                        {t("DROPZONE_MESSAGE")}
                    </Text>
                )}

                {uploadStatus === 'error' && (
                    <Text ta="center" c={theme.colors.red[0]}>
                        {t("DROPZONE_ERROR_MESSAGE")}
                    </Text>
                )}

                {uploadStatus === 'success' && (
                    <Text ta="center" c={theme.colors.grey[5]}>
                        {t("DROPZONE_MESSAGE_SUCCESS")}
                    </Text>
                )}
            </Dropzone>

            <Flex justify="center" align="center" mt="2.1rem" mr={'xxl'} mb={"2.2rem"}>
                <CustomButton variant='cancel' onClick={onClose}>
                    {t("CANCEL")}
                </CustomButton>
                <CustomButton variant='submit' onClick={handleSubmit} disabled={files.length === 0 || loading}>
                    {t("IMPORT")}
                </CustomButton>
            </Flex>
        </Modal>
    );
};

export default ImportModal;
