import {
  KeyValuePairs,
  valueDecorators,
  TableRowProps,
  PartialSystemConfigProps,
  SystemConfigProps,
} from "../types";
const tableConfig = require("../systemConfig/tableConfig.json");
const systemConfig = require("../systemConfig/config.json");

export const amountToLocalizaedString = (amount: number) => {
  return amount.toString();
};

export const convertToFormData = (input: KeyValuePairs): FormData => {
  let formData: FormData = new FormData();
  for (let key in input) {
    formData.append(key, input[key])
  }
  return formData
}

export const convertToCamelCase = (str: string) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return word.toUpperCase();
  });
}

export const decorateValue = (value: string, decorator: valueDecorators) : string => {
  switch(decorator) {
    case "currency_3_coma":
      const [valueBeforeDot, valueAfterDot] = value.split(".");
      return valueBeforeDot.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + `${valueAfterDot ? '.' + valueAfterDot : ''}`;
    case "decimal_2":
      return parseFloat(value).toFixed(2);
    case "decimal_3":
      return parseFloat(value).toFixed(3);
    case "percentage":
      return value + "%";
    case "currency_in_million": 
      const amount = +value;
      if (Math.abs(amount) >= 10000) {
        // If amount is in millions
        return `$${(amount / 1000000).toFixed(2)}M`;
      } else if (Math.abs(amount) >= 1000) {
        // If amount is in thousands
        return `$${(amount / 1000).toFixed(2)}K`;
      }
      // If amount is normal
      return `$${amount.toString()}`;
    default:
      return value;
  }
}

export const applyDecorators = (value: any, decorators: valueDecorators[] | undefined | null): string => {
  if(!value && value !== 0) return '';
  if(!decorators) return value;
  const formatOrder: valueDecorators[] = ['decimal_2', 'decimal_3', 'percentage', 'currency_3_coma', 'currency_in_million'];
  formatOrder.filter(key => decorators.includes(key)).forEach(decorate => {
    value = decorateValue(value.toString(), decorate)
  });
  return value;
};

export const getTableData = (table_name: string): TableRowProps[] => {
  return tableConfig ? tableConfig
  .filter((row: any) => (row.table_name === table_name && row.visible === "true" ))
  .sort((a: any, b: any) => a.sort - b.sort)
  .map((row: any) => ({
    colHeader: row.key,
    key: row.api_key,
    decorators: row.decorators,
  })) : [];
}

export const getConfig = (level: string) => {
  let configToReturn:{ [key:string]: PartialSystemConfigProps } = {};
  systemConfig &&  systemConfig
    .filter((row: SystemConfigProps) => row.level === level)
    .forEach((row: SystemConfigProps) => {
      const {key, level, ...rest} = row;
      configToReturn[key] = {...rest}
    });

  return configToReturn;
}

export function calculatePercentage(numerator: number, denominator: number): string {
  if (denominator === 0) {
    return "0.00%";
  }
  return `${((numerator / denominator) * 100).toFixed(2)}%`;
}


export function directPercentage(num: number): string {
  if (num === 0) {
    return "0.00%";
  }
  return `${(num * 100).toFixed(2)}%`;
}

export function getToleranceSymbol(
  violated: number,
  upper: number,
  lower: number
): string {
  if (violated === upper) {
    return ">";
  } else if (violated === lower) {
    return "<";
  } else {
    return "";
  }
}

export function formatLabelsNavbar(input: string): string {
  return input
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
}

export const customHeight = (rowData: any[] = []): string => {
  if (rowData.length > 1) {
      const heightInRem = 3.2 + (rowData.length * 2.5);
      return `${heightInRem}rem`;
  } else {
      return "5.68rem";
  }
};
