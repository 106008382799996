import { routes } from "../routes";

export const cards = [
	{ param: "market_on_open_counters", title: "MARKET_ON_OPEN", route: routes.MARKET_ON_OPEN },
	{ param: "market_counters", title: "Market", route: routes.MARKET },
	{
		param: "market_on_close_counters",
		title: "MARKET_ON_CLOSE",
		route: routes.MARKET_ON_CLOSE,
	},
	{
		param: "market_on_close_future_days",
		title: "MARKET_ON_CLOSE_PLUS_DAYS",
		route: routes.MARKET_FUTURE_DAYS,
	},
];
