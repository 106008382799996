import React, { useState, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import { t } from "i18next";
import { Box, Flex } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import AdminTableHeader from "../../../../components/AdminPage/AdminTableHeader";
import IconWrapper from "../../../../components/Common/IconWrapper";
import { routes } from "../../../../routes";
import { services } from "../../../../services";
import { FundPayload, valueDecorators } from "../../../../types";
import { getTableData, applyDecorators } from "../../../../utils/conversions";
import '../Investment.css';
import DeleteModalUser from "../../../../components/AdminPage/DeleteModalUser";

const FundsInvestment: React.FC = () => {
	const [rowData, setRowData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [editRowData, setEditRowData] = useState({});
	const [opened, setOpened] = useState(false);

	const navigate = useNavigate();

	const navigateToCreateFundPage = () => {
		navigate(routes.CREATE_FUND);
	};
	const handleRowSelection = (event: any) => {
		setEditRowData(() => event.api.getSelectedRows()[0]);
	};
	const handleEditFund = (editRowData: FundPayload) => {
		navigate(`${routes.CREATE_FUND}?fundId=${editRowData.fund_id}`);
	};
	const handleDeleteConfirm = async (editRowData: FundPayload) => {
		try {
			const response = await axios.delete(`${services.FUND_ATTRIBUTES}/${editRowData.fund_id}`);

			if (response.status === 200) {
				console.log("Fund deleted successfully");
				setOpened(false)
				fetchData();
			}
		} catch (error) {
			console.error('Error deleting fund attribute:', error);
		}
	};

	const fetchData = useCallback(() => {
		setLoading(true);
		axios
			.get(services.FUND_ATTRIBUTES)
			.then((response) => {
				setRowData(response.data.data);
				setLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const columnDefs: ColDef[] = useMemo(() => {
		const tableData = getTableData("FundsInvestment");
		const columns = [
			{
				headerName: t("SR_NO"),
				field: "srNo",
				width: 100,
				valueGetter: (params: ValueGetterParams) =>
					params.node?.rowIndex != null ? params.node.rowIndex + 1 : "",
				cellStyle: { textAlign: "center" },
			},
			...tableData.map(({ colHeader, key, decorators }) => ({
				headerName: t(colHeader),
				field: key,
				cellRenderer: (params: { value: any }) =>
					applyDecorators(params.value, decorators as valueDecorators[]),
			})),
		];
		return columns;
	}, []);

	const customHeight = () => {
		if (rowData.length > 1) {
			const heightInRem = 1.876 + rowData.length * 1.9;
			return `${heightInRem}rem`;
		}
		return "4.4rem";
	};

	const handleDeleteClick = () => {
		if (editRowData) {
			setOpened(true);
		}
	};


	return (
		<Box>
			<Box p={"lg"} mr={"-1.7rem"}>
				<AdminTableHeader
					isRowSelected={Object.keys(editRowData).length > 0}
					headerTitle='show_search'
					createTitle='CREATE_A_FUND'
					handleEditUser={() => handleEditFund(editRowData as FundPayload)}
					handleDeleteClick={handleDeleteClick}
					resetCreateUser={() => navigateToCreateFundPage()}
				/>
			</Box>
			<div
				className='fund-query ag-theme-quartz'
				style={{
					height: customHeight(),
					width: "155vh",
					minHeight: "4.4rem",
					margin: "auto",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}}
			>
				{loading ? (
					<Flex align={'center'} justify={'center'}>
						<IconWrapper name='loading-large' />
					</Flex>
				) : (
					<AgGridReact
						rowData={rowData}
						columnDefs={columnDefs}
						suppressRowHoverHighlight
						rowHeight={30}
						defaultColDef={{
							sortable: true,
							filter: true,
							resizable: true,
						}}
						rowSelection='single'
						onSelectionChanged={(event) => handleRowSelection(event)}
					/>
				)}

				<DeleteModalUser
					opened={opened}
					onClose={() => setOpened(false)}
					modalType="delete"
					TITLE="DELETE_FUND"
					message="DELETE_FUND_MESSAGE"
					onSubmit={() => handleDeleteConfirm(editRowData as FundPayload)}
				/>
			</div>
		</Box>
	);
};

export default FundsInvestment;
