import { createSlice, createAsyncThunk, Dispatch, UnknownAction } from "@reduxjs/toolkit";
import axios from "axios";
import { LoginAuthBody, User } from "../types";
import { services } from "../services";
import { jwtDecode } from "jwt-decode";
import { RootState } from "./store";
import { NavigateFunction } from "react-router-dom";
import { isAdmin } from "../utils/validations";
import { routes } from "../routes";

interface UserState {
	loading: boolean;
	authenticated: boolean;
	user: User | null;
	error: boolean;
	errorMessage: string;
	loginCounter: number;
	fundGroup: string | null;
	permissions: string[] | null;
	workItemsLength: number;
}

interface LoginPayload {
	username: string;
	password: string;
	navigate: NavigateFunction;
	//pathname: string;
}

const initialState: UserState = {
	loading: false,
	authenticated: false,
	user: null,
	error: false,
	errorMessage: '',
	loginCounter: 0,
	fundGroup: null,
	permissions: null,
	workItemsLength: 0,
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload;
			state.authenticated = true;
			state.loading = false;
			state.fundGroup = action.payload.fundGroup;
			state.permissions = action.payload.permissions;
		},
		clearUser: (state) => {
			state.user = null;
			state.authenticated = false;
		},
		setError: (state, action) => {
			state.error = true;
			state.errorMessage = action.payload;
			state.loading = false;
		},
		clearError: (state) => {
			state.error = false;
			state.errorMessage = "";
		},
		startLoading: (state) => {
			state.loading = true;
		},
		stopLoading: (state) => {
			state.loading = false;
		},
		resetLoginCounter: (state) => {
			state.loginCounter = 0;
		},
		increaseLoginCounter: (state) => {
			state.loginCounter++;
		},
		setWorkItemsLength: (state, action) => {
			state.workItemsLength = action.payload;
		},
	},
});

export const {
	setUser,
	clearUser,
	setError,
	clearError,
	startLoading,
	stopLoading,
	resetLoginCounter,
	increaseLoginCounter,
	setWorkItemsLength,
} = userSlice.actions;

export const login = createAsyncThunk(
	'user/login',
	async (payload: LoginPayload,
		{ dispatch, getState }) => {
		try {
			dispatch(startLoading());
			dispatch(clearError());
			const { username, password, navigate } = payload;
			const newAxios = axios.create();
			const body: LoginAuthBody = {
				client_id: process.env.REACT_APP_CLIENT_ID!,
				client_secret: process.env.REACT_APP_CLIENT_SECRET!,
				grant_type: "password",
				username,
				password,
			};
			const res = await newAxios
				.post(services.GET_TOKENS, body, {
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
					baseURL: "/",
				});
			localStorage.setItem("athena_A", res.data.access_token);
			localStorage.setItem("athena_R", res.data.refresh_token);
			axios.defaults.headers.common.Authorization = `Bearer ${res.data.access_token}`;
			const decodedToken: any = jwtDecode(res.data.access_token);
			dispatch(setUser({
				username: decodedToken?.preferred_username,
				givenName: decodedToken?.given_name,
				fullName: decodedToken?.name,
				email: decodedToken?.email,
				roles: decodedToken?.permissions[0].role,
				order_limit: decodedToken?.permissions[0].orders_limit,
			}
			));
			dispatch(resetLoginCounter())
			dispatch(clearError())
			if (isAdmin(decodedToken?.permissions[0].role))
				navigate(routes.USERS);
			else
				navigate(routes.HOME)
				const fundGroupJwt = decodedToken?.permissions.map((perm: any) => ({
					fundGroup: perm.fund_group,
					permissions: perm.fund_group_permissions,
					orderLimit: perm.orders_limit
				}));

			if (fundGroupJwt) {
				localStorage.setItem('fundGroup', JSON.stringify(fundGroupJwt));
			} else {
				// Handle the case where either fundGroup or permissions is not available
				console.error('Unable to save data to localStorage: fundGroup or permissions not found');
			}
		} catch (e: any) {
			dispatch(clearUser())
			if (e.response?.status && e.response.status < 500) {
				const state = getState() as RootState;
				const attempts = state.user.loginCounter;
				if (attempts === 0) {
					dispatch(setError("Username or password is wrong. Please try again."));
				} else if (attempts === 1) {
					dispatch(setError("Username or password is wrong. You have one more attempt before your account is locked."));
				} else {
					dispatch(setError("Your account is locked. Please contact your administrator."));
				}
				dispatch(increaseLoginCounter());
			} else {
				dispatch(setError("Internal server error. Please try again."));
			}
		}
	}
)


export const logout = createAsyncThunk(
	'user/logout',
	async (_, { dispatch }) => {
		try {
			dispatch(clearUser())
			localStorage.removeItem("athena_A");
			localStorage.removeItem("athena_R");
			delete axios.defaults.headers.common["Authorization"];
			//window.location.href="/login"
		}
		catch (e) {
			console.log(e)
		}
	}
)

export default userSlice.reducer;