import React from "react";
import PageTitle from "../components/Common/PageTitle";
import ComingSoon from "../components/Common/ComingSoon/ComingSoon";

const AiAssistant: React.FC = () => {
	return (
		<div>
			<PageTitle TITLE='AI_ASSISTANT' />
			<ComingSoon />
		</div>
	);
};

export default AiAssistant;
