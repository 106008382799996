import { FC, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { AgGridEvent, CellClickedEvent, CellDoubleClickedEvent, ColDef, GridOptions } from "ag-grid-community";
import "@ag-grid-community/styles/ag-grid.css"; // Core CSS
import "@ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./Agstyles.css";
import { Button, useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { routes } from "../../routes";
import axios from "axios";
import SkeletonLoader from "../Common/Skeleton";
import { AgTableComponentProps, RowInfo } from "../../types";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { services } from "../../services";
import { ConstantUtils } from "../../utils/constantUtils";
import { hasWritePermission } from "../../utils/permissionUtils";
import { applyDecorators, customHeight, getTableData, getToleranceSymbol } from "../../utils/conversions";
import CustomNoRowsOverlay from '../Common/customNoRowsOverlay';
import { getBadgeStyles } from "../../utils/getStyles";
import { useColDefs } from "./UseColDef"
import { calculateMaxHeight, gridOptions, rowSelectionType } from "./utilsAgTable";

export const AgTableComponent: FC<AgTableComponentProps> = ({ rowData, queryParams, transactionId, isFundLocked, fetchDataForTable }) => {
    const { t, i18n } = useTranslation();
    const theme = useMantineTheme();
    const styles = getBadgeStyles(theme);
    const [languageKey, setLanguageKey] = useState(i18n.language);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation(); // Use useLocation hook
    const { pathname, search } = location;
    const currentLocation = location.pathname;
    const urlQueryParams = new URLSearchParams(search);
    const selected = decodeURI(urlQueryParams.get("selected") || "");
    let target_mode = pathname.split("/")[1];
    target_mode = target_mode.toUpperCase().replace(/-/g, "_");
    const tableData = getTableData("fund");
    const [checkState, setCheckState] = useState<string | boolean>();
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [loadingButtons, setLoadingButtons] = useState<Record<number, boolean>>({});
    const [triggerFundsApi, setTriggerFundsApi] = useState(false);
    const [wipButtonStates, setWipButtonStates] = useState<Record<number, string>>({});
    const inBadge = styles.inBadge;
    const outBadge = styles.outBadge;
    const commonCellStyle = styles.commonCellStyle;
    const cellWithoutBold = styles.cellWithoutBold;
    const commonCellStyleWithoutTolerance = styles.commonCellStyleWithoutTolerance;
    const cellicon = styles.cellicon;
    const fundIdHeader = t("FUND_ID");
    const CANCEL = t("CANCEL")
    const I_M_STARTING = t("I_M_STARTING");
    const ME = t("ME");

    const { target_date, calc_date } = queryParams || {};

    useEffect(() => {
        setLanguageKey(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        const buttonState = isFundLocked ? ME : I_M_STARTING;
        setWipButtonStates((prevState) => ({
            ...prevState,
            [transactionId || ""]: buttonState,
        }));
    }, [transactionId, isFundLocked]);

    useEffect(() => {
        setWipButtonStates({});
        setLoadingButtons({});
        setIsButtonDisabled(false);
        setCheckState(false);
        if (fetchDataForTable) {
            fetchDataForTable();
        }
    }, [triggerFundsApi]);

    const handleWipButtonClick = async (rowID: any, params: any) => {
        const { fund_id, fund_group, is_fund_locked, transaction_id } = params.data;
        const currentState = wipButtonStates[Number(rowID)];
        const newState = currentState === I_M_STARTING ? ME : I_M_STARTING;
        setWipButtonStates({ ...wipButtonStates, [rowID]: newState });

        if (!hasWritePermission(fund_group, "write")) {
            toast.error(t("NOT_AUTHORIZE_MESSAGE"));
            return;
        }
        const event_type = is_fund_locked === "true"
            ? ConstantUtils.orderClaimStates.unclaim
            : ConstantUtils.orderClaimStates.claim;

        const url = `${services.ORDERCLAIM}?fund_id=${fund_id}`;
        const payload = {
            fund_id,
            target_date,
            target_mode,
            transaction_id,
            event_type,
            calc_date,
        };

        const handlePending = (retryCount: number) => {
            const statusUrl = `${services.ORDERCLAIMSTATUS}?fund_id=${fund_id}&target_date=${target_date}&calc_date=${calc_date}&transaction_id=${transaction_id}&target_mode=${target_mode}`;

            axios
                .get(statusUrl)
                .then(async (orderClaimResponse) => {
                    const orderClaimData = orderClaimResponse.data.data.response_type;

                    if (ConstantUtils.successTypes.includes(orderClaimData)) {
                        const successState =
                            ConstantUtils.successTypes[rowID] === 'FUND_MESSAGE_RESPONSE_TYPE_ORDER_CLAIM_SUCCESS'
                                ? ME
                                : I_M_STARTING;
                    
                        setWipButtonStates((prevStates) => ({
                            ...prevStates,
                            [transaction_id]: successState,
                        }));
                    
                        await new Promise((resolve) => setTimeout(resolve, 1000));
                    
                        setTriggerFundsApi((prev) => !prev);
                        setLoadingButtons((prev) => ({
                            ...prev,
                            [transaction_id]: false,
                        }));
                    } if (orderClaimData === "PENDING") {
                        setWipButtonStates((prevStates) => ({
                            ...prevStates,
                            [transaction_id]: t("LOADING"),
                        }));

                        if (retryCount < 30) {
                            setTimeout(() => handlePending(retryCount + 1), 1000);
                        } else {
                            handleRetryExceeded();
                        }
                    } 
                })
                .catch((error) => {
                    console.error(error);
                    handleFailure();
                });
        };

        const handleRetryExceeded = () => {
            setWipButtonStates((prevStates) => ({
                ...prevStates,
                [transaction_id]: ME,
            }));
            setLoadingButtons((prev) => ({
                ...prev,
                [transaction_id]: false,
            }));
            toast.error(t("REFRESH_NEEDED"));
        };

        const handleFailure = () => {
            toast.error(t("ORDER_UPDATE_MESSAGE"));
            setWipButtonStates((prevStates) => ({
                ...prevStates,
                [rowID]: I_M_STARTING,
            }));
            setLoadingButtons((prev) => ({
                ...prev,
                [transaction_id]: false,
            }));
        };

        axios
            .post(url, payload)
            .then((response) => {
                const { data: datacheck, uuid_str: uuid } = response.data;
                localStorage.setItem('uuid', uuid);

                if (datacheck === "PENDING") {
                    handlePending(0);
                } else {
                    toast.error(t("ORDER_CLAIM_FAILED"));
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error(t("ORDER_CLAIM_FAILED"));
            })
            .finally(() => {
                setLoadingButtons((prev) => ({ ...prev, [transaction_id]: false }));
            });
    };

    const handleFundIdCellClick = (params: CellClickedEvent<RowInfo, any>) => {
        console.log("Row selected:", params.data);
    };

    const handleFundIdCellDoubleClick = async (params: CellDoubleClickedEvent<RowInfo, any>) => {
        if (params.data) {
            const fundId = params.data.fund_id;
            const transaction_id = params.data.transaction_id;
            const is_fund_locked = params.data.is_fund_locked;
            const fund_summary_type = params.data.fund_summary_type;
            const fund_group = params.data.fund_group;

            let initialState = I_M_STARTING;
            if (is_fund_locked === "true") {
                initialState = ME;
            } else if (is_fund_locked === "false") {
                initialState = I_M_STARTING;
            }

            if (target_mode === "MARKET_ON_CLOSE_PLUS_DAYS") {
                target_mode = "MARKET_ON_CLOSE";
            }

            const rowId = params.data.sr_no;
            const currentState = wipButtonStates[rowId] || initialState;
            const newState = initialState === I_M_STARTING ? ME : I_M_STARTING;

            try {
                const { target_date, calc_date } = queryParams || {};
                const checktargetmode = target_mode === "ALL_FUND_STATUS" ? fund_summary_type : target_mode;
                const navigateUrl = `${routes.FUNDSDATA}/${fundId}/${transaction_id}/${checktargetmode}/${selected}/${target_date}/${fund_group}/${calc_date}`;

                const disabledStatesForNavigation = {
                    [ME]: 'false',
                    [I_M_STARTING]: 'true',
                };
                let url = `${navigateUrl}?disabled=${disabledStatesForNavigation[currentState]}`;
                navigate(url);

                setWipButtonStates((prevStates) => ({
                    ...prevStates,
                    [rowId]: newState,
                }));
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleWipButtonHover = (rowId: number) => {
        const test = checkState === true ? I_M_STARTING : ME
        setWipButtonStates(prevStates => {
            const currentState = prevStates[rowId] || test;
            if (currentState === ME) {
                return {
                    ...prevStates,
                    [rowId]: CANCEL,
                };
            }
            return prevStates;
        });
    };

    const handleWipButtonLeave = (rowId: number) => {
        const test = checkState === true ? I_M_STARTING : ME
        setWipButtonStates(prevStates => {
            const currentState = prevStates[rowId] || I_M_STARTING;
            if (currentState === CANCEL) {
                return {
                    ...prevStates,
                    [rowId]: ME,
                };
            }
            return prevStates;
        });
    };

    const renderCellWithViolations = (
        params: any,
        valueKey: string,
        toleranceKey: string,
        toleranceLowerKey: string,
        toleranceUpperKey: string,
        decoratorsKey: string
    ) => {
        const value = parseFloat(params.data[valueKey]);
        const tolerance = params.data[toleranceKey];
        const toleranceLower = params.data[toleranceLowerKey];
        const toleranceUpper = params.data[toleranceUpperKey];
        const formattedValue = applyDecorators(value, tableData.find(row => row.key === decoratorsKey)?.decorators);

        if (tolerance && tolerance !== "0.00%") {
            const symbol = getToleranceSymbol(tolerance, toleranceUpper, toleranceLower);
            const backgroundColor = value >= toleranceLower && value <= toleranceUpper
                ? theme.colors.yellow[0]
                : theme.colors.red[0];

            return (
                <div style={{ backgroundColor, textAlign: "right" }}>
                    <div className='spendcashstyles' style={{ marginBottom: "-4rem", paddingRight: "0.5rem", marginRight: theme.spacing.sm }}>{formattedValue}</div>
                    <br />
                    <div style={{ color: theme.colors.grey[5], marginRight: theme.spacing.sm }} className='secondtextincellfunds'>
                        {`${symbol}${tolerance}`}
                    </div>
                </div>
            );
        }

        return <div className='firsttextincell'>{formattedValue}</div>;
    };

    const colDefs = useColDefs({
        selected,
        fundIdHeader,
        t,
        handleFundIdCellClick,
        hasWritePermission,
        renderCellWithViolations,
        inBadge,
        outBadge,
        cellWithoutBold,
        cellicon,
        commonCellStyle,
        commonCellStyleWithoutTolerance,
        wipButtonStates,
        handleWipButtonClick,
        handleWipButtonHover,
        handleWipButtonLeave,
        isButtonDisabled,
        loadingButtons,
        theme,
        setCheckState,
        ME,
        I_M_STARTING,
    });

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            menuTabs: ["filterMenuTab"],
            filter: "agMultiColumnFilter",
            suppressStickyLabel: true,
            headerClass: "custom-header",
            resizable: true,
            initialWidth: 200,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            headerPosition: "sticky",
            sticky: true,
        };
    }, []);

    const tableHeight = customHeight(rowData);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    const componentLocation = __filename.split('/').pop();
    const hideColumns = componentLocation === 'AllFundStatus.tsx';

    colDefs.filter(colDef => {
        if (hideColumns) {
            return colDef.field !== 'next_step' && colDef.field !== 'wip';
        } else {
            const decorators = tableData.find(row => row.key === colDef.field)?.decorators;
            colDef.valueFormatter = ({ value }) => applyDecorators(value, decorators);
            return true;
        }
    });

    const noRowsOverlayComponentParams = useMemo(() => {
        return {
            noRowsMessageFunc: () => `- ${t("NO_FUNDS_HERE")} -`,
        }
    }, []);

    return (
        <div>
            {isLoading ? (
                <SkeletonLoader />
            ) : (
                <div
                    className='ag-theme-quartz'
                    style={{
                        height: tableHeight,
                        width: "100%",
                        minHeight: "5.68rem",
                        maxHeight: calculateMaxHeight,
                    }}
                >
                    <AgGridReact
                        key={languageKey}
                        rowData={rowData}
                        columnDefs={colDefs.filter((colDef) => {
                            if (selected === "Completed" || currentLocation.includes(`${routes.ORDERCOMPLETION}`) || selected === "All-Fund-Status") {
                                return colDef.field !== "next_step" && colDef.field !== "wip";
                            } else {
                                return true;
                            }
                        })}
                        defaultColDef={defaultColDef}
                        rowHeight={40}
                        maxConcurrentDatasourceRequests={1}
                        maxBlocksInCache={1}
                        gridOptions={gridOptions}
                        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
                        noRowsOverlayComponent={CustomNoRowsOverlay}
                        rowSelection={rowSelectionType}
                        suppressScrollOnNewData={true}
                        onSortChanged={(e: AgGridEvent) => {
                            e.api.refreshCells();
                        }}
                        onCellClicked={handleFundIdCellClick}
                        onCellDoubleClicked={handleFundIdCellDoubleClick}
                    />
                    <ToastContainer position='top-right' autoClose={8000} />
                </div>
            )}
        </div>
    );
};

export default AgTableComponent;

